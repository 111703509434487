import { useCallback, useRef, useState } from 'react';

export function useInView() {
	const observer = useRef<IntersectionObserver | null>(null);
	const [isInView, setIsInView] = useState<boolean>(false);

	const onView = useCallback(
		(node: Element) => {
			if (observer.current && observer.current?.disconnect) {
				observer?.current?.disconnect();
			}

			observer.current = new IntersectionObserver(entries => {
				setIsInView(entries[0].isIntersecting);
			});

			if (node) {
				setIsInView(false);
				observer?.current?.observe(node);
			}
		},
		[observer]
	);

	return { isInView, onView };
}
