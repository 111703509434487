import React from 'react';
import * as queryString from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';

export function useQuery() {
	const { search, pathname } = useLocation();
	const history = useHistory();

	const getQueryString = (object: { [key: string]: string }) => {
		let obj = queryString.parse(search.replace('?', ''));
		obj = { ...obj, ...object };
		return queryString.stringify(obj);
	};

	const getQueryParams = () => {
		const obj: { [key: string]: string } = queryString.parse(search.replace('?', '')) as { [key: string]: string };
		return obj;
	};

	/**
      Updates the query parameters in the URL.
      @param {Object.<string, string>} object - An object containing key-value pairs to add to the query parameters.
      @param {string[]} removeKeys - An array of keys to remove from the query parameters.
    */
	const setMultipleQuery = (object: { [key: string]: string } = {}, removeKeys: string[] = []) => {
		let obj = queryString.parse(search.replace('?', ''));
		// Remove the keys from the query params if needed
		removeKeys.forEach(key => delete obj[key]);

		// Add the new key-value pairs to the query params
		obj = { ...obj, ...object };
		history.push({ pathname, search: queryString.stringify(obj) });
	};

	const setQuery = (key: string, value: string, params?: Record<string, string>) => {
		params ??= queryString.parse(search.replace('?', '')) as Record<string, string>;
		params[key] = value;
		history.push({ pathname, search: queryString.stringify(params) });
	};

	const getQueryValue = (key: string, params?: Record<string, string>) => {
		params ??= queryString.parse(search.replace('?', '')) as Record<string, string>;
		return params[key];
	};

	const resetQuery = () => {
		history.push({ pathname, search: '' });
	};

	const removeQuery = (key: string, params?: Record<string, string>) => {
		params ??= queryString.parse(search.replace('?', '')) as Record<string, string>;
		delete params[key];
		history.push({ pathname, search: queryString.stringify(params) });
	};

	const removeMultipleQuery = (keys: string[], params?: Record<string, string>) => {
		params ??= queryString.parse(search.replace('?', '')) as Record<string, string>;
		keys.forEach(key => delete params[key]);
		history.push({ pathname, search: queryString.stringify(params) });
	};

	return {
		setQuery,
		getQueryValue,
		resetQuery,
		setMultipleQuery,
		getQueryString,
		getQueryParams,
		removeQuery,
		removeMultipleQuery,
	};
}
