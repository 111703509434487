/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { IColors, Icons } from '../../types';
import { useColors } from '../../hooks/useColors';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';

const conflictsWrapperCss = (colors: IColors, isMarginLeft = true) => css`
	svg,
	button {
		color: ${colors.bg_error} !important;
	}
	svg {
		width: 12px !important;
		height: 12px !important;
		margin: 0 !important;
	}

	${isMarginLeft && `margin-left: 9px;`}
	gap: 4px;
	display: flex;
	align-items: center;
`;

const conflictIconCss = css`
	padding: 0px !important;
`;

export const ConflictsIndication = ({
	caption,
	OnTriggerConflicts = undefined,
	isSub = false,
	isMarginLeft = true,
}: {
	caption: string;
	OnTriggerConflicts?: () => void;
	isSub?: boolean;
	isMarginLeft?: boolean;
}) => {
	const { colors } = useColors();

	const handleClick = () => {
		if (OnTriggerConflicts) {
			OnTriggerConflicts();
		}
	};
	return (
		<div css={conflictsWrapperCss(colors, isMarginLeft)} data-aid="ConflictsIndication-conflicts">
			<Button
				data-aid="button-ConflictsIndication-handle"
				theme="basic"
				sizer="XS"
				disabled={!isSub || (isSub && !OnTriggerConflicts)}
				onClick={handleClick}
				css={conflictIconCss}
				type="button"
			>
				<BnIcon icon={Icons.conflict} />
			</Button>

			{!isSub && (
				<Button
					data-aid="button-ConflictsIndication-cap"
					theme="basicLine"
					sizer="XS"
					onClick={handleClick}
					type="button"
				>
					{caption}
				</Button>
			)}
		</div>
	);
};
