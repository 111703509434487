/** @jsx jsx */
/** @jsxRuntime classic */
import React, {
	createContext,
	Dispatch,
	Fragment,
	ReactNode,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { css, jsx } from '@emotion/react';
import { FormApi, FormState, ValidationErrors } from 'final-form';
import { FieldArray } from 'react-final-form-arrays';
import { isEventType } from '../../lib/form';
import { get } from 'lodash';
import { checkIsModified } from '@app/react/forms/utils/utils';

export const containerCss = css`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
`;

const formContainerCss = css`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

interface IFormContext {
	values?: any;
	form?: FormApi<any, Partial<any>>;
	initialValues?: Partial<any>;
	valid?: boolean;
	timeError?: string;
	errors?: any;
	setTimeError?: Dispatch<SetStateAction<string>>;
	pristine?: boolean;
	getFieldValue?: <T>(field: string) => T;
	isModified?: (field: string) => boolean;
}

export const FormContext = createContext<IFormContext>({});

/**
 * @description must be used inside the FormWrapper
 * @param name the name of the field you want to subscribe to -> example for array : (presetName ? `${presetName}.isMaintenance` : 'isMaintenance')
 * @returns the new Value of the field
 */
export const useFieldRegistration = (name: string) => {
	const [newValue, setNewValue] = useState<any>();
	const { form } = useContext(FormContext);

	useEffect(() => {
		const registerField = form.registerField(
			name,
			fieldState => {
				const { value } = fieldState;
				if (value !== newValue) {
					setNewValue(value);
				}
			},
			{ value: true }
		);
		return () => {
			registerField();
		};
	}, []);

	return newValue;
};

export type TFormState = FormState<Record<string, any>, Partial<Record<string, any>>>;
export type TExposeState = (state: TFormState) => void;

export interface IForm {
	submit: (values: any, form: any) => void;
	initialValuesProps?: Partial<any>;
	children?: ReactNode;
	header?: ReactNode;
	footer?: ReactNode;
	keepDirtyOnReinitialize?: boolean;
	validate?: (values: any) => ValidationErrors;
	exposeState?: TExposeState;
}

export const FormWrapper = ({
	submit,
	initialValuesProps,
	children,
	header,
	footer,
	keepDirtyOnReinitialize,
	validate = v => {
		return {};
	},
	exposeState,
}: IForm) => {
	const [timeError, setTimeError] = useState<string>('');

	return (
		<div css={containerCss}>
			<Form
				onSubmit={submit}
				validate={validate}
				initialValues={initialValuesProps}
				keepDirtyOnReinitialize={keepDirtyOnReinitialize}
				mutators={{
					...arrayMutators,
					clear: (args, state, { changeValue }) => {
						changeValue(state, args[0], () => undefined);
					},
					onSelect: (args, state, { changeValue }) => {
						changeValue(state, args[0], () => args[1]);
					},
				}}
				render={({ form, handleSubmit }) => {
					const { initialValues, values, valid, errors, pristine, modified } = form.getState();

					const getFieldValue = useCallback(<T extends any>(field: string) => get(values, field) as T, [values]);

					const isModified = useCallback(
						(field: string) => checkIsModified(field, form),
						[modified, form, initialValues]
					);

					return (
						<FormContext.Provider
							value={{
								form,
								initialValues,
								values,
								valid,
								errors,
								timeError,
								setTimeError,
								pristine,
								getFieldValue,
								isModified,
							}}
						>
							<form autoComplete="off" css={formContainerCss} onSubmit={handleSubmit} encType="multipart/form-data">
								<FormSpy
									subscription={{ valid: true }}
									render={() => {
										return <Fragment>{header}</Fragment>;
									}}
								/>
								{children}
								<FormSpy
									subscription={{ valid: true }}
									render={() => {
										return <Fragment>{footer}</Fragment>;
									}}
								/>
								{exposeState && (
									<FormSpy
										subscription={{
											pristine: true,
											values: true,
											initialValues: true,
										}}
										onChange={state => {
											exposeState(state);
										}}
									/>
								)}
							</form>
						</FormContext.Provider>
					);
				}}
			/>
		</div>
	);
};

interface IFormArrayContext {
	fields?: any;
	meta?: Partial<{
		active: boolean;
		dirty: boolean;
		dirtySinceLastSubmit: boolean;
		error: any;
		initial: any;
		invalid: boolean;
		pristine: boolean;
		submitError: any;
		submitFailed: boolean;
		submitSucceeded: boolean;
		touched: boolean;
		valid: boolean;
		visited: boolean;
	}>;
	handleCreateNew?: (init?: unknown) => void;
	isAtleastOneItem?: boolean;
}

export const FormArrayContext = createContext<IFormArrayContext>({});

export const ArrayNameContext = createContext<{ name: string; index: number }>({ name: '', index: 0 });

export const FormArrayWrapper = ({
	name,
	children,
	header,
	footer,
	tabsMode = false,
	activeTab,
	validate,
}: {
	name: string;
	children: ReactNode | (({ index, name }: { index: number; name: string }) => Element);
	header?: ReactNode;
	footer?: ReactNode;
	tabsMode?: boolean;
	activeTab?: number;
	validate?: (values: any) => ValidationErrors;
}) => {
	return (
		<FieldArray name={name} validate={validate} key={`FormArrayWrapper-${name}`}>
			{({ fields, meta }) => {
				const handleCreateNew = (init?: unknown) => {
					fields.push((!isEventType(init) && init) ?? meta?.initial?.[0] ?? {});
				};
				const isAtleastOneItem = fields.length > 1;
				return (
					<div data-aid="form" key={`FormArrayWrapper-inner-${name}`}>
						<FormArrayContext.Provider value={{ fields, meta, handleCreateNew, isAtleastOneItem }}>
							{header}
							{fields.map((name, index) => {
								if (tabsMode && activeTab !== -1) {
									if (index === activeTab) {
										return (
											<ArrayNameContext.Provider key={`context-provider-active-tab-${name}`} value={{ name, index }}>
												{typeof children === 'function' ? children({ name, index }) : children}
											</ArrayNameContext.Provider>
										);
									}
									return null;
								} else {
									return (
										<ArrayNameContext.Provider key={`context-provider-${name}`} value={{ name, index }}>
											{typeof children === 'function' ? children({ name, index }) : children}
										</ArrayNameContext.Provider>
									);
								}
							})}
							{footer}
						</FormArrayContext.Provider>
					</div>
				);
			}}
		</FieldArray>
	);
};
