import {
	Addon,
	AddSlotToSegmenResultDto,
	BaseInvoiceDto,
	Product,
	Slot,
	IValidateEditReservationAddonsResult,
	PaginationResultDto,
	DraftSlotDto,
	DEFAULT_PAGE,
	DraftReservationDto,
	DraftSlotsFilterResultDto,
	GenerateReservationDto,
	GetDraftReservationSlotsFiltersDto,
	GetDraftSlotsOptions,
	UpdateDraftSlotsDto,
	GenericResponseDto,
	GetDraftReservationOptionsDto,
	ErrorResponsesDto,
	DraftIdsDto,
	SimpleProductDto,
	GetDraftReservationAddonsQueryDto,
	CreateReservationAddonsDto,
	SimpleDraftReservationDto,
	ReservationAddonsDto,
	PlatformsEnum,
	UpdatePricesTypeEnum,
	CartPricingResultDto,
	UpdateCartPricesDto,
	CartDto,
	SharedPaginatedResultDto,
	PaginatedSlotsSharedDto,
} from '@bondsports/types';

import { Reservation } from 'app/react/types/calendar';
import { Invoice } from 'app/react/types/checkout';
import { AddSegmentToReservationDto, IAddAddon, IRemoveAddonResp } from 'app/react/types/reservation';
import {
	AddSlotToSegmentDto,
	DraftReservationResponse,
	InvoiceDto,
	ReservationDto,
	SaveSlotToSegmentDto,
	SlotDto,
} from 'app/react/components/calendar/newReservation/NewReservationTypes';
import { environment } from '../../../../environments/environment';
import { IApiError, network } from '../network';
import { IEditAddonReq, IEditAddonRes } from 'app/react/components/shared/EditAddonToReservation/types';
import { IBasicError, IErrorArr, IErrorObj, isError } from '../../types/errors';
import { buildQueryString } from '../urlUtils';
import {
	DraftReservation,
	DraftSlotsFilters,
	mapDraftReservationDtoToDraftReservation,
	mapDraftSlotsFilterResultDtoToDraftSlotsFilters,
} from '@app/react/types/draftReservation';
import { ITEMS_PER_PAGE_DEFAULT } from '../constants';
import { isErrorResponse } from '@bondsports/utils';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

async function calculateAddons(
	organizationId: number,
	facilityId: number,
	data: ReservationDto
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/generateSlots`,
		data
	);
	return response;
}

async function updateInvoice(
	organizationId: number,
	facilityId: number,
	data: ReservationDto,
	isUpdateAddons = false,
	isUpdateMaintenance = false
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/updateInvoice?updateAddons=${isUpdateAddons}&updateMaintenance=${isUpdateMaintenance}`,
		data
	);
	return response;
}
export interface IUpdatePrices {
	reservation: ReservationDto;
	type: UpdatePricesTypeEnum;
	products?: { id: number; price: number; originalPrice?: number; priceId?: number }[];
	category?: { slot: number; addon: number; reservationAddon?: number };
	globalPrice?: number;
}

export interface IInvoiceSlots {
	slotsIds: number[];
	addonsIds: number[];
	basicInfoData?: BaseInvoiceDto;
	addToInvoiceId?: number;
}

async function updatePrices(
	organizationId: number,
	facilityId: number,
	data: IUpdatePrices
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/updatePrices`,
		data
	);
	return response;
}

async function holdBooking(
	organizationId: number,
	facilityId: number,
	data: { reservation: ReservationDto; slotType: string }
): Promise<{ reservation: ReservationDto }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/save`,
		data
	);
	return response;
}

async function getSlotData(
	reservationId: number,
	organizationId: number,
	slotId: number,
	includingProdact?: boolean
): Promise<Slot | { err: string[] }> {
	const response = await network.get(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/slot/${slotId}?addProduct=${includingProdact}`
	);
	return response;
}

async function updateSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	slotId: number,
	data: SlotDto
) {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/slot/${slotId}`,
		data
	);
	return response;
}

async function updatePartialSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	slotId: number,
	data: SlotDto
) {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/slot/${slotId}/partial`,
		data
	);
	return response;
}

type GenerateSlotRes = AddSlotToSegmenResultDto | IErrorObj;

async function generateSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	segmentId: number,
	data: AddSlotToSegmentDto
): Promise<GenerateSlotRes> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/segment/${segmentId}/generateSlot`,
		data
	);
	return response;
}

async function saveSlotsToSegment(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	segmentId: number,
	data: SaveSlotToSegmentDto
) {
	const response = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/facility/${facilityId}/segment/${segmentId}/add-slots`,
		data
	);
	return response;
}

interface IUpdateSlotsAddonsResponse {
	slots: SlotDto[];
	invoice: InvoiceDto;
}

type UpdateSlotsAddonsRes = IUpdateSlotsAddonsResponse | IErrorArr;

async function updateSlotsAddons(organizationId: number, facilityId: number, data: any): Promise<UpdateSlotsAddonsRes> {
	return network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/update-slots-addons`,
		data
	);
}

const invoiceSlots = async (organizationId: number, reservationId: number, data: IInvoiceSlots) => {
	const response = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/invoice`,
		data
	);
	return response;
};

async function updateSlotsPrices(
	organizationId: number,
	facilityId: number,
	data: any
): Promise<{ slots: SlotDto[]; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/update-slots-prices`,
		data
	);
	return response;
}

async function addSegmentToReservation(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	data: AddSegmentToReservationDto
): Promise<
	| {
			reservation: Reservation;
			invoice?: Invoice;
			debug: any;
	  }
	| { err: string[] }
> {
	const response = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/facility/${facilityId}/segments`,
		data
	);
	return response;
}

const deleteEventByIds = (organizationId: number, reservationId: number, eventIds: number[]) => {
	return network.delete(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/slots?slotsIds=${eventIds.join(',')}`,
		{}
	);
};

const deleteReservationAddon = (
	organizationId: number,
	reservationId: number,
	addonIds: number[]
): Promise<IRemoveAddonResp> => {
	return network.delete(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons?addonsIds=${addonIds.join(',')}`,
		{}
	);
};

const addReservationAddon = async (
	organizationId: number,
	reservationId: number,
	data: IAddAddon
): Promise<Addon[]> => {
	const { newAddons } = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons`,
		data
	);
	return newAddons;
};

// const invoiceAddonItems = (
// 	organizationId: number,
// 	reservationId: number,
// 	data: IInvoiceAddon
// ): Promise<IUpdateReservationResult> => {
// 	return network.post(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/invoice`, data);
// };

const getAddonsForReservation = (organizationId: number, reservationId: number): Promise<Product[]> => {
	return network.get(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons-options`);
};

const postConfirmEditAddonResv = (
	organizationId: number,
	reservationId: number,
	data: IEditAddonReq
): Promise<IValidateEditReservationAddonsResult> => {
	return network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/validate-edit-addons`,
		data
	);
};

const putEditAddonResv = (
	organizationId: number,
	reservationId: number,
	data: IEditAddonReq
): Promise<IEditAddonRes> => {
	return network.put(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons`, data);
};

const getDratReservationSlots = (
	organizationId: number,
	reservationId: string,
	queryParams: GetDraftReservationSlotsFiltersDto
): Promise<SharedPaginatedResultDto<DraftSlotDto, PaginatedSlotsSharedDto> | IBasicError> => {
	const queryString = buildQueryString({
		...queryParams,
		page: queryParams.page,
		itemsPerPage: queryParams.itemsPerPage ?? ITEMS_PER_PAGE_DEFAULT,
	});

	return network.get(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/paginated-slots${queryString}`
	);
};

const getDraftSlotById = (
	organizationId: number,
	reservationId: string,
	id: string,
	options?: GetDraftSlotsOptions
): Promise<DraftSlotDto | IBasicError> => {
	const queryString = options ? buildQueryString(options) : '';
	return network.get(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/slots/${id}${queryString}`
	);
};

const getDratReservationDto = async (
	organizationId: number,
	reservationId: string,
	options?: GetDraftReservationOptionsDto
): Promise<DraftReservation | IBasicError> => {
	const queryParams: string = options ? buildQueryString(options) : '';

	const response: DraftReservationDto | IBasicError = await network.get(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}${queryParams}`
	);
	if ('err' in response) {
		return response;
	}

	const draftReservation = mapDraftReservationDtoToDraftReservation(response);
	return draftReservation;
};

const getDratSlotsFilters = async (
	organizationId: number,
	reservationId: string
): Promise<DraftSlotsFilters | IBasicError> => {
	const response: DraftSlotsFilterResultDto | IBasicError = await network.get(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/slots-filters`
	);
	if ('err' in response) {
		return response;
	}

	const draftReservation = mapDraftSlotsFilterResultDtoToDraftSlotsFilters(response);
	return draftReservation;
};

const generateDraftReservation = async (
	organizationId: number,
	data: GenerateReservationDto
): Promise<DraftReservation> => {
	const response: DraftReservationDto | IApiError = await network.post(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/`,
		data
	);

	if (isError(response)) {
		throw response;
	}

	return response as DraftReservationDto;
};

const updateDraftSlots = async (
	organizationId: number,
	reservationId: string,
	data: UpdateDraftSlotsDto
): Promise<DraftReservationResponse> => {
	const response: DraftReservationResponse = await network.put(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/slots`,
		data
	);

	if (isErrorResponse(response)) {
		throw response;
	}

	return response;
};

const deleteDraftReservation = async (organizationId: number, reservationId: string): Promise<GenericResponseDto> => {
	const response: GenericResponseDto | IBasicError = await network.delete(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}`,
		{}
	);

	if (isError(response)) {
		throw response.err;
	}

	return response;
};

const updateDraftReservation = async (
	organizationId: number,
	reservationId: string,
	data: GenerateReservationDto
): Promise<DraftReservation> => {
	const response: DraftReservationDto | IApiError = await network.put(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}`,
		data
	);

	if (isError(response)) {
		throw response;
	}

	return response as DraftReservationDto;
};
async function calculateReservationConflicts(organizationId: number, reservationId: string) {
	const response = await network.put(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/calculate-conflicts`,
		{}
	);
	return response;
}

const deleteDraftSlots = (
	organizationId: number,
	reservationId: string,
	options: DraftIdsDto
): Promise<ErrorResponsesDto | GenericResponseDto> => {
	const queryParams: string = buildQueryString(options);
	return network.delete(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/slots${queryParams}`,
		{}
	);
};

const getDraftReservationRelevantAddons = async (
	organizationId: number,
	reservationId: string,
	options: GetDraftReservationAddonsQueryDto
): Promise<PaginationResultDto<SimpleProductDto> | IBasicError> => {
	const queryParams: string = buildQueryString(options);

	return await network.get(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/relevant-addons${queryParams}`
	);
};

async function updateAddons(
	organizationId: number,
	reservationId: string,
	data: CreateReservationAddonsDto
): Promise<SimpleDraftReservationDto | IBasicError> {
	const response = await network.put(
		`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/addons`,
		data
	);
	return response;
}

const getReservationAddons = async (
	organizationId: number,
	reservationId: string
): Promise<ReservationAddonsDto | IBasicError> => {
	return await network.get(`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/addons`);
};

const holdReservation = async (
	organizationId: number,
	reservationId: string,
	addToReservationId?: number,
	shiftId?: number,
	updatePrices?: UpdateCartPricesDto
): Promise<ReservationDto | IBasicError> => {
	return await network.post(`${v4APIUrl}/organization/${organizationId}/reservations/${reservationId}/save`, {
		platform: PlatformsEnum.BO,
		shiftId,
		updatePrices,
		addToReservationId,
	});
};

const getReservationPrices = async (
	organizationId: number,
	reservationId: string
): Promise<CartPricingResultDto | IBasicError> => {
	return await network.get(`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/prices`);
};

const createCart = async (
	organizationId: number,
	reservationId: string,
	updatePrices?: UpdateCartPricesDto
): Promise<CartDto | IBasicError> => {
	return await network.post(`${v4APIUrl}/organization/${organizationId}/draft-reservations/${reservationId}/cart`, {
		updatePrices,
	});
};

export const newReservationApi = {
	calculateAddons,
	updateAddons,
	updatePrices,
	holdBooking,
	updateInvoice,
	getSlotData,
	updateSingleSlot,
	updatePartialSingleSlot,
	generateSingleSlot,
	saveSlotsToSegment,
	updateSlotsAddons,
	updateSlotsPrices,
	invoiceSlots,
	addSegmentToReservation,
	deleteEventByIds,
	deleteReservationAddon,
	addReservationAddon,
	getAddonsForReservation,
	postConfirmEditAddonResv,
	putEditAddonResv,
	getDratReservationSlots,
	getDratReservationDto,
	getDratSlotsFilters,
	generateDraftReservation,
	getDraftSlotById,
	updateDraftSlots,
	deleteDraftReservation,
	updateDraftReservation,
	calculateReservationConflicts,
	deleteDraftSlots,
	getDraftReservationRelevantAddons,
	getReservationAddons,
	holdReservation,
	getReservationPrices,
	createCart,
};
