import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

type UseDebounceValueProps<T> = {
	delay?: number;
	callback: (value: T | undefined) => void;
	initialValue?: T;
};

type UseDebounceValueReturn<T> = {
	setValue: (value: T | ((prev: T) => T)) => void;
	value: T | undefined;
};

const DELAY_MS = 250;

/**
 * Debounced value hook. It will call the callback function with the debounced value.
 * @param callback {Function} - Callback function to be called with the debounced value. IMPORTANT: The callback function should be memoized.
 * @param [delay=250ms] {number} - Debounce delay in milliseconds
 * @param initialValue {T} - Optional initial value
 * @returns {UseDebounceValueReturn} - Object with the value and setValue function
 */
export function useDebounceValue<T>({
	callback,
	delay = DELAY_MS,
	initialValue,
}: UseDebounceValueProps<T>): UseDebounceValueReturn<T> {
	const [value, setValue] = useState(initialValue);

	const debouncedFn = useMemo(() => debounce(callback, delay), [callback, delay]);

	useEffect(() => {
		debouncedFn(value);
		return () => debouncedFn.cancel();
	}, [value, debouncedFn]);

	return {
		setValue,
		value,
	};
}
