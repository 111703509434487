import { ProductTypesEnum } from '@app/shared/services/programs/programs.service';
import { MaintenanceDto } from '@bondsports/types';
import { ISubProduct } from './checkout';
import { Resources } from './packege';
import { DurationUnitTypesEnum } from '../components/shared/formBlocks/utils/utils';

export enum EndRepeatEnum {
	ON = '1',
	AFTER = '2',
}

export type SelectedDays = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface SpaceOption {
	label: string;
	value: number;
	// sports;
}

export type Frequencies = 'weekly' | 'monthly' | 'daily' | '';

export enum FrequencieEnum {
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
	DAILY = 'daily',
}

export interface MaintenanceTimeObjNew {
	title: string; // maintenanceTime
	durationValue: number; // time
	maintenanceDurationdurationType: TimeFrameEnum; // timeFrame
	maintenanceTiming: MaintenanceTimingEnum; // occurring
}

export interface MaintenanceTimeObj {
	maintenanceTime: string;
	time: number;
	timeFrame: TimeFrameEnum;
	occurring: MainteanceOccurringEnum;
}
export interface TimeSlot {
	space;
	activity: string;
	startDate: string;
	startTime: string;
	endDate: string;
	endTime: string;
	isRepeat?: boolean;
	frequencies?: 'weekly' | 'monthly' | 'daily' | '';
	every?: string;
	selectedDays?: SelectedDays[];
	occurrencesType?: EndRepeatEnum;
	endRepeatDate?: string; // should be deprecated
	repeatEndDate?: string;
	occurrences?: number;
	series?: Slot[];
	isMaintenanceTime: boolean;
	maintenanceTimes: MaintenanceTimeObj[];
	publicNotes?: string;
	maintenanceDtos?: MaintenanceDto[];
	repeatEvery?: number;
}

export interface Slot {
	startDate: string;
	startTime: string;
	endDate: string;
	endTime: string;
}

export interface SummeryTimeSlotData extends Slot {
	space: number;
	activity: string;
	rate: string;
	addons?;
	isPartOfSeries?: boolean;
	seriesNumber?: string;
	mainteanceOccurring?: MaintenanceTimingEnum;
	publicNotes?: string;
	type: SlotTypeEnum;
	title?: string;
}

export interface SummeryObj {
	[k: string]: SummeryTimeSlotData[];
}

export interface SlotData extends SummeryTimeSlotData {
	packageId: number;
	note: string;
	name: string;
	price: number;
}

export interface RentalProducts {
	id: number;
	type: ProductTypesEnum;
	price: number;
	productName: string;
	resources: Resources[];
	spaceName: string;
	subProducts: ISubProduct[];
	quantity: number;
}
export interface InternalSlotData extends Slot {
	space: number;
	activity: string;
	note: string;
	name: string;
	type: SlotTypeEnum;
	publicNotes?: string;
}

export enum EventTypeEnum {
	INTERNAL = 'Internal event',
	EXTERNAL = 'External event',
	MAINTENANCE = 'Maintenance',
}

export enum MainteanceOccurringEnum {
	AFTERTHEEVENT = 'afterTheEvent',
	BEFORETHEEVENT = 'beforeTheEvent',
	ATTHEBEGINNINGOFTHEEVENT = 'atTheBeginningOfTheEvent',
	ATTHEENDOFTHEEVENT = 'atTheEndOfTheEvent',
}

export enum MaintenanceTimingEnum {
	BEFORE = 1,
	AFTER = 2,
	AT_THE_BEGINING = 3,
	AT_THE_END = 4,
}

export enum TimeFrameEnum {
	MINUTES = 'minutes',
	HOURS = 'hours',
}

export enum SlotTypeEnum {
	INTERNAL = 'internal',
	EXTERNAL = 'external',
	MAINTENANCE = 'maintenance',
	CUSTOM = 'custom',
}

export interface SimpleMaintenance {
	durationValue: number;
	maintenanceDurationdurationType: DurationUnitTypesEnum;
	maintenanceTiming: MaintenanceTimingEnum;
}
