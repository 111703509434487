import { EndRepeatEnum, MaintenanceTimingEnum } from 'app/react/types/Booking';
import { TranslationEn } from 'assets/i18n/en';
import { ClientAddon, IUpdateSlotPrices } from 'app/react/types/calendar';
import {
	FrequencyEnum,
	ICalculateAddonsResponse as IResponse,
	IDraftReservationFromValues,
	IDraftSegmentsFormValues,
	IDraftSeriesFormValues,
	InvoiceDto,
	ReservationClientDto,
	ReservationDto,
	SlotDto,
	SlotDurationTypeEnum,
	VisibilityEnum,
	DraftReservationAddons,
	AddonAndQuantity,
} from './NewReservationTypes';
import * as dayjs from 'dayjs';
import { deepClone, getFullName } from 'app/react/lib/utils';
import { IUpdatePrices, newReservationApi } from '@app/react/lib/api/newReservationApi';
import {
	CreateDraftSegmentDto,
	CreateDraftSeriesDto,
	DraftReservationDto,
	DraftSegmentDto,
	DraftSlotDto,
	DurationUnitTypesEnum,
	Facility,
	FacilityDto,
	GenerateReservationDto,
	IdAndNameDto,
	InstructorResourceDto,
	PackageProductsRelationTypeEnum,
	PriceDto,
	PrivacySettingsEnum,
	ProblematicSlotDto,
	Product,
	ProductDto,
	ProductResourceDto,
	ProductSubTypesEnum,
	ReservationTypeEnum as ApiReservationTypeEnum,
	ResourceNameTypeEnum,
	ResourceScheduleDto,
	SimpleCustomerDto,
	SpaceResourceDto,
	SportsEnum,
	CreateReservationAddonsDto,
	UpdatePricesTypeEnum,
	UpdateCartPricesDto,
	CartDto,
} from '@bondsports/types';
import { ISchedule, ResourceWithSports } from './types';
import { capitalize, Dictionary, groupBy, intersection, isEqual, keyBy, sortBy, uniq } from 'lodash';
import { ETypography, ETypographyColor, formatUserName, IOption, IOption_V2 } from '@bondsports/utils';
import { DurationTypeEnum } from '@bondsports/types/dist/types/reservations/types/enums/reservation.enums';
import { VISIBILITY } from '@app/react/components/shared/formBlocks/VisibilityBlock/constants';
import { CUSTOMER } from '@app/react/components/shared/formBlocks/SingleCustomersSelect/constants';
import { ICustomer } from '@app/react/types/customers';
import { isSameDateOrEarlier, isSameDateOrLater } from '@bondsports/date-time';
import { convert24ToAmPm, convertAmPmTo24, convertAvailabilityToDate, getBondDayOfWeek } from '@app/react/lib/dates';
import { MaintenanceDto } from '@app/react/types/reservation';
import { ProductTypesEnum } from '@app/react/types/orders';
import { validateAmount } from '@bondsports/general';
import { IInlineCartItem } from '@app/react/hooks/purchase/types';
import { roundPriceCents } from '@app/react/lib/pricing';
import { CHARACHERS } from '@bondsports/utils';
import { ReservationTypeEnum } from '@bondsports/types';
import { rentalProductDuration } from '@app/react/lib/rentalProductUtils';

const labels = TranslationEn.errors;

//To Do: Remove- duplicated from apps\backoffice\src\app\react\lib\dates.ts
export const timeSerialization = (time: string) => {
	if (time) {
		if (dayjs(time, 'hh:mm a').isValid()) {
			return dayjs(time, 'hh:mm a').format('HH:mm:ss');
		}
		return time;
	} else {
		return '';
	}
};

export const ReservationTypeToSlotType = {
	rental: 'external',
	program: 'internal',
	maintenance: 'maintenance',
};

export const generateData = (values: ReservationClientDto, removeSlots = false) => {
	values.segments = values.segments.map((seg, segIndex) => {
		// relevant to step 2
		if (seg.addonIds) {
			seg.addonIds = seg.addonIds.filter(id => id).map(id => Number(id));
		}

		seg.series = seg.series.map((s, index) => {
			// relevant to step 2
			if (values?.slotsBySeriesBySegments) {
				s.slots = values?.slotsBySeriesBySegments?.[segIndex]?.[index];
			}

			if (removeSlots) {
				delete s.slots;
			}

			// override the start+end times with serialized values
			const startTime = timeSerialization(s.startTime);
			const endTime = timeSerialization(s.endTime);

			const data = {
				...s,
				frequency: s.frequency || FrequencyEnum.NONE,
				slotDurationType: s.duration
					? SlotDurationTypeEnum.DURATION
					: s.allDay
						? SlotDurationTypeEnum.ALL_DAY
						: SlotDurationTypeEnum.DATES,
				startTime,
				endTime,
				maintenance: s.maintenance?.filter(m => m.title),
				repeatEndDate: s.occurrencesType === EndRepeatEnum.ON ? s.repeatEndDate : null,
				numberOccurrences: s.occurrencesType === EndRepeatEnum.AFTER ? s.numberOccurrences : null,
			};

			// remove the start+end times if not exist
			if (!startTime) delete data.startTime;
			if (!endTime) delete data.endTime;
			return data;
		});
		return {
			...seg,
			isPrivate: seg.isPrivate,
		};
	});

	if (values.addons) {
		values.addons = values.addons
			.filter(addon => addon.productId)
			.map(addon => {
				return {
					...addon,
					productId: Number(addon.productId),
					quantity: Number(addon.quantity),
				};
			});
	}

	// remove
	delete values.customer;
	delete values.slotsBySeriesBySegments;
	delete values.optionalAddons;
	delete values.optionalAddonsBySegments;
	delete (values as ReservationClientDto & { finalReservation: any }).finalReservation;

	return values;
};

export const generateNewSegments = ({
	organizationId,
	facilityId,
	data,
	onError,
}: {
	organizationId: number;
	facilityId: number;
	data: any;
	onError;
}) => {
	return newReservationApi.calculateAddons(organizationId, facilityId, generateData({ ...data }, true)).then(res => {
		if ((res as { err: string[] }).err) {
			onError(TranslationEn.errors.noProducts);
		} else {
			if (
				(res as IResponse).reservation.reservationType === ReservationTypeEnum.RENTAL &&
				(res as { invoice: InvoiceDto }).invoice.lineItems.length === 0
			) {
				onError(TranslationEn.errors.noProducts);
			} else {
				return (res as IResponse).reservation.segments;
			}
		}
	});
};

export const generateSlotsBySegments = (res: { reservation: ReservationDto; invoice?: InvoiceDto }, form?: any) => {
	const addons = {};
	const slotsBySeriesBySegments: SlotDto[][][] = [];
	const slots = [];
	res?.reservation?.segments?.forEach(segment => {
		const slotBySeries: SlotDto[][] = [];
		segment?.series?.forEach(s => {
			slotBySeries.push(s.slots);
			s?.slots?.forEach(slot => {
				slots.push(slot);
				slot?.product?.childProductPackages?.forEach(addon => {
					if (!addons[addon.childProductId]) {
						addons[addon.childProductId] = { ...addon.childProduct, level: addon.level };
					}
				});
			});
		});
		slotsBySeriesBySegments.push(slotBySeries);
	});

	if (form) {
		form.mutators.onSelect(
			`optionalAddons`,
			Object.keys(addons).map(key => addons[key])
		);
	}

	return { addons, slotsBySeriesBySegments, slots };
};
export const getAddonsByProduct = (product: Product) => {
	const addons = {};
	product?.childProductPackages.forEach(addon => {
		if (!(addon.childProductId in addons)) {
			addons[addon.childProductId] = { ...addon.childProduct, level: addon.level };
		}
	});
	return Object.keys(addons).map(key => addons[key]) as ClientAddon[];
};

export const generateSlots = async ({
	organizationId,
	facilityId,
	data,
	onError,
	callback,
	form,
	name,
	updateInvoice,
	segmentIndex,
	updateProduct = false,
	handleDraftSlots,
}: {
	organizationId: number;
	facilityId: number;
	data: any;
	onError;
	callback?: () => void;
	form?: any;
	name?: string;
	updateInvoice?: boolean;
	segmentIndex?: number;
	updateProduct?: boolean;
	handleDraftSlots?: (v: any) => void;
}) => {
	const newValues = deepClone(data);
	const res = await newReservationApi.calculateAddons(organizationId, facilityId, generateData({ ...data }, true));
	if ((res as { err: string[] }).err) {
		onError(TranslationEn.errors.noProducts);
		return undefined;
	} else {
		if (
			(res as IResponse).reservation.reservationType === ReservationTypeEnum.RENTAL &&
			(res as { invoice: InvoiceDto }).invoice.lineItems.length === 0
		) {
			onError(TranslationEn.errors.noProducts);
			return undefined;
		} else {
			const { addons, slotsBySeriesBySegments, slots } = generateSlotsBySegments(res as IResponse);

			form.mutators.onSelect('slotsBySeriesBySegments', slotsBySeriesBySegments);
			newValues.slotsBySeriesBySegments = slotsBySeriesBySegments;
			form.mutators.onSelect('finalReservation', (res as IResponse).reservation);
			newValues.finalReservation = (res as IResponse).reservation;
			form.mutators.onSelect(
				`optionalAddons`,
				Object.keys(addons).map(key => addons[key])
			);
			newValues.optionalAddons = Object.keys(addons).map(key => addons[key]);

			if (handleDraftSlots) {
				handleDraftSlots(slots);
			}

			if (updateProduct) {
				const fieldName = name ? `${name}.product` : 'product';
				const product = (res as IResponse).reservation?.segments[0]?.series[0]?.slots?.[0]?.product;
				form.mutators.onSelect(fieldName, product);
				newValues[fieldName] = product;
			}

			if (segmentIndex !== undefined) {
				const newOptionalAddons = { ...data.optionalAddons };
				newOptionalAddons[segmentIndex] = Object.keys(addons).map(key => addons[key]);
				form.mutators.onSelect(`optionalAddonsBySegments`, newOptionalAddons);
				newValues.optionalAddonsBySegments = newOptionalAddons;
			}
			form.mutators.onSelect(`addons`, [{ productId: '', quantity: '' }]);
			newValues.addons = [{ productId: '', quantity: '' }];
			if (name) {
				form.mutators.onSelect(`${name}.isAddons`, true);
				newValues[`${name}.isAddons`] = true;
				form.mutators.onSelect(`${name}.addonIds`, ['']);
				newValues[`${name}.addonIds`] = [''];
			} else {
				form.mutators.onSelect(`isAddons`, true);
				newValues.isAddons = true;
				form.mutators.onSelect('addonIds', ['']);
				newValues['addonIds'] = [''];
			}
			if (callback) {
				setTimeout(() => {
					callback();
				}, 300);
			}
			if (updateInvoice) {
				form.mutators.onSelect('segments', (res as IResponse).reservation.segments);
				form.mutators.onSelect('invoice', (res as IResponse).invoice);
			}
			newValues.segments = (res as IResponse).reservation.segments;
			newValues.invoice = (res as IResponse).invoice;
			return newValues as ReservationClientDto;
		}
	}
};

type IUpdatedPriceObject = IUpdateSlotPrices | IUpdatePrices;

//refactor needed: values and formData are the same
export const handlePricingType = (values: any, obj: IUpdatedPriceObject, formData: any) => {
	switch (values.pricing_type) {
		case UpdatePricesTypeEnum.INDIVIDUAL:
			const resourcesProducts = generateProductUpdateInterface(formData?.resourcesProducts);
			const reservationAddons = generateProductUpdateInterface(formData?.reservationAddons);
			const slotAddons = generateProductUpdateInterface(formData?.slotAddons);
			obj.products = [...resourcesProducts, ...reservationAddons, ...slotAddons];
			break;
		case UpdatePricesTypeEnum.CATEGORY:
			obj.category = { slot: values.resourcesSubTotal, addon: values.addonsSubTotal };
			break;
		case UpdatePricesTypeEnum.GLOBAL:
			obj.globalPrice = formData.reservationTotalPrice;
			break;
		default:
			break;
	}
};

interface AddToCartProps {
	invoice: InvoiceDto;
	finalReservation: ReservationDto;
}

export const updatePrices = ({
	values,
	form,
	organizationId,
	facilityId,
	callback,
	onError,
}: {
	values: any;
	form: any;
	organizationId: number;
	facilityId: number;
	callback: (values: AddToCartProps) => void;
	onError: (message: string) => void;
}) => {
	const formData = JSON.parse(JSON.stringify(values));

	const obj: IUpdatePrices = {
		reservation: generateData({ ...formData }, false),
		type: values.pricing_type,
	};
	handlePricingType(values, obj, formData);

	newReservationApi.updatePrices(organizationId, facilityId, obj).then(res => {
		if ((res as unknown as { err: any }).err) {
			onError(labels.somethingWentWrong);
		} else {
			const slotBySeriesBySegments: SlotDto[][][] = [];
			(res as { reservation: ReservationClientDto; invoice: InvoiceDto }).reservation.segments.forEach(segment => {
				const slotBySeries: SlotDto[][] = [];
				segment.series.forEach(s => {
					slotBySeries.push(s.slots);
				});
				slotBySeriesBySegments.push(slotBySeries);
			});
			const finalReservation = { ...res.reservation, invoice: res.invoice };
			const invoice = res.invoice;
			form.mutators.onSelect('slotsBySeriesBySegments', slotBySeriesBySegments);
			form.mutators.onSelect('finalReservation', finalReservation);
			form.mutators.onSelect('invoice', invoice);
			form.mutators.onSelect(
				'addons',
				(res as { reservation: ReservationClientDto; invoice: InvoiceDto }).reservation.addons
			);
			form.mutators.onSelect('segments', (res as IResponse).reservation.segments);
			callback({ invoice, finalReservation });
		}
	});
};

export const InitialSeriesData: IDraftSeriesFormValues = {
	startDate: '',
	endDate: '',
	isRepeat: false,
	repeatOn: [],
	numberOfOccurrences: 0,
	repeatEndDate: '',
	repeatEvery: 0,
	frequency: FrequencyEnum.NONE,
	maintenance: [
		{
			title: '',
			durationValue: 10,
			maintenanceDurationdurationType: DurationUnitTypesEnum.MINUTES,
			maintenanceTiming: MaintenanceTimingEnum.BEFORE,
		} as MaintenanceDto,
	],
	startTime: '',
	endTime: '',
};

export const initialData: IDraftReservationFromValues = {
	visibility: VisibilityEnum.PUBLIC,
	colorCodeId: undefined,
	reservationType: ReservationTypeEnum.RENTAL,
	segments: [
		{
			series: [structuredClone(InitialSeriesData)],
			visibility: VisibilityEnum.PUBLIC,
			facility: undefined,
			title: '',
		},
	],
	name: '',
	customerId: 0,
	[CUSTOMER]: undefined,
};

export const generateProductUpdateInterface = arr => {
	if (arr) {
		return arr.map(product => {
			return {
				productId: product.id,
				price: +product.price,
				originalPrice: product.originalPrice,
				priceId: product.priceId,
			};
		});
	}
	return [];
};

/**
 * Returns the price the first price by the given compression
 * @param prices {PriceDto[]} - prices to get the price from
 * @param compression {(a: PriceDto, b: PriceDto) => number} - compression to compare the prices
 * @returns {PriceDto} - the most accurate price
 */
export function getFirstPriceBy(prices: PriceDto[], compression: (a: PriceDto, b: PriceDto) => number): PriceDto {
	if (!prices?.length) {
		return null;
	}

	return structuredClone(prices).sort(compression).at(0);
}

/**
 * Flattens the prices of the products
 * @param products {ProductDto[]} - products to get the prices from
 * @returns {PriceDto[]} - prices of the products
 */
export function flatProductPrices(products: ProductDto[]): PriceDto[] {
	return products.flatMap((product: ProductDto): PriceDto[] => {
		switch (product.productSubType) {
			case ProductSubTypesEnum.PER_PARTICIPANT_RENTAL:
				const childProduct: Product = product.childProductPackages.find(
					pp => pp.relationType === PackageProductsRelationTypeEnum.PARTICIPANT
				)?.childProduct;
				return childProduct.prices as unknown as PriceDto[];
			case ProductSubTypesEnum.GROUP_RENTAL:
			case ProductSubTypesEnum.RENTAL:
			default:
				return product.prices;
		}
	});
}

/**
 * Returns the price of the extra participant product
 * @param product {ProductDto} - product to get the extra participant price from
 * @returns {PriceDto} - price of the extra participant product
 */
export function getExtraParticipantPrice(product: ProductDto): PriceDto {
	return product.childProductPackages.find(pp => pp.relationType === PackageProductsRelationTypeEnum.EXTRA_PARTICIPANT)
		?.childProduct?.currPrice as unknown as PriceDto;
}

/**
 * Generates resource options for the select
 * @param resources {ResourceWithSports[]} - resources to generate options for
 */
export function generateResourceOptions(resources: ResourceWithSports[]): IOption_V2[] {
	return resources?.map(resource => {
		const name =
			resource.firstName || resource.lastName ? formatUserName(resource.firstName, resource.lastName) : resource.name;

		return {
			label: name,
			value: resource.id,
			...(resource.resourceSubType && {
				additionalInfo: capitalize(resource.resourceSubType),
				additionalTypographyType: ETypography.body2Accented,
			}),
			additionalRows: [{ additionals: [resource.sports?.map(sport => TranslationEn.sports[sport]).join(', ') ?? ''] }],
		};
	});
}

const PRODUCTS_AND_RESOURCES_LABELS = TranslationEn.forms.newReservation.step2.productsAndResources;

/**
 * Generates product options for the select, based on the selected sports, spaces and instructors
 * @param products {ProductDto[]} - products to generate options for
 * @param instructors {InstructorResourceDto[]} - instructors to check if they have the selected sports
 * @param spaces {SpaceResourceDto[]} - spaces to check if they have the selected sports
 * @param sport {SportsEnum} - selected sport
 * @param isAllSelected {boolean} - if all products are selected
 * @param customersIds {number[]} - customers IDs to check if they are participants
 * @returns {IOption_V2[]} - product options for the select
 */
export function generateProductOptions(
	products: ProductDto[],
	instructors: InstructorResourceDto[],
	spaces: SpaceResourceDto[],
	sport: SportsEnum,
	isAllSelected: boolean,
	customersIds: number[]
): IOption_V2[] {
	if (!products?.length) {
		return [];
	}

	const spaceIds: Set<number> = new Set(spaces?.map(space => space.id));
	const instructorIds: Set<number> = new Set(instructors?.map(instructor => instructor.id) || []);

	const options: IOption[] = products.map((product: ProductDto): IOption => {
		// if the product does not intersect with the selected sport, disable the product
		let isDisabled: boolean = !intersection(product.sports, [sport]).length;

		const {
			[ResourceNameTypeEnum.SPACE]: spaceResources,
			[ResourceNameTypeEnum.INSTRUCTOR]: instructorResources,
		}: Dictionary<ProductResourceDto[]> = groupBy(product.productResources, pr => pr.type);

		// if no space intersects with the products spaces, disable the product
		isDisabled ||= spaceResources?.every(pr => !spaceIds.has(pr.id));

		// if no instructor intersects with the products instructors, disable the product
		isDisabled ||=
			instructorIds.size && (!instructorResources?.length || instructorResources.some(pr => !instructorIds.has(pr.id)));

		const productPrices: PriceDto[] = flatProductPrices([product]);

		// if the product has no prices, disable the product
		isDisabled ||= !productPrices.length;

		// disabling all products if all are selected
		isDisabled ||= isAllSelected;

		// if the product is reserved for customers and the current customer is not in the list, disable the product
		const isProductNotReservedForCustomer: boolean =
			Boolean(product.reservedForCustomersIds?.length) &&
			!product.reservedForCustomersIds.some(id => customersIds.includes(id));

		isDisabled ||= isProductNotReservedForCustomer;

		const participantsText: string =
			product.productSubType !== ProductSubTypesEnum.RENTAL ? createParticipantsRow(product, productPrices) : '';
		const priceRow: string = createPriceRow(productPrices, product.defaultPriceId);

		return {
			label: product.name,
			value: product.id,
			additionalInfo: priceRow,
			additionalRows: [
				{
					additionals: [optionSubTypeAndDurationRow(product), participantsText],
				},
			],
			isDisabled,
		};
	});

	return sortOptions(options);
}

/**
 * Generates product participants row for the product options select
 * @param product {ProductDto} - product to generate the row for
 * @param prices {PriceDto[]} - prices to generate the row for
 * @returns {string} - product price row for the product options select
 */
function createParticipantsRow(product: ProductDto, prices: PriceDto[]): string {
	if (!prices?.length) {
		return '';
	}

	const maxQuantityPrice: PriceDto = getFirstPriceBy(prices, (price, other) => other.maxQuantity - price.maxQuantity);

	if (!maxQuantityPrice) {
		return '';
	}

	const extraParticipantPrice: PriceDto = getExtraParticipantPrice(product);

	const extraParticipants: number = extraParticipantPrice?.maxQuantity - extraParticipantPrice?.minQuantity || 0;

	return `${maxQuantityPrice.maxQuantity + extraParticipants} ${PRODUCTS_AND_RESOURCES_LABELS.products.participants}`;
}

/**
 * Generates product price row for the product options select
 * @param prices {PriceDto[]} - prices to generate the row for
 * @param defaultPriceId {number} - default price id
 * @returns {string} - participants count row for the product options select
 */
function createPriceRow(prices: PriceDto[], defaultPriceId?: number): string {
	if (!prices?.length) {
		return '';
	}

	let lowestPrice: PriceDto;
	if (defaultPriceId) {
		lowestPrice = prices.find(price => price.id === defaultPriceId);
	}

	lowestPrice ??= prices[0];

	if (!lowestPrice) {
		return '';
	}

	const currency: string = lowestPrice.currency.toUpperCase();
	const currencySymbol: string = TranslationEn.currency[currency].symbol;

	return `${currencySymbol}${lowestPrice.price}${prices.length > 1 && !lowestPrice.isDefault ? CHARACHERS.PLUS : ''}`;
}

/**
 * Generates a row for the product options select
 * @param subType {ProductSubTypesEnum} - product sub type
 * @param duration {number} - product duration in minutes
 * @returns {string} - row for the product options select
 */
function optionSubTypeAndDurationRow(product: ProductDto): string {
	const subTypeLabel: string =
		PRODUCTS_AND_RESOURCES_LABELS.products.productTypes[product.productSubType] ??
		PRODUCTS_AND_RESOURCES_LABELS.products.productTypes[ProductSubTypesEnum.RENTAL];

	return `${subTypeLabel} (${rentalProductDuration(product, true)})`;
}

/**
 * Generates sports options for the select
 * @param sports {SportsEnum[]} - sports to generate options for
 * @param spaces {SpaceResourceDto[]} - spaces to check if they have the selected sports
 * @param instructors {InstructorResourceDto[]} - instructors to check if they have the selected sports
 * @param labels {Dictionary<string>} - labels for the sports options
 * @returns {IOption_V2[]} - sports options for the select
 */
export function getSportsOptions(
	sports: SportsEnum[],
	spaces: SpaceResourceDto[],
	instructors: InstructorResourceDto[],
	labels: {
		selectedSpaceAndInstructorDontMatch: string;
		selectedSpaceDoesNotMatch: string;
		selectedInstructorDoesNotMatch: string;
	}
): IOption_V2[] {
	if (!sports?.length) {
		return [];
	}

	const options = sports.map((sport: SportsEnum): IOption => {
		const doesSpaceHaveSport: boolean = !spaces?.length || spaces.every(space => space.sports.includes(sport));
		const doesInstructorHaveSport: boolean =
			!instructors?.length || instructors.every(instructor => instructor.sports?.includes(sport));

		let message: string = '';
		if (!doesSpaceHaveSport && !doesInstructorHaveSport) {
			message = labels.selectedSpaceAndInstructorDontMatch;
		} else if (!doesSpaceHaveSport) {
			message = labels.selectedSpaceDoesNotMatch;
		} else if (!doesInstructorHaveSport) {
			message = labels.selectedInstructorDoesNotMatch;
		}

		return {
			label: TranslationEn.sports[sport],
			value: sport,
			isDisabled: !doesSpaceHaveSport || !doesInstructorHaveSport,
			...(message && { additionalRows: [{ color: ETypographyColor.red, additionals: [message] }] }),
		};
	});

	return sortOptions(options);
}

/**
 * Sorts the options by the disabled state and the label
 * @param options {IOption[]} - options to sort
 * @returns {IOption[]} - sorted options
 */
function sortOptions(options: IOption[]): IOption[] {
	return sortBy(options, [option => option.isDisabled, option => option.label]);
}

function privacySettingsMapper(visibility: VisibilityEnum): PrivacySettingsEnum {
	if (!visibility) {
		return null;
	}

	switch (visibility) {
		case VisibilityEnum.PRIVATE:
			return PrivacySettingsEnum.PRIVATE;
		case VisibilityEnum.PUBLIC:
		default:
			return PrivacySettingsEnum.PUBLIC;
	}
}

export function visibilityMapper(privacySetting: PrivacySettingsEnum): VisibilityEnum {
	if (!privacySetting) {
		return null;
	}

	switch (privacySetting) {
		case PrivacySettingsEnum.PRIVATE:
			return VisibilityEnum.PRIVATE;
		case PrivacySettingsEnum.PUBLIC:
		default:
			return VisibilityEnum.PUBLIC;
	}
}

function generateDraftReservationData(
	organizationId: number,
	values: IDraftReservationFromValues,
	isModified: (field: string) => boolean,
	reservationId: string,
	customerId: number
): GenerateReservationDto {
	const privacySetting: PrivacySettingsEnum = privacySettingsMapper(values[VISIBILITY]);

	const segments: CreateDraftSegmentDto[] = values.segments
		.filter((segment, index) => {
			if (segment.id) {
				return values.removedSegmentsIds?.includes(segment.id) || isModified(`segments[${index}]`);
			}

			return true;
		})
		.map<CreateDraftSegmentDto>(segment => {
			return {
				id: segment.id,
				resourceIds: segment.spaces.map(space => space.id),
				instructorsIds: segment.instructors?.length ? segment.instructors.map(instructor => instructor.id) : undefined,
				productsIds: !segment.allProducts ? segment.products?.map(product => product.id) : null,
				title: segment.title,
				facilityId: segment.facility.id,
				participantsIds: segment.customers?.length ? segment.customers.map(customer => customer.id) : null,
				participantsNumber: Number(segment.participantCount) || null,
				privacySetting: privacySettingsMapper(segment.visibility) || privacySetting,
				reservationId,
				colorCodeId: Number(values.colorCodeId),
				sportId: segment.sport,
				publicNotesForSlots: segment.publicNotes,
				privateNotesForSlots: segment.privateNotes,
				series: segment.series.map(series => {
					const hasMaintenance: boolean = series.isMaintenance;

					const repeatSettings = {
						repeatEvery: series.repeatEvery ? Number(series.repeatEvery) : null,
						repeatOn: series.repeatOn?.map(Number),
						repeatEndDate: series.repeatEndDate,
						numberOccurrences: Number(series.numberOfOccurrences),
						isEndDate: series.isEndDate,
						isOccurrence: series.isOccurrences,
					};

					return {
						startDate: series.startDate,
						startTime: convertAmPmTo24(series.startTime, true),
						endDate: series.endDate,
						endTime: convertAmPmTo24(series.endTime, true),
						slotDurationType: DurationTypeEnum.DATES,
						durationUnit: DurationUnitTypesEnum.MINUTES,
						maintenance: hasMaintenance ? series.maintenance : null,
						frequency: series.isRepeat ? series.frequency : FrequencyEnum.NONE,
						...(series.isRepeat && repeatSettings),
					} as CreateDraftSeriesDto;
				}),
			};
		});

	return {
		reservation: {
			organizationId,
			name: values.name,
			reservationType:
				values.reservationType === ReservationTypeEnum.RENTAL
					? ApiReservationTypeEnum.RENTAL
					: ApiReservationTypeEnum.INTERNAL,
			privacySetting: privacySetting,
			colorCodeId: Number(values.colorCodeId),
			customerId,
			publicNotes: values.publicNotes,
			privateNotes: values.privateNotes,
		},
		addSegments: segments.length ? segments : null,
		removeSegmentsIds: values.removedSegmentsIds,
	};
}

/**
 * Generates the draft reservation
 * @param organizationId {number} - organization ID
 * @param values {IDraftReservationFromValues} - values to generate the reservation with
 * @param isModified {(field: string) => boolean} - function to check if the field is modified
 */
export async function generateDraftReservation(
	organizationId: number,
	values: IDraftReservationFromValues,
	isModified: (field: string) => boolean,
	customerId: number,
	reservationId?: string
): Promise<DraftReservationDto> {
	const data: GenerateReservationDto = generateDraftReservationData(
		organizationId,
		values,
		isModified,
		reservationId,
		customerId
	);
	return await newReservationApi.generateDraftReservation(organizationId, data);
}

/**
 * Updates the draft reservation
 * @param organizationId {number} - organization ID
 * @param reservationId {string} - reservation ID
 * @param values {IDraftReservationFromValues} - values to update the reservation with
 * @param isModified {(field: string) => boolean} - function to check if the field is modified
 */
export async function updateDraftReservation(
	organizationId: number,
	reservationId: string,
	values: IDraftReservationFromValues,
	customerId: number,
	isModified: (field: string) => boolean
) {
	const data: GenerateReservationDto = generateDraftReservationData(
		organizationId,
		values,
		isModified,
		reservationId,
		customerId
	);
	return await newReservationApi.updateDraftReservation(organizationId, reservationId, data);
}

/**
 * Creates a resource mismatch error message
 * @param errors {{ startDate: string; endDate: string; startTime: string; endTime: string; spaceId: number }[]} - errors to create the message from
 * @param spaces {IdAndNameDto[]} - spaces to get the names from
 * @returns {string} - formatted error message
 */
export function createProductResourceMismatchError(errors: ProblematicSlotDto[], spaces: IdAndNameDto[]): string {
	const errorMessages: string[] = [];
	const spaceById = keyBy(spaces, s => s.id);

	// showing up to 3 errors
	const iterations = errors.length < 3 ? errors.length : 3;
	for (let i = 0; i < iterations; i++) {
		const spaceId: number = errors[i].spaceId;
		errorMessages.push(TranslationEn.forms.newReservation.step2.mismatchSlotsError(errors[i], spaceById[spaceId]));
	}

	return errorMessages.join('\n');
}

/**
 * Validates the schedule of the segment series
 * @param series {IDraftSeriesFormValues} - series to validate
 * @param facility {Facility} - facility to validate against
 * @param spaces {SpaceResourceDto[]} - spaces to validate against
 * @param instructors {InstructorResourceDto[]} - instructors to validate against
 * @returns {string[]} - invalid resources names (facility, spaces, instructors)
 */
export function validateSeriesSchedule(
	series: IDraftSeriesFormValues,
	facility: Facility,
	spaces: SpaceResourceDto[],
	instructors: InstructorResourceDto[]
): string[] {
	const invalidResources: string[] = [];

	const seriesClone: IDraftSeriesFormValues = structuredClone(series);
	seriesClone.startTime = convertAmPmTo24(seriesClone.startTime, true);
	seriesClone.endTime = convertAmPmTo24(seriesClone.endTime, true);

	const valid: boolean = facility.openingTimes.some(openingTime => validateSchedule(openingTime, seriesClone));

	if (!valid) {
		invalidResources.push(facility.name);
	}

	for (const space of spaces ?? []) {
		const valid: boolean = space.activityTimes.some(activityTime => validateSchedule(activityTime, seriesClone));

		if (!valid) {
			invalidResources.push(space.name);
		}
	}

	for (const instructor of instructors ?? []) {
		const schedules: ResourceScheduleDto[] = instructor.schedule.filter(schedule =>
			schedule.activityTimes.some(at => at.facilityId === facility.id)
		);
		const valid: boolean = schedules
			.flatMap(schedule => schedule.activityTimes)
			.some(activityTime => validateSchedule(activityTime, seriesClone));

		if (!valid) {
			invalidResources.push(getFullName(instructor));
		}
	}

	return invalidResources;
}

/**
 * Validates the schedule of the series
 * @param schedule {ISchedule} - schedule to validate against
 * @param series {IDraftSeriesFormValues} - series to validate
 * @returns {boolean} - whether the schedule is valid
 */
function validateSchedule(schedule: ISchedule, series: IDraftSeriesFormValues): boolean {
	const startDay: number = getBondDayOfWeek(series.startDate);
	const endDay: number = getBondDayOfWeek(series.endDate);

	if (schedule.availabilityStartDate && schedule.availabilityEndDate) {
		if (!isSameDateOrLater(convertAvailabilityToDate(schedule.availabilityStartDate), series.startDate)) {
			return false;
		}

		if (!isSameDateOrEarlier(convertAvailabilityToDate(schedule.availabilityEndDate), series.endDate)) {
			return false;
		}
	}

	const isStartTimeValid =
		`${series.startDate}T${series.startTime}Z`.localeCompare(`${series.startDate}T${schedule.open}Z`) >= 0;
	const isEndTimeValid =
		`${series.endDate}T${series.endTime}Z`.localeCompare(`${series.endDate}T${schedule.close}Z`) <= 0;

	const isStartDayValid = schedule.dayOfWeek === startDay;
	const isEndDayValid = schedule.dayOfWeek === endDay;

	return isStartTimeValid && isEndTimeValid && isStartDayValid && isEndDayValid;
}

/**
 * Generates the form values from the reservation
 * @param reservation {DraftReservationDto} - reservation to generate the form values from
 * @returns {IDraftReservationFromValues} - form values generated from the reservation
 */
export const generateFormValuesFromReservation = (reservation: DraftReservationDto): IDraftReservationFromValues => {
	const spaceById: Dictionary<SpaceResourceDto> = keyBy(reservation.spaces, space => space.id);
	const instructorById: Dictionary<InstructorResourceDto> = keyBy(reservation.instructors, instructor => instructor.id);
	const productById: Dictionary<ProductDto> = keyBy(reservation.products, product => product.id);
	const customerById: Dictionary<SimpleCustomerDto> = keyBy(reservation.participants, customer => customer.id);
	const facilityById: Dictionary<FacilityDto> = keyBy(reservation.facilities, facility => facility.id);

	return {
		reservationId: reservation.id,
		name: reservation.name,
		reservationType: reservation.reservationType as unknown as ReservationTypeEnum,
		visibility: visibilityMapper(reservation.privacySetting),
		colorCodeId: reservation.colorCodeId,
		customer: reservation.owner as unknown as ICustomer,
		customerId: reservation.owner.id,
		segments: reservation.segments.map<IDraftSegmentsFormValues>((segment: DraftSegmentDto) => {
			const spaces: SpaceResourceDto[] = segment.resourceIds.map(id => spaceById[id]);
			const instructors: InstructorResourceDto[] = segment.instructorsIds?.map(id => instructorById[id]);
			const products: ProductDto[] = segment.productsIds?.map(id => productById[id]);
			const customers: SimpleCustomerDto[] = segment.participantsIds?.map(id => customerById[id]);
			const facility: FacilityDto = facilityById[segment.facilityId];

			return {
				id: segment.id,
				spaces,
				instructors,
				facility: facility as Facility,
				sport: segment.sportId,
				products: products?.length ? products : [],
				allProducts: !products?.length,
				segmentId: segment.id,
				title: segment.title,
				visibility: visibilityMapper(segment.privacySetting),
				participantCount: segment.participantsNumber,
				customers: customers,
				publicNotes: segment.publicNotesForSlots,
				privateNotes: segment.privateNotesForSlots,
				series: segment.series.map<IDraftSeriesFormValues>(series => {
					const firstSlot: DraftSlotDto = series.slots?.at(0);

					return {
						startDate: firstSlot ? firstSlot.startDate : series.startDate,
						endDate: firstSlot ? firstSlot.endDate : series.endDate,
						startTime: convert24ToAmPm(series.startTime),
						endTime: convert24ToAmPm(series.endTime),
						repeatEvery: series.repeatEvery,
						repeatOn: series.repeatOn,
						frequency: series.frequency,
						numberOfOccurrences: series.numberOccurrences,
						isRepeat: !!series.repeatEndDate || !!series.numberOccurrences,
						isEndDate: Boolean(series.isEndDate),
						repeatEndDate: series.repeatEndDate,
						isOccurrences: Boolean(series.isOccurrence),
						isMaintenance: !!series.maintenance?.length,
						maintenance: series.maintenance?.map<MaintenanceDto>(maintenance => {
							return {
								title: maintenance.title,
								durationValue: maintenance.durationValue,
								maintenanceDurationdurationType: maintenance.maintenanceDurationdurationType,
								maintenanceTiming: maintenance.maintenanceTiming,
							} as unknown as MaintenanceDto;
						}),
					};
				}),
			};
		}),
	};
};

/**
 * Compares the form values to the initial values
 * @param values {IDraftReservationFromValues} - values to compare
 * @param initialValues {IDraftReservationFromValues} - initial values to compare
 * @returns {boolean} - whether the values are equal to the initial values
 */
export function compereValuesToInitial(
	values: IDraftReservationFromValues,
	initialValues: IDraftReservationFromValues
) {
	if (values.reservationId !== initialValues.reservationId) {
		return false;
	}

	if (values.segments.length !== initialValues.segments.length) {
		return false;
	}

	if (values.removedSegmentsIds?.length !== initialValues.removedSegmentsIds?.length) {
		return false;
	}

	for (const [segmentIndex, segment] of values.segments.entries()) {
		if (!compareSegment(segment, initialValues.segments[segmentIndex])) {
			return false;
		}
	}

	return true;
}

export function compareSegment(segment: IDraftSegmentsFormValues, initialValues: IDraftSegmentsFormValues) {
	const initialSegment: IDraftSegmentsFormValues = initialValues;
	const compareSegments = compareProperties(segment, initialSegment, [
		'id',
		'title',
		'visibility',
		'participantCount',
		'allProducts',
		'sport',
		'publicNotes',
		'privateNotes',
		'allProducts',
	]);

	if (!compareSegments) {
		return false;
	}

	const spaceById: Dictionary<SpaceResourceDto> = keyBy(initialSegment.spaces, space => space.id);
	if (
		segment.spaces?.length !== initialSegment.spaces?.length ||
		!segment.spaces?.every(space => spaceById[space.id])
	) {
		return false;
	}

	const instructorById: Dictionary<InstructorResourceDto> = keyBy(
		initialSegment.instructors,
		instructor => instructor.id
	);
	if (
		segment.instructors?.length !== initialSegment.instructors?.length ||
		(segment.instructors && !segment.instructors.every(instructor => instructorById[instructor.id]))
	) {
		return false;
	}

	const productById: Dictionary<ProductDto> = keyBy(initialSegment.products, product => product.id);
	if (
		segment.products?.length !== initialSegment.products?.length ||
		(segment.products && !segment.products.every(product => productById[product.id]))
	) {
		return false;
	}

	const customerById: Dictionary<SimpleCustomerDto> = keyBy(initialSegment.customers, customer => customer.id);
	if (
		segment.customers?.length !== initialSegment.customers?.length ||
		(segment.customers && !segment.customers.every(customer => customerById[customer.id]))
	) {
		return false;
	}

	if (segment.series.length !== initialSegment.series.length) {
		return false;
	}

	for (const [seriesIndex, series] of segment.series.entries()) {
		const initialSeries: IDraftSeriesFormValues = initialSegment.series[seriesIndex];
		const compareSeries = compareProperties(series, initialSeries, [
			'startDate',
			'endDate',
			'startTime',
			'endTime',
			'repeatOn',
			'frequency',
			'repeatEvery',
			'frequency',
			'isRepeat',
			'isMaintenance',
		]);

		if (series.isOccurrences && !isEqual(series.numberOfOccurrences, initialSeries.numberOfOccurrences)) {
			return false;
		}

		if (series.isEndDate && !isEqual(series.repeatEndDate, initialSeries.repeatEndDate)) {
			return false;
		}

		if (!isEqual(series.repeatOn, initialSeries.repeatOn)) {
			return false;
		}

		if (!compareSeries) {
			return false;
		}

		if (
			series.maintenance?.length !== initialSeries.maintenance?.length ||
			!isEqual(series.maintenance, initialSeries.maintenance)
		) {
			return false;
		}
	}

	return true;
}

/**
 * Comparates two objects by the given properties
 * @param value {T} - value to compare
 * @param other {T} - other value to compare
 * @param properties {(keyof T)[]} - properties to compare
 * @returns {boolean} - whether the properties are equal
 */
export function compareProperties<T>(value: T, other: T, properties: (keyof T)[]): boolean {
	return uniq(properties).every(property => value[property] === other[property]);
}

export const prepareAddonsData = (
	values: DraftReservationAddons,
	initialValues: DraftReservationAddons
): CreateReservationAddonsDto => {
	const isValidAddon = (addon: AddonAndQuantity) => Boolean(addon?.addon?.id) && addon.quantity;

	const validReservationAddons = values.reservationAddons?.filter(isValidAddon);
	const validSegmentsAddons = values.segmentsAddons.filter(
		segmentAddon => isValidAddon(segmentAddon) && segmentAddon.segmentIds?.length
	);

	let removeByGroupIds: string[] = [];
	let removeByIds: string[] = [];

	if (initialValues.reservationAddons) {
		const initialValuesIds = new Set(initialValues.reservationAddons.map(addon => addon.id));
		const valuesIds = new Set(values.reservationAddons.map(addon => addon.id));

		removeByIds = Array.from(initialValuesIds).filter(id => !valuesIds.has(id));
	}

	if (initialValues.segmentsAddons) {
		const initialGroupIds = new Set(initialValues.segmentsAddons.map(segment => segment.groupId));
		const currentGroupIds = new Set(values.segmentsAddons.map(segment => segment.groupId));

		removeByGroupIds = Array.from(initialGroupIds).filter(id => !currentGroupIds.has(id));
	}
	return {
		reservation: validReservationAddons?.length
			? validReservationAddons.map(addon => ({ productId: addon.addon.id, quantity: addon.quantity, id: addon.id }))
			: undefined,
		segments: validSegmentsAddons.length
			? validSegmentsAddons.map(segment => ({
					ids: segment.segmentIds,
					quantity: segment.quantity,
					productsIds: [segment.addon.id],
					id: segment.id,
					groupId: segment.groupId,
				}))
			: undefined,
		removeByIds: removeByIds.length ? removeByIds : undefined,
		removeByGroupIds: removeByGroupIds.length ? removeByGroupIds : undefined,
	};
};

/**
 * Converts a given value to a number if it is a string.
 *
 * @param value - The value to be converted, which can be a number or a string.
 * @returns The numeric value if the input was a valid numeric string, or the original value if it was already a number.
 *          Returns `undefined` if the input is `undefined` or an invalid numeric string.
 */
const convertToNumber = (value?: number | string): number | undefined => {
	if (typeof value === 'string') {
		const parsed = Number(value);
		return isNaN(parsed) ? undefined : parsed;
	}
	return value;
};
/**
 * Generates the pricing data
 * @param values - form values to generate the pricing data from
 * @returns {UpdateCartPricesDto} - pricing data generated from the values
 */
export function generatePricingData(values): UpdateCartPricesDto {
	return {
		categories: {
			products: convertToNumber(values.categoriesProductsSubTotal),
			addons: convertToNumber(values.categoriesAddonsSubTotal),
		},
		products: {
			products: values.products.map(product => ({ ...product, unitPrice: convertToNumber(product.unitPrice) })),
			addons: values.addons.map(addon => ({ ...addon, unitPrice: convertToNumber(addon.unitPrice) })),
		},
		totalPrice: convertToNumber(values.reservationTotalPrice),
		type: values.pricing_type,
	};
}

/**
 * Converts the cart to the inline cart line items
 * @param cart {CartDto} - cart to convert
 * @param reservation {DraftReservationDto} - reservation to get the related spaces from
 * @returns {IInlineCartItem[]} - inline cart line items converted from the cart
 */
export function convertCartToInlineCartLineItems(cart: CartDto, reservation: DraftReservationDto): IInlineCartItem[] {
	return cart.cartItems.map(cartItem => {
		const relatedSpace: SpaceResourceDto = reservation.spaces?.find(space =>
			cartItem.resources?.find(resource => resource.id === space.id)
		);

		const isTaxInclusive: boolean = Boolean(cartItem.taxItem?.isTaxInclusive);

		return {
			id: `${cartItem.productId}_${cartItem.unitPrice}`,
			type: ProductTypesEnum.RESERVATION,
			price: cartItem.unitPrice,
			totalPrice: cartItem.price,
			productName: cartItem.product?.name,
			resources: cartItem.resources || [],
			spaceName: relatedSpace?.name || '',
			subProducts: [],
			quantity: cartItem.quantity,
			isTaxInclusive,
			taxPrice: !isTaxInclusive ? cartItem.taxItem.price : 0,
			tax: !isTaxInclusive ? cartItem.taxItem.percentage : 0,
		};
	});
}
