import React from 'react';
import { atom, useSetRecoilState } from 'recoil';

// Helper function to validate timezone
const isValidTimezone = (timezone: string): boolean => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone });
    return true;
  } catch (error) {
    return false;
  }
};

// Get the browser's timezone
const getBrowserTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const STORAGE_KEY = 'backoffice_timezone_preference';

/**
 * Local storage effect for persisting timezone preference
 */
const localStorageEffect = (key: string) => ({setSelf, onSet}) => {
  // Try to get stored value
  try {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      const timezone = JSON.parse(savedValue);
      if (isValidTimezone(timezone)) {
        setSelf(timezone);
      }
    }
  } catch (error) {
    console.warn('Failed to restore timezone from localStorage:', error);
  }

  // Listen for changes and update localStorage
  onSet((newValue, _, isReset) => {
    try {
      if (isReset || !newValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    } catch (error) {
      console.warn('Failed to save timezone to localStorage:', error);
    }
  });
};

/**
 * Atom for storing the preferred timezone
 * Defaults to browser timezone and persists to localStorage
 */
export const preferredTimezoneState = atom<string>({
  key: 'preferredTimezone',
  default: getBrowserTimezone(),
  effects: [
    localStorageEffect(STORAGE_KEY)
  ]
});

/**
 * Component to handle global timezone updates.
 * Not yet exposed in the UI, but still helpful for development and QA.
 * 
 * Usage:
 *  window.setPreferredTimezone('America/Los_Angeles');
 *  window.setPreferredTimezone(); // clears preference
 */
const TimezoneUpdater = () => {
  const setTimezone = useSetRecoilState(preferredTimezoneState);

  // Attach the setter to window when component mounts
  React.useEffect(() => {
    const updateTimezone = (timezone: string | null) => {
      try {
        // If clearing preference or empty, use browser default
        if (!timezone?.trim()) {
          const browserTimezone = getBrowserTimezone();
          setTimezone(browserTimezone);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(browserTimezone));
          console.log(`Timezone preference cleared. Using browser timezone: ${browserTimezone}`);
          return true;
        }

        // Validate the timezone
        if (!isValidTimezone(timezone)) {
          console.error(`Invalid timezone: ${timezone}`);
          console.log('You can find a list of valid timezones at: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones');
          return false;
        }

        // Update both Recoil state and localStorage
        setTimezone(timezone);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(timezone));
        console.log(`Timezone preference set to: ${timezone}`);
        return true;
      } catch (error) {
        console.error('Failed to update timezone preference:', error);
        return false;
      }
    };

    (window as any).setPreferredTimezone = updateTimezone;

    // Cleanup
    return () => {
      delete (window as any).setPreferredTimezone;
    };
  }, [setTimezone]);

  return null;
};

export const userStore = {
  preferredTimezoneState,
  TimezoneUpdater,
};
