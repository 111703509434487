import React from 'react';
import ReactDOM from 'react-dom';
import { RCOrganization } from '@rcenter/core';
import {
	AfterViewInit,
	Component,
	ElementRef,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { FacilityController } from 'app/react/components/navigationBar/FacilityController';
import { AuthenticationService } from '@app/shared/services/auth/authentication.service';

@Component({
	selector: 'react-facility-control',
	template: `<div #reactRoot></div>`,
	encapsulation: ViewEncapsulation.Emulated,
})
export class FacilityControllerComponent implements OnDestroy, AfterViewInit, OnChanges {
	@ViewChild('reactRoot') containerRef: ElementRef;
	organization: RCOrganization;

	constructor(private authService: AuthenticationService) {
		// Unnecessary at the moment
		// this.organization = this.authService.currentOrganization.value;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.render(this.organization);
	}

	ngOnInit() {
		// Added this subscription to detect changes in the organization
		this.authService.currentOrganization.subscribe(org => {
			this.organization = org;
			this.render(org);
		});
	}

	ngAfterViewInit() {
		this.render(this.organization);
	}

	ngOnDestroy() {
		ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
	}

	open() {
		console.log('openning anyway  - who cares?');
	}

	private render(organization) {
		if (!this.containerRef) return;

		ReactDOM.render(React.createElement(FacilityController, { organization }), this.containerRef.nativeElement);
	}
}
