import { useState, useEffect, useCallback, useMemo } from 'react';
import { AttendanceEntityTypeEnum, EventWithAttendanceDto } from '@bondsports/types';
import { useAttendance } from './useAttendance';
import { useActivities } from './useActivities';
import { useCustomersEvents } from './useCustomersEvents';
import { useErrorModal } from './useErrorModal';
import { useNotification } from './useNotification';
import { ENotificationType } from '../stores/baseStore';
import useAudio, { SoundEffect } from './useAudio';
import { fullName } from '@bondsports/general';
import { ICustomer } from '../types/customers';
import { addToDateHours, isEarlier, isLater } from '@bondsports/date-time';

// Remember: "Memberships" are an "Activity" with .activityType = "membership"
const MEMBERSHIPS_PER_PAGE = 4;

/**
 * This component handles the logic for checking in a customer, used by the "Customer Check-In Modal".
 * 
 * The logic for "what to check in to" and how to react is documented in the flow diagram on this: https://bond-sports.atlassian.net/browse/BOND-10067
 * 
 * For a better UX, we've implemented the logic in a parallel way, so that auto-check-in to Membership and Event can happen at the same time.
 * This results in the following approach (thread's are conceptual, non-blocking IO allows A and B to run in parallel):
 * 
 *  ...thread A...
 * - If the Customer has an Event starting in < 1hr or started but not ended, auto-check-in to that Event.
 *  ...thread B...
 * - If exactly one Membership, auto-check-in to that Membership.
 * 	...join threads A and B (ie. wait for both to complete)...
 * - Show appropriate Toast and/or hide Modal (see actual "supervisor" logic below)
 * 
 */
export const useCheckInLogic = (
  customerState: ICustomer,
  userId: number,
  organizationId: number,
  toggle: () => void,
  labels: any,
  page: number
) => {

  const customerFullName = useMemo(() => 
    fullName(customerState.firstName, customerState.lastName),
    [customerState.firstName, customerState.lastName]
  );

  // Need seperate hooks so we can call them in parallel and track the state of each individually
  const membershipAttendance = useAttendance(customerState.id, organizationId);
  const eventAttendance = useAttendance(customerState.id, organizationId);
  
  const { 
    fetchAvailableActivities, 
    isFetchingActivities, 
    availableActivities, 
    activitiesMeta,
    fetchActivitiesError 
  } = useActivities();
  const { fetchCustomerEvents, events } = useCustomersEvents(customerState.id, customerState.organizationId);
  const { setErrorModal } = useErrorModal();
  const { setToastNotification } = useNotification();
  const { playSoundEffect } = useAudio();
  const [eventForCheckin, setEventForCheckin] = useState<EventWithAttendanceDto | undefined>();

  const [pendingAutocheckinOperations, setPendingAutocheckinOperations] = useState(2);
  const [didCheckInTo, setDidCheckInTo] = useState<AttendanceEntityTypeEnum[]>([]);
  const decrementPendingAutocheckinOperations = useCallback(() => {
    setPendingAutocheckinOperations(prev => {
      return prev - 1;
    });
  }, []);

  // Consolidated check-in function
  const checkInToEntity = useCallback(async (
    entityId: number, 
    entityType: AttendanceEntityTypeEnum,
    event?: EventWithAttendanceDto,
    onComplete?: () => void
  ) => {
    const attendance = entityType === AttendanceEntityTypeEnum.MEMBERSHIP ? 
      membershipAttendance : eventAttendance;
    
    if (!attendance.isCreatingAttendance) {
      if (event) { setEventForCheckin(event); }
      const createAttendancePromise = attendance.createAttendance(customerState, userId, {
        entityId,
        entityType,
      }, event);

      if (onComplete) {
        await createAttendancePromise;
        onComplete();
      }
    }
  }, [customerState, eventAttendance, membershipAttendance, setEventForCheckin, userId]);

  // Fetch Memberships and Events
  useEffect(() => {
    fetchAvailableActivities(userId, organizationId, page, MEMBERSHIPS_PER_PAGE);
    if (!events) { fetchCustomerEvents(); }
  }, [page, userId, organizationId, customerState.id]);

  // Do Membership auto-check-in
  // Auto check in to first Membership if there is exactly one
  useEffect(() => {
    if (!isFetchingActivities && activitiesMeta && availableActivities.length === 1) {
      checkInToEntity(availableActivities[0].activityId, AttendanceEntityTypeEnum.MEMBERSHIP);
    } else if (!isFetchingActivities && activitiesMeta) {
      decrementPendingAutocheckinOperations();
    }
  }, [activitiesMeta, isFetchingActivities, availableActivities]);

  // Do Event auto-check-in
	// Auto check in to first Event if it's starting in < 1hr or started but not ended
	useEffect(() => {
    if (!events) { return }
    // Only auto-check-in to Events once
    if (didCheckInTo.includes(AttendanceEntityTypeEnum.EVENT)) { return; }

		if (events.length > 0) {
			const now = new Date();
      const oneHourFromNow = addToDateHours(new Date(), 1);

			for (const event of events) {
				const eventStartDate = new Date(event.startDate);
        const eventEndDate = new Date(event.endDate);

        // If the event is starting in < 1hr or started but not ended
				if (isEarlier(eventStartDate, oneHourFromNow) && isLater(eventEndDate, now)) {
					// If we're already checked in to this event; note it, don't check in again
          if (event.attendance?.some(a => a.userId === userId)) {
            setEventForCheckin(event);
						playSoundEffect(SoundEffect.CheckInSuccess);
						setDidCheckInTo(prevState => [...prevState, AttendanceEntityTypeEnum.EVENT]);
						decrementPendingAutocheckinOperations();
						return;
					}

          // Check in to the event (which will decrement the pending operations on return)
          checkInToEntity(event.id, AttendanceEntityTypeEnum.EVENT, event);
          return;
				}
			}
    }
    
    // There were no matching events; decrement the pending operations
		decrementPendingAutocheckinOperations();
  }, [events]);
  
  // Handle check-in responses
  const handleAttendanceResponse = useCallback((
    success: any,
    error: string | null,
    entityType: AttendanceEntityTypeEnum
  ) => {
    if (didCheckInTo.includes(entityType)) { return; }

    if (success) {
      setDidCheckInTo(prev => [...prev, entityType]);
      decrementPendingAutocheckinOperations();
    }
    if (error) {
      setErrorModal({ message: error });
      decrementPendingAutocheckinOperations();
    }
  }, [didCheckInTo, decrementPendingAutocheckinOperations, setErrorModal]);

  useEffect(() => {
    handleAttendanceResponse(
      membershipAttendance.createAttendanceSuccess,
      membershipAttendance.createAttendanceError,
      AttendanceEntityTypeEnum.MEMBERSHIP
    );

    handleAttendanceResponse(
      eventAttendance.createAttendanceSuccess,
      eventAttendance.createAttendanceError,
      AttendanceEntityTypeEnum.EVENT
    );
  }, [
    membershipAttendance.createAttendanceSuccess,
    membershipAttendance.createAttendanceError,
    eventAttendance.createAttendanceSuccess,
    eventAttendance.createAttendanceError,
    handleAttendanceResponse
  ]);

  // Supervisor logic
  // Auto-check-in for "now" Event and single-Membership is allowed to happen in parallel (above); they don't affect each other.
	// Here we implement the rest of the logic around auto-check-in.
  useEffect(() => {
    if (pendingAutocheckinOperations !== 0) return;

    const hasEvent = didCheckInTo.includes(AttendanceEntityTypeEnum.EVENT);
    const hasMembership = didCheckInTo.includes(AttendanceEntityTypeEnum.MEMBERSHIP);

    let message;
    let notificationType = ENotificationType.success;

    if (hasEvent && hasMembership) {
      message = labels.eventAndMembershipCheckinSuccessMessage(customerFullName, eventForCheckin?.title);
      toggle();
    } else if (hasEvent) {
      message = labels.eventCheckinSuccessMessage(customerFullName, eventForCheckin?.title);
      if (activitiesMeta?.totalItems === 0) toggle();
    } else if (hasMembership) {
      message = labels.membershipCheckinSuccessMessage(customerFullName);
      toggle();
    } else if (activitiesMeta?.totalItems === 0) {
      playSoundEffect(SoundEffect.CheckInError);
      message = labels.membershipCheckinFailMessage(customerFullName);
      notificationType = ENotificationType.warning;
      toggle();
    }

    if (message) {
      setToastNotification(message, notificationType);
    }
  }, [pendingAutocheckinOperations, didCheckInTo, activitiesMeta?.totalItems, customerFullName, eventForCheckin]);

  return {
    isFetchingActivities,
    activitiesMeta,
    pendingAutocheckinOperations,
    checkInToEntity,
    isCreatingMembershipAttendance: membershipAttendance.isCreatingAttendance,
    fetchActivitiesError,
    availableActivities
  };
}; 