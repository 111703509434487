/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { Fragment, ReactNode } from 'react';
import {
	fitContentCss,
	flex,
	flexBetweenCss,
	flexCss,
	fullWidthCss,
	gapCss,
	marginRightCss,
	marginTopCss,
	paddingLeftCss,
	paddingRightCss,
	widthPxCss,
} from '@app/react/styles/utils';
import { Divider } from '@app/react/layouts/Divider';
import { Detail } from '../../../utils/Detail';
import {
	CHARACHERS,
	ETypography,
	ETypographyColor,
	IconWithTooltip,
	Icons,
	SkeletonWrapper,
	Tooltip,
	Typography,
} from '@bondsports/utils';
import { Link } from 'react-router-dom';
import { colors } from '@app/react/styles/theme';
import { Card } from './Card';
import { AlertsStatusEnum, ILinkedAccount } from '@app/react/types/customers';
import { DetailsEnum, alertStatusMapper } from './utils';
import { LikedAccountsList } from '../../../customerPage/LinkedAccountsBlock/LikedAccountsList';
import * as dayjs from 'dayjs';
import { EDateTimeFormats } from '@app/react/types/times';
import { AccountLink } from '../../../customerPage/LinkedAccountsBlock/AccountLink';
import { TranslationEn } from '@assets/i18n/en';
import { MailTo } from '@app/react/components/shared/MailTo';
import { SkeletonList } from '@app/react/components/shared/SkeletonsList';
import { nowrapCss, zeroMinWidthCss } from '@app/react/styles/ellipsisStyles';
import { DateTimeFormats, formatDateTime } from '@bondsports/date-time';
import { getYearsDiff } from '../../../../../lib/dates';

interface IStateProps {
	birthDate?: string;
	phoneNumber?: string;
	email: string;
	waiverSignedDate?: string;
	linkedAccounts?: ILinkedAccount[];
	viewWaiverPath: string;
	isLoadingLinkedAccounts: boolean;
}

interface Props {
	status: AlertsStatusEnum;
	profilePictureUrl?: string;
	state: IStateProps;
	isLoading?: boolean;
}

const labels = TranslationEn.customers.overview;

export const PersonalProfile = ({ status, profilePictureUrl, state, isLoading = false }: Props) => {
	const { color, icon } = alertStatusMapper[status];

	return (
		<SkeletonWrapper isLoading={isLoading}>
			<Card color={color} icon={icon} profilePictureUrl={profilePictureUrl}>
				<PersonalDetails {...state} />
			</Card>
		</SkeletonWrapper>
	);
};

const PersonalDetails = ({
	linkedAccounts,
	birthDate,
	phoneNumber,
	email,
	waiverSignedDate,
	viewWaiverPath,
	isLoadingLinkedAccounts,
}: IStateProps) => {
	const bdayString = birthDate
		? `${CHARACHERS.OPEN_PARENTHESES}${getYearsDiff(birthDate)}${TranslationEn.customers.ageSuffix}${
				CHARACHERS.CLOSE_PARENTHESES
		  } ${formatDateTime(birthDate, DateTimeFormats.DAY_FORMAT)}`
		: '';
	return (
		<div css={[fullWidthCss, zeroMinWidthCss]}>
			<div css={[fullWidthCss, marginTopCss(10)]}>
				<div css={flexBetweenCss}>
					<Details birthDate={bdayString} {...{ phoneNumber, email }} />

					<WaiverStatus waiverSignedDate={waiverSignedDate} viewWaiverPath={viewWaiverPath} />
				</div>
				<Divider margin={'20px 0 20px 0'} color={colors.brandPrimary} />
				<div css={fitContentCss}>
					{isLoadingLinkedAccounts ? (
						<SkeletonList isLoading={isLoadingLinkedAccounts} skeletonLength={1} />
					) : (
						<LinkedAccountsBlock linkedAccounts={linkedAccounts} />
					)}
				</div>
			</div>
		</div>
	);
};

const WaiverStatus = ({ waiverSignedDate, viewWaiverPath }: { waiverSignedDate?: string; viewWaiverPath: string }) => {
	return (
		<div css={[flex, gapCss(8)]}>
			{!waiverSignedDate && (
				<IconWithTooltip
					icon={Icons.indications_alert}
					text={labels.waiver.missingWaiver}
					color={colors.dangerRed}
					size={20}
				/>
			)}
			<Link to={viewWaiverPath} css={nowrapCss}>
				<Typography type={ETypography.captionLink} color={ETypographyColor.primary}>
					{labels.waiver.viewWaivers}
				</Typography>
			</Link>
		</div>
	);
};

const LinkedAccountsBlock = ({ linkedAccounts }) => {
	return (
		<Fragment>
			{linkedAccounts?.length ? (
				<LinkedAccounts linkedAccounts={linkedAccounts} />
			) : (
				<Typography type={ETypography.body2Accented} color={ETypographyColor.primary}>
					{labels.linkedAccounts.noLinkedAccounts}
				</Typography>
			)}
		</Fragment>
	);
};

const LinkedAccounts = ({ linkedAccounts }: { linkedAccounts: ILinkedAccount[] }) => {
	const familyMembers = linkedAccounts.filter(account => !account.isFamily);
	const familyAccount = linkedAccounts.find(account => account.isFamily);
	const Wrapper = ({ children }: { children: ReactNode }) => {
		return (
			<Fragment>
				{familyMembers.length ? (
					<Tooltip
						placement="bottom-start"
						content={
							<div css={[paddingLeftCss(14), paddingRightCss(14), widthPxCss(260)]}>
								<LikedAccountsList linkedAccounts={familyMembers} />
							</div>
						}
					>
						{children}
					</Tooltip>
				) : (
					<Fragment>{children}</Fragment>
				)}
			</Fragment>
		);
	};
	return (
		<Wrapper>
			<AccountLink details={familyAccount} typography={ETypography.body2Link} />
		</Wrapper>
	);
};

const Details = (state: { birthDate?: string; phoneNumber?: string; email: string }) => {
	const iconMapper = {
		[DetailsEnum.BIRTHDATE]: Icons.birthday,
		[DetailsEnum.PHONENUMBER]: Icons.phone,
		[DetailsEnum.EMAIL]: Icons.email,
	};
	return (
		<div css={[flexCss, gapCss(30), marginRightCss(20), zeroMinWidthCss]}>
			<div css={[flexCss, gapCss(30)]}>
				{Object.keys(state)
					.filter(d => d !== DetailsEnum.EMAIL)
					.map((detail: DetailsEnum) => {
						const value = state[detail];
						return value ? <Detail key={detail} icon={iconMapper[detail]} detail={value} /> : <Fragment />;
					})}
			</div>

			{state.email && (
				<Detail
					icon={Icons.email}
					detail={
						<MailTo
							value={state.email}
							typographyType={ETypography.body1Accented}
							TypographyColor={ETypographyColor.primary}
						/>
					}
				/>
			)}
		</div>
	);
};
