import * as React from 'react'
import type { DataGridColumnDef } from 'ui';
import { TranslationEn } from '@assets/i18n/en';
import { getPaymentMethodLabelByValue } from '@app/react/components/payments/utils/paymentMethods';
import { PaymentMethodTypeEnum, PaymentStatusEnum } from '@bondsports/types';
import { PaymentChargeStatusEnum } from '@app/react/types/payment';
import { filterPaymentMethods } from '@app/react/components/shared/ChooseInvoice/utils';
import { EStatusColorMapper } from '@app/react/types/customers';

export interface PaymentColumnDef {
  createdAt: Date;
  id: number;
  total: string;
  paymentMethod: PaymentMethodTypeEnum;
  status: PaymentStatusEnum;
  invoices: number[];
}

const columns = TranslationEn.customers.paymentsInvoices;
const { paymentsTableLabelMapper } = TranslationEn;

export const paymentsColumns: DataGridColumnDef<PaymentColumnDef>[] = [
  {
    key: 'createdAt',
    header: columns.createdOn,
    type: 'datetime',
  },
  {
    key: 'id',
    header: columns.receiptNum,
    type: 'text',
  },
  {
    key: 'invoices',
    header: columns.invoiceNum,
    type: 'text',
    parse: (invoices) => {
      return columns.invoicesNums(invoices);
    }
  },
  {
    key: 'total',
    header: columns.total,
    type: 'text',
    sortable: false,
  },
  {
    key: 'paymentMethod',
    header: columns.paymentMethod,
    type: 'text',
    sortable: false,
    filter: {
      paramName: 'paymentTypes',
      options: filterPaymentMethods,
    },
    parse: (value) => {
      return getPaymentMethodLabelByValue(value);
    }
  },
  {
    key: 'status',
    header: columns.status,
    type: 'tags',
    sortable: false,
    filter: {
      paramName: 'paymentStatuses',
      options: [
        { value: PaymentStatusEnum.FULLY_PAID, label: PaymentChargeStatusEnum.paid },
        { value: PaymentStatusEnum.REFUNDED, label: PaymentChargeStatusEnum.refunded },
        { value: PaymentStatusEnum.PENDING, label: PaymentChargeStatusEnum.pending },
        { value: PaymentStatusEnum.NOT_PAID, label: PaymentChargeStatusEnum.failed },

      ],
    },
    parse: (status) => {
      return [{
          value: paymentsTableLabelMapper[status],
          color: EStatusColorMapper[status],
      }];
    }
  }
];
