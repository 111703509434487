/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';

import { useSlot } from '../../../hooks/useSlot';
import { GenericContainer } from '../GenericContainer';
import { ColorCode } from '../types';

//TODO: check if theres a way to avoid inline style without !important..
/** inline css is rewuired here to enforce the style, otherwise it get override */
const containerChildInlineCss = {
	display: 'flex',
	overflowX: 'hidden' as any,
	width: 'max-content',
	alignItems: 'end',
	justifyContent: 'space-between',
	minWidth: '100%',
};

export const ExternalWeekly = ({
	event: state,
	colorCode,
	isConflict = false,
}: {
	event: any;
	triggerEvent: (v: any) => void;
	colorCode?: ColorCode;
	isConflict?: boolean;
}) => {
	const { Name, SpacerX, isDraft, DiffTime, PrivateIndicator, StartTime, IconsContainer } = useSlot({
		state,
		isConflict,
	});

	return (
		<GenericContainer isDraft={isDraft} colorCode={colorCode} key={`ExternalWeekly-${state.id}`}>
			<div style={containerChildInlineCss} data-aid="ExternalWeekly-EventSlot">
				<span
					style={{
						display: 'flex',
					}}
				>
					<Name size="S" />
					<SpacerX width={4} />
					<PrivateIndicator />
				</span>
				<SpacerX width={4} />
				<span style={{ display: 'flex' }}>
					<StartTime />
					<SpacerX width={4} />
					<DiffTime />
				</span>
				<IconsContainer hidePaymentIcons />
			</div>
		</GenericContainer>
	);
};
