import { Addon, Product, IValidateEditReservationAddonsResult } from '@bondsports/types';

import { IAddAddon, IRemoveAddonResp } from 'app/react/types/reservation';
import { InvoiceDto, SlotDto } from 'app/react/types/NewReservation';
import { environment } from '../../../../environments/environment';
import { network } from '../network';
import { IEditAddonReq, IEditAddonRes } from 'app/react/components/shared/EditAddonToReservation/types';
import { IInvoiceSlots } from './newReservationApi';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

async function updatePartialSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	slotId: number,
	data: SlotDto
) {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/slot/${slotId}/partial`,
		data
	);
	return response;
}

const invoiceSlots = async (organizationId: number, reservationId: number, data: IInvoiceSlots) => {
	const response = await network.post(
		`${v4APIUrl}/organization/${organizationId}/reservations/${reservationId}/invoice`,
		data
	);
	return response;
};

async function updateSlotsPrices(
	organizationId: number,
	facilityId: number,
	data: any
): Promise<{ slots: SlotDto[]; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/update-slots-prices`,
		data
	);
	return response;
}

const deleteEventByIds = (organizationId: number, reservationId: number, eventIds: number[]) => {
	return network.delete(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/slots?slotsIds=${eventIds.join(',')}`,
		{}
	);
};

const getEventFinancialDataByIds = (organizationId: number, reservationId: number, eventIds: number[]) => {
	return network.get(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/removed-slots?slotsIds=${eventIds.join(
			','
		)}`,
		{}
	);
};

const deleteReservationAddon = (
	organizationId: number,
	reservationId: number,
	addonIds: number[]
): Promise<IRemoveAddonResp> => {
	return network.delete(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons?addonsIds=${addonIds.join(',')}`,
		{}
	);
};

const addReservationAddon = async (
	organizationId: number,
	reservationId: number,
	data: IAddAddon
): Promise<Addon[]> => {
	const { newAddons } = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons`,
		data
	);
	return newAddons;
};

const getAddonsForReservation = (organizationId: number, reservationId: number): Promise<Product[]> => {
	return network.get(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons-options`);
};

const postConfirmEditAddonResv = (
	organizationId: number,
	reservationId: number,
	data: IEditAddonReq
): Promise<IValidateEditReservationAddonsResult> => {
	return network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/validate-edit-addons`,
		data
	);
};

const putEditAddonResv = (
	organizationId: number,
	reservationId: number,
	data: IEditAddonReq
): Promise<IEditAddonRes> => {
	return network.put(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons`, data);
};

export const bookingV2Api = {
	updatePartialSingleSlot,
	updateSlotsPrices,
	invoiceSlots,
	deleteEventByIds,
	deleteReservationAddon,
	addReservationAddon,
	getAddonsForReservation,
	postConfirmEditAddonResv,
	putEditAddonResv,
	getEventFinancialDataByIds,
};
