/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';
import { Fragment, ReactNode } from 'react';

import { BnIcon } from '../../../components/icons_v2';
import { useSlot } from '../../../hooks/useSlot';
import { Icons } from '../../../types';
import { GenericContainer } from '../GenericContainer';
import { maintenanceIconCss, DailyShortCss, flexStart } from '../style';
import { MaintenanceTimingEnum as MaintTime, ISlot, ColorCode, TPxHeightSetter, TMaintAtEndHeight } from '../types';

/** inline css is required here to enforce the style, otherwise it get override */
const MaxContentCenterCss = {
	display: 'flex',
	alignItems: 'center',
	width: 'max-content',
};
//TODO: check if theres a way to avoid inline style without !important..
/** inline css is required here to enforce the style, otherwise it get override */
const containerChildInlineCss = {
	display: 'flex',
	overflowX: 'hidden' as any,
	width: 'max-content',
	alignItems: 'end',
	justifyContent: 'space-between',
	minWidth: '100%',
};

const maintHeaderCss = css`
	display: flex;
	width: 100%;
`;

const childrenCss = css`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	margin-right: 12px;
`;

dayjs.extend(customParseFormat.default);
dayjs.extend(utc.default);

export enum ECalendarMode {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

interface IMaintenanceSlot {
	slot: ISlot;
	isDragging?: boolean;
	isConflict?: boolean;
	isNestedEvent?: boolean;
	isTrailing?: boolean;
	height?: number;
	colorCode?: ColorCode;
	maintAtEndHeight?: TMaintAtEndHeight;
	setHeightFromRef?: TPxHeightSetter;
}

interface ISizedMaintenance {
	slot: ISlot;
	isConflict?: boolean;
	arrowIcon?: string;
}

interface IMaintHeader extends ISizedMaintenance {
	nameSize: 'S' | 'M';
	children?: ReactNode;
}

const MaintenanceHeader = ({ slot, isConflict = false, arrowIcon = '', nameSize, children }: IMaintHeader) => {
	const { Name, SpacerX } = useSlot({
		state: slot,
		isConflict,
	});
	return (
		<div css={maintHeaderCss} data-aid="index-MaintenanceSlot">
			<div css={flexStart}>
				<BnIcon icon={Icons.maintenance} css={maintenanceIconCss} className="sub" />
				<BnIcon icon={Icons[arrowIcon]} css={maintenanceIconCss} className="main" />
				<SpacerX width={4} />
				<Name size={nameSize} />
			</div>
			<div css={childrenCss}>{children}</div>
		</div>
	);
};

const ShortMaintenance = ({ slot, isConflict = false, arrowIcon = '' }: ISizedMaintenance) => {
	const { TimeComponent, SpacerX, IconsContainer } = useSlot({
		state: slot,
		isConflict,
	});

	return (
		<div css={DailyShortCss}>
			<MaintenanceHeader slot={slot} isConflict={isConflict} arrowIcon={arrowIcon} nameSize={'S'}>
				<SpacerX width={24} />
				<TimeComponent />
			</MaintenanceHeader>
			<IconsContainer hidePaymentIcons />
		</div>
	);
};

const LongMaintainenance = ({ slot, isConflict = false, arrowIcon = '' }: ISizedMaintenance) => {
	const { TimeComponent, Dot, MaintenanceComponent, SpacerY, IconsContainer } = useSlot({
		state: slot,
		isConflict,
	});
	return (
		<Fragment>
			<div style={MaxContentCenterCss}>
				<TimeComponent />
				<Dot />
				<MaintenanceComponent />
			</div>
			<SpacerY height={5} />
			<MaintenanceHeader slot={slot} isConflict={isConflict} arrowIcon={arrowIcon} nameSize={'M'} />
			<IconsContainer hidePaymentIcons />
		</Fragment>
	);
};

export const MaintainanceList = ({
	maintSlots,
	isNestedEvent = false,
	isTrailing = false,
	calcHeight,
	colorCode,
	maintAtEndHeight = null,
	setHeightFromRef,
}: {
	maintSlots: any[];
	isNestedEvent?: boolean;
	isTrailing?: boolean;
	calcHeight: (slot: any) => number;
	colorCode?: ColorCode;
	maintAtEndHeight?: TMaintAtEndHeight;
	setHeightFromRef?: TPxHeightSetter;
}) => {
	return (
		<Fragment>
			{maintSlots.map((maint: any) => {
				const maintSlotHeight = calcHeight(maint);
				return (
					<Maintenance
						key={`innerMaintainance-${maint.id}`}
						isNestedEvent={isNestedEvent}
						isTrailing={isTrailing}
						slot={maint}
						isDragging={maint.isDragging || false}
						isConflict={maint.isConflict}
						height={maintSlotHeight}
						colorCode={colorCode}
						maintAtEndHeight={maintAtEndHeight}
						setHeightFromRef={setHeightFromRef}
					/>
				);
			})}
		</Fragment>
	);
};

export const Maintenance = ({
	slot,
	isDragging,
	isConflict = false,
	isNestedEvent = false,
	isTrailing = false,
	height,
	colorCode,
	maintAtEndHeight = null,
	setHeightFromRef,
}: IMaintenanceSlot) => {
	const hasConflicts = isConflict || !!slot.conflictsCount;
	const { isShort, isDraft } = useSlot({
		state: slot,
		isConflict: hasConflicts,
	});
	const slotColors = slot.colorCode ?? colorCode;
	const { maintenanceTiming } = slot;
	let isTopBorderFlat = false;
	let isBottomBorderFlat = false;
	let arrowIcon = '';

	if (+maintenanceTiming === MaintTime.BEFORE || +maintenanceTiming === MaintTime.AT_THE_BEGINING) {
		isBottomBorderFlat = true;
		arrowIcon = Icons.corner_right_down;
	} else if (+maintenanceTiming === MaintTime.AFTER || +maintenanceTiming === MaintTime.AT_THE_END) {
		isTopBorderFlat = true;
		arrowIcon = Icons.corner_right_up;
	}

	return (
		<GenericContainer
			key={`Maintenance-${slot.id}`}
			isDraft={isDraft}
			colorCode={slotColors}
			isDaily
			isShort={isDragging ? false : isShort}
			isTopBorderFlat={isTopBorderFlat}
			isBottomBorderFlat={isBottomBorderFlat}
			isNestedEvent={isNestedEvent}
			isTrailing={isTrailing}
			height={height}
			maintAtEndHeight={maintAtEndHeight}
			setHeightFromRef={setHeightFromRef}
		>
			{isShort ? (
				<ShortMaintenance slot={slot} isConflict={hasConflicts && !arrowIcon} arrowIcon={arrowIcon} />
			) : (
				<LongMaintainenance slot={slot} isConflict={hasConflicts && !arrowIcon} arrowIcon={arrowIcon} />
			)}
		</GenericContainer>
	);
};

export const MaintenanceWeekly = ({
	state,
	colorCode,
	isConflict = false,
}: {
	state: any;
	colorCode?: ColorCode;
	isConflict?: boolean;
}) => {
	const { Name, SpacerX, DiffTime, StartTime, IconsContainer, isDraft } = useSlot({
		state,
		isConflict,
	});

	return (
		<GenericContainer isDraft={isDraft} colorCode={colorCode} key={`MaintenanceWeekly-${state.id}`}>
			<div style={containerChildInlineCss}>
				<Name size="S" />
				<SpacerX width={4} />
				<span style={{ display: 'flex' }}>
					<StartTime />
					<SpacerX width={4} />
					<DiffTime />
				</span>
				<IconsContainer hidePaymentIcons />
			</div>
		</GenericContainer>
	);
};
