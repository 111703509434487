import _ from 'lodash';
import { formatDate } from '@app/react/lib/dates';
import { EDateTimeFormats } from '@app/react/types/times';

export const removeLastPartOfUrl = (url: string) => {
	const urlArr = url.split('/');
	urlArr.pop();
	return urlArr.join('/');
};

export function arrayToString(array: any[]) {
	return array.join(',');
}

export function buildQueryString(obj: any) {
	const search = obj.search ? encodeURIComponent(obj.search) : '';
	const nameSearch = obj.nameSearch ? encodeURIComponent(obj.nameSearch) : '';
	let query = _(obj)
		.assign({ search, nameSearch })
		.map((value, param) => {
			let valueString = Array.isArray(value) ? arrayToString(value) : value;

			// Include properties that are dates
			if (valueString !== undefined && valueString instanceof Date) {
				return `${param}=${formatDate(valueString, EDateTimeFormats.YYYY_MM_DD)}`;
			}

			// Include properties that are false, but exclude null, undefined, or empty strings
			if (valueString !== undefined && valueString !== null && valueString !== '') {
				return `${param}=${valueString}`;
			}
		})
		.filter(value => value !== undefined) // Keep 'false', but remove undefined/null
		.join('&');

	return query ? `?${query}` : '';
}
