import { DAYS_IN_A_WEEK } from '@app/react/lib/dates';
import { MINUTES_PER_HOUR } from '@bondsports/utils';
import { ESessionResourceSettingKey, ResourceSettingsDto } from '../../../types/resourceSettings';
import { RegistrationPeriodTypeEnum } from '../createNewSession/types/enums';

export const numberOrNull = val => (val == null || isNaN(val) ? null : Number(val));

/**
 * Function to get the value of a specific resource setting by its key
 * @param {ResourceSettingsDto[]} resourceSettings - A list of resource settings
 * @param {ESessionResourceSettingKey} key - The key of the specific resource setting to get the value for
 * @returns The value of the resource setting if it exists, otherwise null
 */
export const getResourceSettingValueByKey = (
	resourceSettings: ResourceSettingsDto[],
	key: ESessionResourceSettingKey
) => {
	const resourceSetting = resourceSettings.find(rs => rs.key === key);
	return resourceSetting ? resourceSetting.value : null;
};

const DAYS_IN_MONTH = 30;

export const calculateRegistrationConstraints = (numDays: number, numMin: number): {
  type: RegistrationPeriodTypeEnum,
  num: number,
} => {
  if (numMin) {
      return {
        type: RegistrationPeriodTypeEnum.HOURS,
        num: numMin / MINUTES_PER_HOUR,
    }
  }
  if (numDays) {
    if (numDays % DAYS_IN_MONTH === 0) {
      return {
        type: RegistrationPeriodTypeEnum.MONTHS,
        num: numDays / DAYS_IN_MONTH,
      }
    }

    if (numDays % DAYS_IN_A_WEEK === 0) {
      return {
        type: RegistrationPeriodTypeEnum.WEEKS,
        num: numDays / DAYS_IN_A_WEEK,
      }
    }

    return {
      type: RegistrationPeriodTypeEnum.DAYS,
      num: numDays,
    }
  }
  return null;
}

export const getAgeNumberFromAgeString = (ageString: string): string => {
  return ageString ? ageString.split(' ')[1] : '';
}
