/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useState, useEffect } from "react";
import { jsx, css, useTheme } from "@emotion/react";
import { capitalize } from "../../functions";
import { IColors, Icons } from "../../types/theme";
import { BnIcon } from "../icons_v2";

const toggleContainer = (disabled: boolean) => css`
  position: relative;
  cursor: ${disabled ? "default" : "pointer"};
  width: min-content;
`;

const toggleBackground = (
  isPressed: boolean,
  colors: IColors,
  isSecondLabel: boolean,
  disabled: boolean
) => css`
  display: block;
  width: 36px;
  height: 20px;
  opacity: ${disabled ? 0.5 : 1};
  background: ${
    isSecondLabel
    ? colors.tx_text_primary
    : isPressed
    ? colors.t_green
    : colors.tx_text_teriaty};
  border-radius: 100px;
`;

const toggleCircle = (
  isPressed: boolean,
  colors: IColors,
  isSecondLabel: boolean,
  disabled: boolean
) => css`
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  border-radius: 100px;
  cursor: ${disabled ? "default" : "pointer"};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  margin-left: ${isPressed ? "16px" : "0"};
  background: ${colors.bg_white};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${colors.tx_text_primary};
  }
`;
const iconCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const mainContainer = (colors: IColors, disabled: boolean) => css`
  display: flex;
  align-items: center;

  label {
    color: ${disabled ? colors.tx_text_disabled : colors.tx_text_primary};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
  }
  .l {
    margin: 0;
    margin-left: 8px;
  }
  .r {
    margin: 0;
    margin-right: 8px;
  }
  .S {
    font-size: 14px;
    line-height: 130%;
  }
  .M {
    font-size: 16px;
    line-height: 20px;
  }
`;

export interface Props {
  checked?: boolean;
  setPressed?: (value: boolean) => void;
  label?: string;
  secondLabel?: string;
  disabled?: boolean;
}

export const Toggle = ({
  setPressed,
  label = "",
  secondLabel = "",
  disabled = false,
  ...props
}: Props) => {
  const colors = useTheme();
  const [innerPressed, setInnerPressed] = useState(props.checked || false);

  useEffect(() => {
    setInnerPressed((props as { checked: boolean }).checked);
  }, [(props as { checked: boolean }).checked]);

  const handlePress = () => {
    if (!disabled) {
      if (setPressed) {
        setPressed(!innerPressed);
      }
      setInnerPressed(!innerPressed);
      //@ts-ignore
      if (props.onChange) {
        //@ts-ignore
        props.onChange(!innerPressed);
      }
    }
  };

  const isSecondLabel = secondLabel ? true : false;

  return (
    <div data-aid="toggle2" css={mainContainer(colors, disabled)}>
      {secondLabel && <label className="r">{capitalize(secondLabel)}</label>}
      <div
        data-aid="toggle3"
        css={toggleContainer(disabled)}
        onClick={handlePress}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === " ") {
            e.preventDefault();
            handlePress();
          }
        }}
      >
        <span 
          data-aid="toggle-span1"
          css={toggleBackground(innerPressed, colors, isSecondLabel, disabled)}
        ></span>
        <span data-aid="toggle1-circle" css={toggleCircle(innerPressed, colors, isSecondLabel, disabled)}>
          {/* {innerPressed } */}
          {isSecondLabel && <BnIcon icon={Icons.check} />}
        </span>
      </div>
      {label && <label data-aid="toggle-label" className="l">{capitalize(label)}</label>}
    </div>
  );
};
