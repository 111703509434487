/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useState, Fragment, useMemo, useCallback, useEffect, ReactNode, memo } from 'react';

import { Popup } from '../../components/Popup';
import { useSlot } from '../../hooks/useSlot';
import { IOption } from '../../types';
import { EventSlot } from './EventSlot';
import { ExternalWeekly } from './EventSlot/ExternalWeekly';
import { InternalWeekly } from './EventSlot/InternalWeekly';
import { Maintenance, MaintenanceWeekly } from './MaintenanceSlot';
import { SlotBody } from './popupBody';
import { ColorCode, ITimeSlot } from './types';
import { ResourceNameTypeEnum } from '../../types/enums';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

enum SlotTypeEnum {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
	MAINTENANCE = 'maintenance',
	CUSTOM = 'custom',
}

export enum ECalendarMode {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

type SlotProps = {
	event: any;
	triggerEvent: (v: any) => void;
	mode: ECalendarMode;
	isDragging?: boolean;
	isConflict?: boolean;
	conflictCount?: number;
	activeId?: number;
	fetchExpandModeSlot?: (slotId: number, reservationId: number, slotType: string) => any;
	setActivePopup: (id: number) => void;
	Popup?: {
		Component: ReactNode;
		toggle: () => void;
		isOpen: boolean;
	};
	moreOptions?: IOption[];
	onContextMenu?: any;
	handleActions?: (v: string, state: any) => void;
	goToReservation?: (reservationId: number) => void;
	goToConflicts?: (val: ITimeSlot) => void;
	colorCode?: ColorCode;
};

export const Slot = memo((props: SlotProps) => {
	const [isShowing, setShowing] = useState<boolean>(false);
	const [expandedData, setExpandedData] = useState<any>(null);
	const { isMaintenance, isDaily, isInternal } = useMemo(() => {
		const { event, mode } = props;
		return {
			isMaintenance: event.slotType === SlotTypeEnum.MAINTENANCE,
			isDaily: mode === ECalendarMode.DAILY,
			isInternal: event?.slotType === 'internal',
		};
	}, [props]);

	const { isEditable, startTime, endTime, isDraft } = useSlot({ state: props.event });

	useEffect(() => {
		if (isShowing) {
			(async () => {
				if (props.fetchExpandModeSlot) {
					const data = await props.fetchExpandModeSlot(
						props.event.id,
						props.event.reservation?.id ?? props.event.reservationId,
						props.event.slotType
					);

					setExpandedData({ ...data, conflictCount: props.conflictCount ?? 0 });
				}
			})();
		}
	}, [isShowing]);

	const toggle = () => setShowing(!isShowing);

	const isProgram = props?.event?.event?.parentType === ResourceNameTypeEnum.PROGRAM_SEASON;

	if (isDraft) {
		return (
			<span style={{ cursor: 'default' }} key={`Draft-SlotView-${props.event.id}`} data-aid="slot-wrapper">
				<SlotView isMaintenance={isMaintenance} isDaily={isDaily} isInternal={isInternal} {...props} />
			</span>
		);
	}

	if (isProgram) {
		return (
			<div style={{ height: '100%' }} key={`Program-SlotView-${props.event.id}`} data-aid="slot-wrapper">
				<Fragment key={`Program-SlotView-${props.event.id}`}>
					<span onClick={props.Popup?.toggle} onContextMenu={props.onContextMenu}>
						<SlotView isMaintenance={isMaintenance} isDaily={isDaily} isInternal={isInternal} {...props} />
					</span>
					{props.Popup?.isOpen && props.Popup?.Component}
				</Fragment>
			</div>
		);
	}

	return (
		<div style={{ height: '100%' }} key={`Rental-SlotView-${props.event.id}`} data-aid="slot-wrapper">
			<Popup
				disableMaxHeight
				disableAutoTrigger
				trigger={isShowing}
				onOpenChange={setShowing}
				padding={0}
				limitPlacements={false}
				key={`Rental-SlotPopup-${props.event.id}`}
				body={
					<SlotBody
						toggle={toggle}
						triggerEvent={props.triggerEvent}
						state={{ ...props.event, startTime, endTime }}
						isEditable={isEditable}
						expandedData={expandedData}
						handleActions={props.handleActions}
						moreOptions={props.moreOptions}
						goToReservation={props.goToReservation}
						goToConflicts={props.goToConflicts}
					/>
				}
			>
				<span onClick={toggle} onContextMenu={props.onContextMenu}>
					<SlotView isMaintenance={isMaintenance} isDaily={isDaily} isInternal={isInternal} {...props} />
				</span>
			</Popup>
		</div>
	);
});

type PropsView = {
	isMaintenance: boolean;
	isDaily: boolean;
	isInternal: boolean;
} & SlotProps;

const SlotView = ({ isMaintenance, isDaily, isInternal, ...props }: PropsView) => {
	if (isMaintenance) {
		return isDaily ? (
			<Maintenance
				slot={props.event}
				isDragging={props.isDragging}
				isConflict={props.isConflict}
				colorCode={props.colorCode}
			/>
		) : (
			<MaintenanceWeekly state={props.event} isConflict={props.isConflict} colorCode={props.colorCode} />
		);
	}
	if (isInternal) {
		return isDaily ? (
			<EventSlot isInternal={true} {...props} />
		) : (
			<InternalWeekly state={props.event} isConflict={props.isConflict} colorCode={props.colorCode} />
		);
	}

	return isDaily ? <EventSlot isInternal={false} {...props} /> : <ExternalWeekly {...props} />;
};
