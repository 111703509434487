export enum RegistrationPeriodTypeEnum {
	HOURS = 'hours',
	DAYS = 'days',
	WEEKS = 'weeks',
	MONTHS = 'months',
}

// todo: import enum @bondsports/types

export enum SegmentTypeEnum {
	SLOT = 'slot',
	DATE = 'date',
	CUSTOM = 'custom',
}

export enum SegmentOccurrenceTypeEnum {
	WEEK = 'week',
	MONTH = 'month',
}
