import { DEFAULT_TIMEZONE, getStartOfDay } from "@bondsports/date-time";
import { AttendanceDto, EventWithAttendanceDto } from "@bondsports/types";
import { customersApi } from "../lib/api/customersApi";
import { addDays } from 'date-fns';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useMemo } from 'react';

export const DEFAULT_EVENTS_UNTIL_DAYS_FROM_NOW = 7;

/**
 * Custom hook to fetch Events and Attendance for a Customer.
 *
 * @param {number} eventsUntilDaysFromNow - Number of days from now until which events should be fetched.
 * @returns {Object} - An object containing:
 *   - `fetchCustomerEvents`: Function to fetch events by setting the current customer and organization.
 *   - `events`: Array of events with attendance data.
 *   - `isLoadingEvents`: Boolean indicating if events are currently being loaded.
 *   - `isLoadingEventsFailed`: Boolean indicating if loading events failed.
 *   - `updateEvent`: Function to update the events array.
 */
export const useCustomersEvents = (customerId: number, organizationId: number, eventsUntilDaysFromNow = DEFAULT_EVENTS_UNTIL_DAYS_FROM_NOW) => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number | undefined>();

  const queryKey = ['customerEvents', customerId, organizationId, page, itemsPerPage];
  
  const dateRange = useMemo(() => {
    const eventsFrom = getStartOfDay(new Date(), true, DEFAULT_TIMEZONE) as Date;
    const eventsUntil = addDays(eventsFrom, eventsUntilDaysFromNow);
    return { eventsFrom, eventsUntil };
  }, [eventsUntilDaysFromNow]);
  
  const { data: events, isLoading: isLoadingEvents, isError: isLoadingEventsFailed } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await customersApi.getCustomersEvents({
        customerId,
        organizationId,
        startDateAfter: dateRange.eventsFrom,
        startDateBefore: dateRange.eventsUntil,
        page,
        itemsPerPage
      });
      
      return response.data;
    }
  });

  const addAttendanceToEvent = (event: EventWithAttendanceDto | undefined, newAttendance: AttendanceDto | undefined) => {
    if (!event) return;
    
    queryClient.setQueryData(queryKey, (oldEvents: EventWithAttendanceDto[] | undefined) => 
      oldEvents?.map(e => 
        e.id === event.id 
          ? {
              ...e,
              attendance: e.attendance ? [...e.attendance, newAttendance] : [newAttendance]
            }
          : e
      ) ?? []
    );
  };

  const removeAttendanceFromEvent = (event: EventWithAttendanceDto, attendanceId: number) => {
    queryClient.setQueryData(queryKey, (oldEvents: EventWithAttendanceDto[] | undefined) => 
      oldEvents?.map(e => 
        e.id === event.id ? { ...e, attendance: e.attendance.filter(a => a.id !== attendanceId) } : e
      ) ?? []
    );
  };
  
  const fetchCustomerEvents = (newPage = 1, newItemsPerPage?: number) => {
    setPage(newPage);
    setItemsPerPage(newItemsPerPage);
  };
  
  return {
    fetchCustomerEvents,
    events,
    isLoadingEvents,
    isLoadingEventsFailed,
    addAttendanceToEvent,
    removeAttendanceFromEvent
  };
}