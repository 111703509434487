import { TranslationEn } from '@assets/i18n/en';
import { EDateTimeFormats } from '../types/times';
import { formatDate, formatTimeToAmPm } from './dates';
import { DurationUnitTypesEnum, ProductDto, ScheduleDto } from '@bondsports/types';
import { useTimeFormats } from '../hooks/useTimeFormats';

export const generateProductSchedule = (schedules: ScheduleDto[]) => {
	const { scheduleString } = TranslationEn;
	return schedules.map(schedule => {
		const startDate = formatDate(schedule.availabilityStartDate, EDateTimeFormats.MMM_DD);
		const startTime = formatTimeToAmPm(schedule.open);
		const endDate = formatDate(schedule.availabilityEndDate, EDateTimeFormats.MMM_DD);
		const endTime = formatTimeToAmPm(schedule.close);

		return scheduleString(startDate, startTime, endDate, endTime);
	});
};

export const rentalProductDuration = (product: ProductDto, withSpace?: boolean) => {
	return generateRentalProductDuration(
		Boolean(product.durationDays),
		product.durationDays ?? product.durationMinutes,
		withSpace
	);
};

export const generateRentalProductDuration = (isDays: boolean, durationValue: number, withSpace: boolean = false) => {
	const { generateDelta, generateDurationDays } = useTimeFormats();
	return isDays ? generateDurationDays(durationValue) : generateDelta(durationValue ?? 0, withSpace);
};
