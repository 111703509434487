import { PaginationMetaDto } from '@bondsports/types';
import { useState, useEffect } from 'react';
import { FetchDataCallback, TFetchOptions } from '../types/fetchData';
import { useInView } from './useInView';
import { isErrorResponse } from '../lib/utils';

export const useInfiniteScroll = <T extends unknown, F, M = any>({
	fetchCallback,
	initialItemsPerPage,
	fetchOptions,
	initialItems,
	initialMeta,
}: {
	fetchCallback: FetchDataCallback<T, F>;
	initialItemsPerPage?: number;
	fetchOptions?: TFetchOptions<T>;
	initialItems?: T[];
	initialMeta?: PaginationMetaDto;
}) => {
	const [items, setItems] = useState<T[]>(initialItems || []);
	const [meta, setMeta] = useState<PaginationMetaDto>(
		initialMeta || {
			totalItems: 0,
			itemsPerPage: initialItemsPerPage || 0,
			totalPages: 0,
			currentPage: 0,
		}
	);

	const initialHasMore = initialMeta ? initialMeta.currentPage < initialMeta.totalPages : true;
	const [hasMore, setHasMore] = useState(initialHasMore);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');

	const resetState = () => {
		setItems([]);
		setHasMore(true);
	};

	const refetch = async (newFilters?: F) => {
		resetState();
		await loadItems(false, newFilters);
	};
	const loadItems = async (append = true, filters?: F) => {
		if (isLoading) return;

		setIsLoading(true);

		try {
			const nextPage = append ? meta.currentPage + 1 : 1;
			const response = await fetchCallback({
				page: nextPage,
				itemsPerPage: meta.itemsPerPage,
				filters,
			});

			if (!response) {
				return;
			}

			if (isErrorResponse(response)) {
				throw response;
			}

			fetchOptions?.onSuccess?.(response);
			setMeta({ ...response.meta, currentPage: nextPage });
			setHasMore(nextPage < response.meta.totalPages);
			setItems(append ? [...items, ...response.items] : response.items);
		} catch (error) {
			setHasMore(false);
			fetchOptions?.onError?.(error);
			setError(error);
		} finally {
			setIsLoading(false);
			fetchOptions?.onComplete?.();
		}
	};

	const { onView, isInView } = useInView();

	useEffect(() => {
		if (isInView && hasMore && !isLoading) {
			loadItems(true);
		}
	}, [isLoading, isInView, hasMore, loadItems]);

	return {
		items,
		setItems,
		refetch,
		meta,
		hasMore,
		isLoading,
		error,
		lastItemElementRef: onView,
		loadItems,
		resetState,
		setHasMore,
	};
};
