import { useEffect, useState } from 'react';
import { useDebouncedValue } from '../calendar/hooks/useDebouncedValue';
import { useInfiniteScroll } from './useInfiniteScroll';
import { FetchDataCallback } from '../types/fetchData';
import { BaseFilters, IUseSelectInitialData, IUseSelectResult } from '../components/Select_v2/types';
import { SELECT_ITEMS_PER_PAGE_DEFAULT } from '../lib/constants';

const INITIAL_SEARCH = '<^>';
const DELAY_MS = 300;

/**
 * Custom hook for handling select with pagination and search functionality .
 * @param {Object} options - The options for the hook.
 * @param {Function} options.fetchData - The function to fetch data for the select options.
 * @param {Object} options.initialState - The initial state for the select options.
 * @param {Object} options.initialItemsPerPage - The initial page number for fetching the first page.
 * @returns {Object} - The result object containing the select state and functions.
 */
export const useSelect = <T, F extends BaseFilters = BaseFilters>({
	fetchData,
	initialState,
	initialItemsPerPage = SELECT_ITEMS_PER_PAGE_DEFAULT,
	initialFilters,
}: {
	initialState?: IUseSelectInitialData<T>;
	fetchData: FetchDataCallback<T, F>;
	initialItemsPerPage?: number;
	initialFilters?: F;
}): IUseSelectResult<T> => {
	const [search, setSearch] = useState<string>(INITIAL_SEARCH);
	const isInitialSearch = search === INITIAL_SEARCH;
	const validSearch = isInitialSearch ? '' : search;
	const debouncedSearch = useDebouncedValue(validSearch, DELAY_MS);

	const { items, isLoading, error, lastItemElementRef, refetch, loadItems, meta, resetState } = useInfiniteScroll<
		T,
		{ search?: string }
	>({
		fetchCallback: ({ page, itemsPerPage, filters }: { page: number; itemsPerPage: number; filters }) =>
			fetchData({ page, itemsPerPage, filters }),
		initialItems: initialState?.initialOptions,
		initialMeta: initialState?.initialMeta,
		initialItemsPerPage,
	});

	const handleType = (type: string) => {
		setSearch(type);
	};

	const resetSearch = () => {
		setSearch('');
	};

	const onClick = () => {
		if (items.length === 0 && isInitialSearch) {
			loadItems(true, initialFilters);
		}
	};

	useEffect(() => {
		if (search !== INITIAL_SEARCH) {
			refetch({ search: debouncedSearch });
		}
	}, [debouncedSearch]);

	return {
		items,
		isLoading,
		isError: Boolean(error),
		lastOptionElementRef: lastItemElementRef,
		handleType,
		onClick,
		resetSearch,
		meta,
		resetState,
		refetch,
		debouncedSearch,
	};
};
