/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { FC, Fragment, memo, useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader/index';
import { justifyCenterCss } from '../../../organisms/refundPopup/style';
import { flexCenterCss, fullHeightCss, fullWidthCss } from '../../../styles/utils';
import { spaceColumnContainer } from '../../styles';
import { IDailyViewProps } from '../../types';
import { spaceWidth } from '../styles';
import { CurrentTimeLine } from './CurrentTimeLine';
import { DayToDrop } from './DayToDrop';

export const DailyViewVertical: FC<IDailyViewProps> = memo(
	({ spaces, EventComponent, isModeChanged, onAddNewSlotClick, hourSize, isLoading }) => {
		const [updates, setUpdates] = useState(false);

		useEffect(() => {
			setUpdates(true);
		}, [hourSize]);

		const columnsCount = useMemo(() => {
			return spaces.reduce((acc, { children }) => acc + (children.length || 1), 0);
		}, [spaces]);

		if (updates) {
			setUpdates(false);
			return <Fragment key="DailyViewVertical-updates-placeholder" />;
		}

		if (isLoading) {
			return (
				<div
					data-aid="DailyViewVertical-loader"
					key="DailyViewVertical-Loader"
					css={[
						flexCenterCss,
						justifyCenterCss,
						fullWidthCss,
						css`
							height: 80vh;
						`,
					]}
				>
					<Loader width={60} />
				</div>
			);
		}

		return (
			<Fragment key="DailyViewVertical-Spaces-Wrapper">
				{spaces.map((space, index) => {
					const hiddenEvents = false;

					if (!space.children.length) {
						return (
							<div
								key={`DailyViewVertical-Space-${space.id}-${index}`}
								css={spaceColumnContainer(columnsCount, 1)}
								data-aid="DailyViewVertical-daily"
							>
								<DayToDrop
									id={String(space.id)}
									EventComponent={EventComponent}
									withoutChildren
									isModeChanged={isModeChanged}
									hiddenEvents={hiddenEvents}
									onAddNewSlotClick={onAddNewSlotClick}
								/>
							</div>
						);
					}

					return (
						<div
							key={`DailyViewVertical-DayToDrop-Wrapper-${space.id}`}
							css={spaceColumnContainer(columnsCount, space.children.length || 1)}
						>
							{space.children.map(item => {
								return (
									<DayToDrop
										key={`DailyViewVertical-DayToDrop-Child-${item.id}`}
										id={String(item.id)}
										EventComponent={EventComponent}
										isModeChanged={isModeChanged}
										hiddenEvents={hiddenEvents}
										onAddNewSlotClick={onAddNewSlotClick}
									/>
								);
							})}
						</div>
					);
				})}
				<CurrentTimeLine width={columnsCount * spaceWidth} />
			</Fragment>
		);
	}
);
