import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { permissionStore } from '@app/react/stores/permissionsStore';
import { EDurations } from '@app/react/types/times';
import { useCallback, useEffect, useState } from 'react';
import { uniqueItems } from '@bondsports/general';
import { authApi } from '@app/react/lib/api/authApi';
import { ORGANIZATION_ID } from '@app/react/lib/localStorageKeys';
import { hasPermissions } from '@app/shared/services/permissions/permissions.service';
import { useOrganization } from '@app/react/hooks/useOrganization';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const PERMISSIONS_TTL_IN_MINUTES = 10;


/**
 * Custom hook for managing permissions using a Recoil store.
 * @returns An object containing the permission state and functions to manage permissions.
 */
const usePermissions = () => {
	const [userPermissions, setUserPermissions] = useRecoilState<string[]>(permissionStore.permissions);
	const [lastFetchTimestamp, setLastFetchTimestamp] = useRecoilState<number>(permissionStore.lastFetchTimestamp);
	const [isFetching, setIsFetching] = useState(false);
	const { organizationId } = useOrganization();
	const orgId = organizationId || Number(localStorage.getItem(ORGANIZATION_ID));

	const invalidateFetchTimestamp = useCallback(() => {
		return dayjs().utc().diff(dayjs(lastFetchTimestamp).utc(), EDurations.MINUTES) > PERMISSIONS_TTL_IN_MINUTES;
	}, [lastFetchTimestamp]);

	const fetchPermissions = useCallback(async () => {
		try {
			setIsFetching(true);
			const { data } = await authApi.getMyPermissions(orgId);
			if (!data) {
				throw new Error('No permissions found');
			}

			setUserPermissions(data);
			setLastFetchTimestamp(dayjs().utc().valueOf());
		} catch (err) {
			console.error('Error fetching permissions:', err.message);
			setUserPermissions([]);
		} finally {
			setIsFetching(false);
		}
	}, [orgId, setUserPermissions, setLastFetchTimestamp]);

	useEffect(() => {
		if (orgId && invalidateFetchTimestamp()) {
			fetchPermissions();
		}
	}, [orgId, invalidateFetchTimestamp, fetchPermissions]);

	const checkPermissions = useCallback((requiredPermissions: string[]) => {
		return hasPermissions(userPermissions, requiredPermissions);
	}, [userPermissions]);

	return {
		userPermissions,
		isLoading: isFetching,
		checkPermissions
	};
};

export default usePermissions;
