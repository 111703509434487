import { atom } from 'recoil';
import { Program } from '@bondsports/types';

// should add session interface
const programState = atom<Program>({
	key: 'programState',
	default: {} as Program,
});

const allSessions = atom<any[]>({
	key: 'allSessions',
	default: [],
});

const isFetchingSessions = atom<boolean>({
	key: 'isFetchingSessions',
	default: true,
});

const isProgramFetching = atom<boolean>({
	key: 'isProgramFetching',
	default: true,
});

const isColorEdited = atom<boolean>({
	key: 'isColorEdited',
	default: false,
});

export const programStore = {
	isFetchingSessions,
	programState,
	allSessions,
	isProgramFetching,
	isColorEdited,
};
