import { ChargeReservationResultDto, FuturePaymentStatusEnum, PurchaseCartDto } from '@bondsports/types';
import { TranslationEn } from '../../../../assets/i18n/en';
import { paymentApi } from '../../lib/api/paymentApi';
import { BookingDto } from '../../types/NewReservation';
import { IPurchaseRes } from '../../types/orders';
import { useErrorModal } from '../useErrorModal';
import { TChargeScheduled, IPayProps, TPurchaseData } from './types';
import { IBasicError } from '@app/react/types/errors';
import { isErrorResponse } from '@app/react/lib/network';

export const usePurchase = () => {
	const { setErrorModal } = useErrorModal();
	const generalErrorMessage = TranslationEn.errors.purchase.generalError;

	const handleResponseParse = <T extends { status: FuturePaymentStatusEnum }>(res: T): T => {
		if (res.status === FuturePaymentStatusEnum.FAILED) setFailedPaymentError();
		return res;
	};

	const setFailedPaymentError = () => {
		setErrorModal({ message: generalErrorMessage });
	};

	const handlePurchase = async (
		data: Partial<TPurchaseData>,
		organizationId: number,
		onError?: (response: IBasicError) => void
	): Promise<IPurchaseRes> => {
		const res = await paymentApi.purchase(data, organizationId);

		if (isErrorResponse(res)) {
			onError?.(res);
			return;
		}

		return handleResponseParse(res);
	};

	const handlePurchasePartial = async (data: IPayProps): Promise<IPurchaseRes | IBasicError> => {
		const res = await paymentApi.payPartialBalance(data);

		return handleResponseParse(res);
	};

	const handleChargeScheduled = async (data: TChargeScheduled): Promise<IPurchaseRes> => {
		const res = await paymentApi.chargeSelectedScheduled(data);

		return handleResponseParse(res);
	};

	const handlePurchaseRental = async (
		organizationId: number,
		reservationId: string,
		data: PurchaseCartDto,
		onError: (response: IBasicError) => void
	): Promise<ChargeReservationResultDto> => {
		const res: ChargeReservationResultDto | IBasicError = await paymentApi.rentalPurchase(
			organizationId,
			reservationId,
			data
		);

		if (isErrorResponse(res)) {
			onError?.(res);
			return;
		}

		return handleResponseParse(res);
	};

	return {
		handleChargeScheduled,
		handlePurchase,
		handlePurchasePartial,
		handlePurchaseRental,
	};
};
