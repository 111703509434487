/** @jsx jsx */

import { useNavigation } from '@app/react/hooks/useNavigation';
import { organizationApi } from '@app/react/lib/api/organizationApi';
import { css, Global, jsx } from '@emotion/react';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { Allocation } from 'app/react/components/activites/session/dashboard/widgets/allocation/index';
import { Countdown } from 'app/react/components/activites/session/dashboard/widgets/countdown';
import { ProductsWidget } from 'app/react/components/activites/session/dashboard/widgets/productsCallToAction';
import RecoilDebugObserver from 'app/react/components/shared/RecoilDebugObserver/RecoilDebugObserver';
import { useSession } from 'app/react/hooks/useSession';
import { Mixpanel, MixpanelModules } from 'app/react/lib/mixpanel';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { HashRouter as Router, Route, useHistory } from 'react-router-dom';
import { Season } from 'app/react/types/season';
import { BnProvider } from '@bondsports/utils';
import { RecoilRoot } from 'recoil';
import { globalCss } from '../../styles/global';
import muiTheme from '../../styles/muiTheme';
import { EDateTimeFormats } from '../../types/times';
import ExtraDetails from './ExtraDetails';
import RegistrationDate from './RegistrationDate';
import SeasonDateTime from './SeasonDateTime';
import SeasonDetails from './SeasonDetails';
import { SeasonSessions } from './SeasonSessions';
import { useErrorModalDepricated } from '@app/react/components/shared/ErrorDepricated';
import { TranslationEn } from '@assets/i18n/en';
import { useProgram } from '@app/react/hooks/useProgram';
import { ResourceNameTypeEnum } from '@bondsports/types';

interface SeasonDashboardProps {
	organizationId: number;
	seasonId: number;
}

const flex = css`
	display: flex;
`;

const container = css`
	${flex};
	flex-direction: column;
	width: 100%;
	max-width: 950px;
`;

const rightSideCon = css`
	${flex};
	flex-direction: column;
	margin: 0 1rem;
	width: 68%;
`;

const useSpaces = (sessionState: any) => {
	const { programState } = useProgram();
	const history = useHistory();
	const [areSpacesLoading, setAreSpacesLoading] = useState(true);
	const steps = TranslationEn.activities.steps;

	const redirectToSpaceAllocation = useMemo(() => {
		return () => {
			history.push(
				`/program/${steps.type[programState.type]}/${programState.id}/sessions/${sessionState.id}/space_allocations/add`
			);
		};
	}, [sessionState?.id, programState?.id]);

	const isSpaceSet = useMemo(() => {
		setAreSpacesLoading(false);

		return Boolean(sessionState?.reservationId);
	}, [sessionState?.reservationId]);

	return { isSpaceSet, areSpacesLoading, redirectToSpaceAllocation };
};

export const SeasonDashboard: React.FC<SeasonDashboardProps> = ({ seasonId, organizationId }) => {
	const [season, setSeason] = useState<Season | null>(null);
	const [err, setErr] = useState('');
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const {
		sessionState,
		fetchSegments,
		segments,
	} = useSession();

	const [sessions, setSessions] = useState([]);

	const [unallocatedCount, setUnallocatedCount] = useState<number | null>(null);
	const [totalEventCount, setTotalEventCount] = useState<number | null>(null);

	const { ngNavigate } = useNavigation();

	const showCutoffDate = !dayjs(season?.ageCalcDate, EDateTimeFormats.YYYY_MM_DD).isSame(
		dayjs(season?.startDate, EDateTimeFormats.YYYY_MM_DD)
	);

	const getSeasonRequest = async () => {
		const result = sessionState;
		if (result) {
			const description = String(result.description ?? '');
			const longDescription = String(result.longDescription ?? '');
			setSeason({ ...result, description, longDescription });
		}
	};

	const getSeasonSessions = () => {
		fetchSegments(true).then(res => getUnallocatedEvents(res));
	};

	useEffect(() => {
		// filter needed for scenarios when segments contain all the types, i.e. while navigating from Schedule tab
		setSessions(segments.filter(item => item.segmentType !== ResourceNameTypeEnum.EVENT).sort((a, b) => a.id - b.id));
	}, [segments]);

	const getUnallocatedEvents = async (forceSegmentsIds?: number[]) => {
		const { data } = await organizationApi.getUnallocatedEventIds({
			organizationId: organizationId,
			sessionId: seasonId,
			programId: sessionState.programId,
			segmentsIds: forceSegmentsIds ?? segments.map(s => s.id),
		});
		if (data) {
			setUnallocatedCount(data.length);
		}
	};

	const handleAllocationRedirection = async () => {
		ngNavigate(
			'calendar',
			`?fullpage=true&prgid=${sessionState.programId}&sessid=${seasonId}${
				segments?.length ? `&segsids=${segments.map(s => s.id).join(',')}` : ''
			}&facid=${sessionState.facilityId}&isAllocate=true`
		);
	};

	useEffect(() => {
		if (organizationId) {
			getSeasonRequest();
			getSeasonSessions();
		}
	}, [seasonId, organizationId]);

	return (
		<Fragment>
			{season && (
				<div css={container}>
					<div css={flex}>
						<Countdown />
						<ProductsWidget />
						<Allocation
							unallocatedCount={unallocatedCount}
							eventCount={totalEventCount}
							isLoading={!unallocatedCount && unallocatedCount !== 0}
							isConflicted={false}
							onRedirection={handleAllocationRedirection}
						/>
					</div>
					<div css={flex}>
						<SeasonDetails description={season.description} longDescription={season.longDescription} />
					</div>
					<div css={flex}>
						<SeasonDateTime
							startDate={season.startDate}
							endDate={season.endDate}
							activityTimes={season.activityTimes}
						/>

						<div css={rightSideCon}>
							<RegistrationDate />
							<ExtraDetails
								maxParticipants={season.maxParticipants}
								minAge={season.minAge}
								maxAge={season.maxAge}
								gender={season.gender}
								ageCalcDate={showCutoffDate ? season.ageCalcDate : null}
								isDashboard
							/>
						</div>
					</div>
					<SeasonSessions sessions={sessions} />
				</div>
			)}

			<ErrorModalDepricated message={err} />
		</Fragment>
	);
};

const RouterComponent = props => {
	Mixpanel.register({ module: MixpanelModules.PROGRAMS });
	return (
		<StylesProvider injectFirst>
			<Global styles={globalCss} />
			<MuiThemeProvider theme={muiTheme}>
				<RecoilRoot>
					<BnProvider>
						<RecoilDebugObserver />
						<Router>
							<Route exact path="/" render={() => <SeasonDashboard {...props} />} />
						</Router>
					</BnProvider>
				</RecoilRoot>
			</MuiThemeProvider>
		</StylesProvider>
	);
};

export default RouterComponent;
