import { FormApi } from 'final-form';
import { isEqual } from 'lodash';

type AnyObject = Record<string, any>;

export const getNestedValue = <T>(obj: AnyObject, path?: string): T | undefined => {
	if (!path) return undefined;
	return path.split('.').reduce((acc: any, part: string): any => {
		if (acc === undefined) return undefined;

		const match = part.match(/(\w+)\[(\d+)\]/);
		if (match) {
			const [, key, index] = match;
			return acc[key] && acc[key][Number(index)];
		}
		return acc[part];
	}, obj) as T | undefined;
};

/**
 * Get field name with prefix
 * @param fieldName {string} - field name
 * @param prefix {string} - prefix
 * @returns {string} - field name with prefix
 * @example getFieldName('name', 'prefix') => 'prefix.name'
 * @example getFieldName('name') => 'name'
 */
export function getFieldName(fieldName: string, prefix?: string) {
	return prefix ? `${prefix}.${fieldName}` : fieldName;
}

/**
 * Checks if a specific field or any of its subfields in a form has been modified.
 *
 * @param field - The name of the field to check for modifications.
 * @param form - The form object containing the state and field information.
 * @returns A boolean indicating whether the specified field or any of its subfields has been modified.
 */
export const checkIsModified = (field: string, form: FormApi<any, Partial<any>>) => {
	const { modified } = form.getState();
	const fields: string[] = Object.keys(modified).filter(key => key.startsWith(field));

	if (!fields.length) {
		return false;
	}

	return fields.some(field => {
		const fieldState = form.getFieldState(field);
		return modified[field] || isEqual(fieldState.value, fieldState.initial);
	});
};
