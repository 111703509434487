import { TranslationEn } from '@assets/i18n/en';
import React, { ButtonHTMLAttributes } from 'react';
import { Button, ButtonSize, ButtonVariant } from 'ui';

interface CancelButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	onClick?: () => void;
	label?: string;
	disableCancelIfNoChanges?: boolean;
}

export const CancelButton: React.FC<CancelButtonProps> = ({
	onClick,
	label = TranslationEn.general.buttons.cancel,
	...props
}: CancelButtonProps) => {
	return (
		<Button
			variant={ButtonVariant.HYPERLINK}
			onClick={onClick}
			className="uppercase"
			size={ButtonSize.SM}
			data-testid="cancel-buttton"
			type="button"
			{...props}
		>
			{label}
		</Button>
	);
};
