/** @jsx jsx */
import { useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/react';
import {
	ModalFooterWithTwoButtons,
	ModalHeaderWithPrimaryAndSecondaryLabels,
	ModalLayout,
	ModalWindow,
	capitalizeFirstLetter,
	SuspenseWrapper,
} from '@bondsports/utils';
import { AttendanceEntityTypeEnum } from '@bondsports/types';
import { TranslationEn } from '@assets/i18n/en';
import { ItemsPerPageCalculator, Table } from '@app/react/components/shared/NewTable';
import { AlignType } from '@app/react/components/shared/NewTable/types';
import { alternativeModalCss } from './styles';
import { ICustomer } from '@app/react/types/customers';
import { useCheckInLogic } from '@app/react/hooks/useCheckInLogic';

const labels = TranslationEn.customers.customerHeader.customerCheckinModal;

const CHECKIN_MODAL_COLUMNS = [
	{
		id: 'name',
		label: labels.table.name,
		type: 'string',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'type',
		label: labels.table.type,
		type: 'string',
		styling: { align: AlignType.LEFT },
	},
];

interface IProps {
	customerState: ICustomer;
	userId: number;
	organizationId: number;
	toggle: () => void;
}

export const CustomerCheckInModal = ({ customerState, userId, organizationId, toggle }: IProps) => {
	const tableRef = useRef<HTMLDivElement>(null);
	const { maxHeight } = ItemsPerPageCalculator(tableRef, true);
	const [selected, setSelected] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [tableRows, setTableRows] = useState([]);

	const {
		isFetchingActivities,
		activitiesMeta,
		pendingAutocheckinOperations,
		checkInToEntity,
		isCreatingMembershipAttendance,
		fetchActivitiesError,
		availableActivities
	} = useCheckInLogic(customerState, userId, organizationId, toggle, labels, page);

	// Handle table rows updates
	useEffect(() => {
		if (!isFetchingActivities && activitiesMeta) {
			const rows = availableActivities.map(item => ({
				id: item.activityId,
				name: item.activityName,
				type: capitalizeFirstLetter(item.activityType),
			}));
			setTableRows(rows);
		}
	}, [activitiesMeta, isFetchingActivities, availableActivities]);

	return (
		<ModalWindow isShowing={true} toggle={toggle}>
			<ModalLayout alternativeCss={alternativeModalCss}>
				<SuspenseWrapper loading={pendingAutocheckinOperations > 0 || isFetchingActivities}>
					<ModalLayout.Header data-aid="customer-checkin-modal-header">
						<ModalHeaderWithPrimaryAndSecondaryLabels 
							labels={{ title: labels.title, subtitle: labels.description }} 
						/>
					</ModalLayout.Header>
					<ModalLayout.Content data-aid="customer-checkin-modal-content">
						<Table
							meta={activitiesMeta}
							page={page}
							rows={tableRows}
							columns={CHECKIN_MODAL_COLUMNS}
							subject={labels.subject}
							maxHeight={maxHeight}
							selectedArr={[selected].filter(Boolean)}
							onSelect={setSelected}
							emptyTableMessage={labels.emptyTableMessage}
							isLoading={isFetchingActivities}
							bottomBorder={tableRows.length > 0}
							isRowsError={!!fetchActivitiesError}
							rowsErrorMessage={fetchActivitiesError}
							isSelectRow
							isRadio
							ssr
							pagination
							onPageChange={setPage}
						/>
					</ModalLayout.Content>
					<ModalLayout.Footer data-aid="customer-checkin-modal-footer">
						<ModalFooterWithTwoButtons
							isLoading={isCreatingMembershipAttendance}
							labels={{
								cancel: labels.cancelButtonText,
								next: labels.actionButtonText,
							}}
							nextDisabled={!selected}
							onNext={() => checkInToEntity(selected, AttendanceEntityTypeEnum.MEMBERSHIP, null, toggle)}
							onCancel={toggle}
						/>
					</ModalLayout.Footer>
				</SuspenseWrapper>
			</ModalLayout>
		</ModalWindow>
	);
};
