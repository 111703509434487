/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useColors } from '../../hooks/useColors';
import { useTypography } from '../../hooks/useTypography';
import { flexRowCss, ProductCardContainer } from './style';
import { ETypography, ETypographyColor, Icons } from '../../types';
import { ProductPrice } from '../../types/entities/seasons';
import { Pricify, roundPriceCents } from '../../lib/price';
import { DiscountMethodsEnum, GroupItemsPricing } from '../../types/entities/entitledPrice';
import dayjs from 'dayjs';
import { Typography } from '../../atoms';
import { BnIcon } from '../../components/icons_v2';
import { Button } from '../../components/Button';
import { ProductPaymentPlanTypeEnum, ProductPaymentStatusEnum } from '../../lib/constants';
import { i18n } from '../../lib/i18n/index';
import { ResourceGlDto } from '../../types/entities/product';
import { AccountingCodes } from '../accountingCodes';
import { gapCss, marginBottomCss } from '../../styles/utils';
import { Tag } from '../../components/Tag';
import { MoreMenu } from '../../components/more-menu-button/more-menu-button';
import { formatDateTime, DateTimeFormats } from '@bondsports/date-time';
import { EntitlementDiscountDto } from '@bondsports/types';
import { uniqBy } from 'lodash';

interface IAddon {
	price: number;
	name: string;
	prices: ProductPrice[];
	defaultPriceId: number;
}

interface IOption {
	label: string;
	value: string;
}

interface ISchedule {
	id: number;
	createdAt: string;
	updatedAt: string;
	organizationId: number;
	paymentPlanId: number;
	paymentDate: string;
	deletedAt?: string;
	fixed?: number;
	percent?: number;
}

export interface IProductCardProps {
	withCta?: boolean;
	icon?: Icons;

	state: {
		name?: string;
		description?: string;
		prices?: ProductPrice[];
		isProRated?: boolean;
		entitlementDiscounts?: EntitlementDiscountDto[];
		downpayment?: number;
		addons?: IAddon[];
		tax?: number;
		isTaxInclusive?: boolean;
		registrationStartDate?: string;
		registrationEndDate?: string;
		productPaymentPlans?: ProductPaymentPlan[];
		glCodes?: ResourceGlDto[];
	};
	defaultPriceTitle?: string;
	moreMenu: {
		options?: IOption[];
		handleActions?: (v: string) => void;
	};
	handlePurchase?: (v?: any) => void;
}

type ProductPaymentPlan = {
	id: number;
	maxInstallments?: number;
	dayOfMonth?: number;
	startsInMonths?: number;
	paymentPlanType: ProductPaymentPlanTypeEnum;
	status: ProductPaymentStatusEnum;
	schedule?: ISchedule[];
};

type DiscountsProps = { discounts: EntitlementDiscountDto[] };

type DepositAndPaymentPlanProps = {
	deposit?: number;
	productPaymentPlan?: ProductPaymentPlan;
	productPrice: number;
};

interface IProductToOrder extends ProductPrice {
	ordinal: number;
}

const LABELS = i18n.productCard;

const conflictIconCss = css`
	margin-right: 35px;
	margin-left: 4px;
	width: max-content;
	path {
		fill: #c21f39;
	}
`;

const moreMenuContainerCss = css`
	margin-right: -10px;
	display: block;
	position: absolute;
	left: 255px;
	button {
		padding: 0;
	}
`;

export const ProductCard = ({
	withCta = false,
	icon,
	state: {
		name: title,
		description,
		prices = [],
		registrationStartDate,
		registrationEndDate,
		isProRated,
		tax,
		isTaxInclusive,
		downpayment,
		addons = [],
		entitlementDiscounts = [],
		productPaymentPlans,
		glCodes,
	},
	defaultPriceTitle = '',
	moreMenu: { options = [], handleActions = () => {} },
	handlePurchase,
}: IProductCardProps) => {
	const productPaymentPlan = productPaymentPlans?.find(plan => plan.status === ProductPaymentStatusEnum.ACTIVE);

	const { colors } = useColors();
	const { typography } = useTypography();
	const [height, setHeight] = useState<number>(0);

	const collapsableRef = useRef<HTMLDivElement>(null);

	const [isExpand, setExpand] = useState<boolean>(!withCta);

	const expandToggle = () => setExpand(!isExpand);

	if (collapsableRef?.current?.scrollHeight && collapsableRef?.current?.scrollHeight !== height) {
		setHeight(collapsableRef?.current?.scrollHeight);
	}

	const discounts: EntitlementDiscountDto[] = useMemo(() => {
		return uniqBy(entitlementDiscounts, discount => discount.group?.id);
	}, [entitlementDiscounts]);

	return (
		<div data-product={title} css={ProductCardContainer(colors, typography, isExpand, height || null, withCta)}>
			<div data-aid="productCardHeader" className="header">
				<div className="title">
					<div>
						<BnIcon icon={icon} />
						<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
							{title}
						</Typography>
						{productPaymentPlan &&
							productPaymentPlan.status === ProductPaymentStatusEnum.INACTIVE &&
							productPaymentPlan.paymentPlanType !== ProductPaymentPlanTypeEnum.NO_PLAN && (
								<BnIcon height={'30'} width={'30'} icon={Icons.conflict} css={conflictIconCss} />
							)}
						{withCta && options?.length > 0 && (
							<div css={moreMenuContainerCss}>
								<MoreMenu options={options} handleActions={handleActions} />
							</div>
						)}
					</div>
				</div>
				<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
					{LABELS.registration.title} {formatDateTime(dayjs(registrationStartDate), DateTimeFormats.MMM_DD)} -{' '}
					{formatDateTime(dayjs(registrationEndDate), DateTimeFormats.DAY_FORMAT)}
				</Typography>
			</div>
			<div className="divider" />
			<div className="body">
				<div className="collapsable" ref={collapsableRef}>
					<div className="description">
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{description}
						</Typography>
					</div>
					<div data-aid="prices" className="prices">
						{prices
							.sort((a, b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf())
							.map(price => {
								let newPrice: IProductToOrder = { ...price, ordinal: 1 };
								if (dayjs().valueOf() > dayjs(price.endDate).valueOf()) {
									newPrice['ordinal'] = 10;
								}
								return newPrice;
							})
							.sort((a: IProductToOrder, b: IProductToOrder) => {
								return a.ordinal - b.ordinal;
							})
							.map((price, index) => {
								return (
									<PriceLine
										isDisabled={dayjs().valueOf() > dayjs(price.endDate).endOf('day').valueOf()}
										state={price}
										key={index}
										defaultPriceTitle={defaultPriceTitle}
									/>
								);
							})}
					</div>
					{isProRated && (
						<div className="prorated">
							<BnIcon icon={Icons.check} />
							<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
								{LABELS.prices.prorated}
							</Typography>
						</div>
					)}
					{glCodes && glCodes.length > 0 && (
						<div css={marginBottomCss(12)}>
							<div className="divider" />
							<AccountingCodes glCodes={glCodes} isTooltip isSmall />
						</div>
					)}

					<DiscountsAndPaymentPlan
						discounts={discounts}
						productPaymentPlan={productPaymentPlan}
						deposit={downpayment}
						productPrice={Number(prices[0].price)}
					/>

					{typeof tax !== undefined && (tax as number) > 0 && (
						<div className="tax">
							<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
								{LABELS.tax.tax} {isTaxInclusive ? LABELS.tax.included : ''}
							</Typography>
							<Typography color={ETypographyColor.primary} type={ETypography.body2}>
								{`${tax}%`}
							</Typography>
						</div>
					)}

					{addons.length > 0 && (
						<Fragment>
							<div className="divider" />
							<div data-aid="discounts" className="discounts">
								<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
									{LABELS.addons.title}
								</Typography>
								{addons.map((addon: IAddon, index) => {
									let price = addon.price;
									if (!addon.price) {
										addon.prices.forEach(p => {
											if (p.id === addon.defaultPriceId) {
												price = Number(p.price);
											}
										});
									}
									return (
										<DiscountLine type={DiscountMethodsEnum.AMOUNT} name={addon.name} amount={price} key={index} />
									);
								})}
							</div>
						</Fragment>
					)}
				</div>
				{withCta && (
					<button data-aid="button-productCard-toggle" className="show-button" onClick={expandToggle}>
						<Typography color={ETypographyColor.primary} type={ETypography.body2Link}>
							{isExpand ? LABELS.buttons.seeLess : LABELS.buttons.seeAll}
						</Typography>
					</button>
				)}
			</div>
			{withCta && (
				<Fragment>
					<div className="divider" />
					<div className="footer">
						<Button
							data-aid="button-productCard-purchase"
							type="button"
							theme="secondary"
							sizer="XS"
							onClick={handlePurchase}
						>
							{LABELS.buttons.purchase}
						</Button>
					</div>
				</Fragment>
			)}
		</div>
	);
};

const DiscountsAndPaymentPlan: React.FC<DepositAndPaymentPlanProps & DiscountsProps> = ({
	discounts,
	productPaymentPlan,
	deposit,
	productPrice,
}) => {
	return (
		<Fragment>
			{(discounts?.length || productPaymentPlan || typeof deposit === 'number') && <div className="divider" />}
			<Discounts discounts={discounts} />
			<DepositAndPaymentPlan productPaymentPlan={productPaymentPlan} deposit={deposit} productPrice={productPrice} />
		</Fragment>
	);
};

const Discounts: React.FC<DiscountsProps> = ({ discounts }) => {
	return (
		<Fragment>
			{discounts.length > 0 && (
				<div className="discounts">
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{LABELS.discounts.title}
					</Typography>
					{discounts.map((discount, index) => {
						return (
							<DiscountLine
								key={index}
								name={(discount?.group?.name as string) ?? (discount as any).label}
								amount={discount?.discountValue as number}
								type={discount?.discountMethod as DiscountMethodsEnum}
							/>
						);
					})}
				</div>
			)}
		</Fragment>
	);
};

const DepositAndPaymentPlan: React.FC<DepositAndPaymentPlanProps> = ({ deposit, productPaymentPlan, productPrice }) => {
	const hasDownpayment = typeof deposit === 'number';

	const PaymentPlanDetails = useCallback(() => {
		switch (productPaymentPlan?.paymentPlanType) {
			case ProductPaymentPlanTypeEnum.ROLLING:
				return <RollingPaymentPlanDetails productPaymentPlan={productPaymentPlan} />;
			case ProductPaymentPlanTypeEnum.PAYMENT_DATE:
				return <PaymentDatePlanDetails productPaymentPlan={productPaymentPlan} />;
			case ProductPaymentPlanTypeEnum.CUSTOM:
				return (
					<CustomPaymentPlanDetails
						productPaymentPlan={productPaymentPlan}
						productPrice={productPrice}
						dowpayment={deposit}
					/>
				);
			case ProductPaymentPlanTypeEnum.NO_PLAN:
			default:
				return <Fragment />;
		}
	}, [productPaymentPlan, productPrice]);

	return hasDownpayment ? (
		<div className="paymentPlan">
			<PaymentPlanTitle productPaymentPlan={productPaymentPlan} />
			{hasDownpayment ? (
				<div className="deposit">
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{LABELS.deposit.title}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.body2}>
						{Pricify(deposit)}
					</Typography>
				</div>
			) : (
				<div className="deposit">
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{LABELS.deposit.title}
					</Typography>
					<Typography color={ETypographyColor.primary} type={ETypography.body2}>
						{LABELS.deposit.noDeposit}
					</Typography>
				</div>
			)}
			{productPaymentPlan && <PaymentPlanDetails />}
		</div>
	) : (
		<div style={{ marginBottom: '12px' }} />
	);
};

const PaymentPlanTitle: React.FC<{ productPaymentPlan?: ProductPaymentPlan }> = ({ productPaymentPlan }) => {
	const isPlanNotCanceled: boolean =
		!!productPaymentPlan &&
		!!productPaymentPlan.paymentPlanType &&
		productPaymentPlan.paymentPlanType !== ProductPaymentPlanTypeEnum.NO_PLAN;

	const isPlanInactive: boolean =
		!!productPaymentPlan && productPaymentPlan.status === ProductPaymentStatusEnum.INACTIVE;

	return (
		<div css={[flexRowCss, gapCss(4)]}>
			<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
				{LABELS.paymentPlan.title(!isPlanNotCanceled, productPaymentPlan?.paymentPlanType)}
			</Typography>
			{isPlanNotCanceled && isPlanInactive && <Tag title={LABELS.paymentPlan.inactive} color="red" />}
		</div>
	);
};

const RollingPaymentPlanDetails: React.FC<{ productPaymentPlan: ProductPaymentPlan }> = ({ productPaymentPlan }) => {
	return (
		<Fragment>
			<div style={{ marginBottom: '4px' }}>
				<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
					{LABELS.paymentPlan.rolling.maxInstallments(productPaymentPlan.maxInstallments!)}
				</Typography>
			</div>
			{productPaymentPlan.dayOfMonth ? (
				<div style={{ marginBottom: '4px' }}>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{LABELS.paymentPlan.rolling.dayOfMonth(productPaymentPlan.dayOfMonth!)}
					</Typography>
				</div>
			) : (
				<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
					{LABELS.paymentPlan.rolling.dayOfMonth(productPaymentPlan.dayOfMonth!)}
				</Typography>
			)}
			{!productPaymentPlan.startsInMonths ? (
				<div style={{ marginBottom: '4px' }}>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{LABELS.paymentPlan.rolling.startsImmediately}
					</Typography>
				</div>
			) : (
				<div style={{ marginBottom: '4px' }}>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{LABELS.paymentPlan.rolling.startsInMonths(productPaymentPlan.startsInMonths!)}
					</Typography>
				</div>
			)}
		</Fragment>
	);
};

const PaymentDatePlanDetails: React.FC<{ productPaymentPlan: ProductPaymentPlan }> = ({ productPaymentPlan }) => {
	return (
		<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
			{LABELS.paymentPlan.paymentDate.dueOn(productPaymentPlan.schedule)}
		</Typography>
	);
};

const scheduleCss = css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
`;

const convertFixedToPercent = (fixedAmount: number, price: number) => {
	return roundPriceCents((fixedAmount / price) * 100);
};

const convertPercentToFixed = (percentage: number, price: number) => {
	return roundPriceCents(price * (percentage / 100));
};

const CustomPaymentPlanDetails: React.FC<{
	productPaymentPlan: ProductPaymentPlan;
	productPrice: number;
	dowpayment?: number;
}> = ({ productPaymentPlan, productPrice, dowpayment = 0 }) => {
	let percentageLeft: number = 100;
	return (
		<Fragment>
			{
				<div>
					{productPaymentPlan
						.schedule!.sort((a, b) => a.paymentDate?.localeCompare(b.paymentDate) || 1)
						.map((due, index) => {
							const dateString =
								due.paymentDate != null
									? formatDateTime(dayjs(due.paymentDate), DateTimeFormats.DAY_FORMAT)
									: LABELS.paymentPlan.custom.noSetDate;

							const percentage: number = due.fixed
								? convertFixedToPercent(due.fixed, productPrice - dowpayment)
								: due.percent || 0;

							const percentageToShow: number =
								index === productPaymentPlan.schedule!.length - 1 ? roundPriceCents(percentageLeft) : percentage;

							percentageLeft -= percentage;

							return (
								<div css={scheduleCss}>
									{
										<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
											{`${dateString} (${percentageToShow}%)`}
										</Typography>
									}
									<Typography color={ETypographyColor.primary} type={ETypography.body2}>
										{Pricify(due.percent ? convertPercentToFixed(due.percent, productPrice - dowpayment) : due.fixed)}
									</Typography>
								</div>
							);
						})}
				</div>
			}
		</Fragment>
	);
};

const DiscountLine = ({ name, amount, type }: { name: string; amount: number; type: DiscountMethodsEnum }) => {
	return (
		<div className="discount-line">
			<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
				{name}
			</Typography>
			<Typography color={ETypographyColor.secondary} type={ETypography.body2}>
				{type === DiscountMethodsEnum.AMOUNT ? Pricify(Number(amount)) : `${amount}%`}
			</Typography>
		</div>
	);
};

export const PriceLine = ({
	state,
	defaultPriceTitle,
	isDisabled,
}: {
	state: ProductPrice;
	defaultPriceTitle: string;
	isDisabled: boolean;
}) => {
	if (state.name.toLowerCase().includes('late fee') || state.name.toLowerCase().includes('early bird')) {
		return (
			<div className="price-line">
				<div className="left-side">
					<div>
						{state.name.toLowerCase().includes('late fee') ? (
							<Tag title="Late Fee" color={isDisabled ? 'gray' : 'red'} />
						) : (
							<Tag title="Early Bird" color={isDisabled ? 'gray' : 'green'} />
						)}
						<Typography
							color={isDisabled ? ETypographyColor.disabled : ETypographyColor.secondary}
							type={ETypography.caption}
						>
							{state.discountValue &&
								`(${
									state.discountMethod === DiscountMethodsEnum.PERCENT
										? `${state.discountValue}%`
										: `$${state.discountValue}`
								})`}
						</Typography>
					</div>
					<Typography
						color={isDisabled ? ETypographyColor.disabled : ETypographyColor.secondary}
						type={ETypography.caption}
					>
						{`${formatDateTime(dayjs(state.startDate), DateTimeFormats.MMM_DD)} - ${formatDateTime(
							dayjs(state.endDate),
							DateTimeFormats.DAY_FORMAT
						)}`}
					</Typography>
				</div>
				<Typography color={isDisabled ? ETypographyColor.disabled : ETypographyColor.primary} type={ETypography.body2}>
					{Pricify(Number(state.price))}
				</Typography>
			</div>
		);
	} else {
		return (
			<div className="price-line">
				<Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
					{defaultPriceTitle}
				</Typography>
				<Typography color={ETypographyColor.primary} type={ETypography.body2}>
					{Pricify(Number(state.price))}
				</Typography>
			</div>
		);
	}
};
