import React, { FC, ReactNode, useEffect, useState, useRef, Fragment } from 'react';
import { useLayer, Arrow } from 'react-laag';
import { TPlacements } from '../../types/theme';
import { DomPortal } from '../DomPortal';
import { Background } from '../Modal/background';
import { PopUpButton, PopUpBody } from './style';

export interface Props {
	body: ReactNode;
	active?: boolean;
	fullWidth?: boolean;
	padding?: string | number;
	borderRadius?: number;
	onOpenChange?: (val: boolean) => void;
	trigger?: boolean;
	placement?: TPlacements;
	arrow?: boolean;
	disableClickOutside?: boolean;
	disableAutoTrigger?: boolean;
	unsetWidth?: boolean;
	unsetOptionsWidth?: boolean;
	disableMaxHeight?: boolean;
	limitPlacements?: boolean;
	isTopLayer?: boolean;
	disableScroll?: boolean;
	isBackground?: boolean;
	customPlacement?: {
		top: number;
		left: number;
	};
	marginTop?: number;
	onOutsideClick?: () => void;
	maxHeight?: number;
}

export const Popup: FC<Props> = ({
	children,
	body,
	onOpenChange,
	padding = 0.5,
	borderRadius = 5,
	maxHeight,
	fullWidth = false,
	trigger,
	placement = 'bottom-center',
	arrow = false,
	disableAutoTrigger = false,
	disableClickOutside = false,
	unsetWidth = false,
	unsetOptionsWidth = false,
	disableMaxHeight = false,
	limitPlacements = true,
	isTopLayer = false,
	disableScroll,
	isBackground = false,
	customPlacement,
	marginTop = 0,
	onOutsideClick = () => {},
}) => {
	const [isOpen, setOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState();

	useEffect(() => {
		if (fullWidth && ref.current) {
			// @ts-ignore: Object is possibly 'null'.
			setWidth(ref.current.offsetWidth);
		}
	}, [ref?.current?.offsetWidth, fullWidth]);

	useEffect(() => {
		if (onOpenChange) {
			onOpenChange(isOpen);
		}
	}, [isOpen]);

	useEffect(() => {
		if (trigger !== undefined) {
			setOpen(trigger);
		}
	}, [trigger]);

	const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
		isOpen,
		placement: placement,
		auto: true,
		possiblePlacements: limitPlacements
			? ['top-center', 'top-start', 'top-end', 'bottom-start', 'bottom-center', 'bottom-end']
			: [
					'top-center',
					'top-start',
					'top-end',
					'left-start',
					'left-center',
					'left-end',
					'right-start',
					'right-center',
					'right-end',
					'bottom-start',
					'bottom-center',
					'bottom-end',
					'center',
				],
		triggerOffset: 2,
		containerOffset: 13,
		arrowOffset: 8,
		onOutsideClick: () => {
			if (!disableClickOutside) {
				setOpen(false);
				onOutsideClick && onOutsideClick();
			}
		},
	});

	return (
		<Fragment>
			<div
				style={{
					width: unsetWidth ? 'unset' : '100%',
					height: '100%',
				}}
				ref={ref}
				data-aid="index-Popup"
			>
				<PopUpButton
					{...triggerProps}
					onClick={e => {
						if (!disableAutoTrigger) {
							setOpen(!isOpen);
						}
					}}
				>
					{children}
				</PopUpButton>
			</div>
			{isBackground && isOpen && (
				<DomPortal selector={'body'}>
					<Background />
				</DomPortal>
			)}
			{isOpen &&
				renderLayer(
					<div
						{...layerProps}
						style={{
							...layerProps.style,
							top: customPlacement?.top || layerProps.style.top,
							left: customPlacement?.left || layerProps.style.left,
							zIndex: isTopLayer ? 1000003 : 100000,
							width: unsetOptionsWidth ? 'fit-content' : width,
							display: 'flex',
							marginTop,
						}}
					>
						<PopUpBody
							borderRadius={borderRadius}
							padding={padding}
							disableMaxHeight={disableMaxHeight}
							disableScroll={disableScroll}
							maxHeight={maxHeight}
						>
							{body}
						</PopUpBody>
						{arrow && <Arrow {...arrowProps} />}
					</div>
				)}
		</Fragment>
	);
};
