/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { flexCol, gapCss } from '@app/react/styles/utils';
import { PauseDetails } from '../pauseDetails';
import { FormButtons } from '@app/react/components/shared/Buttons/FormButtons';
import { FormSaveButton } from '@app/react/components/shared/Buttons/SaveButton';
import { TranslationEn } from '@assets/i18n/en';
import { FormCancelButton } from '@app/react/components/shared/Buttons/FormCancelButton';
import { IPauseForm } from '../../types/interfaces';

export const PauseForm = ({
	onClick,
	status,
	isEdit,
	handlePauseHistoryClick,
	handleReset,
	organizationId,
	showWarning,
	memberEndDate,
}: IPauseForm) => {
	return (
		<div css={[flexCol, gapCss(35)]} data-testid="pause-form-details">
			<PauseDetails
				onHistoryWarningClick={handlePauseHistoryClick}
				showWarning={showWarning}
				status={status}
				memberEndDate={memberEndDate}
				organizationId={organizationId}
				isEdit={isEdit}
			/>
			<FormButtons>
				<FormCancelButton onClick={handleReset} />
				<FormSaveButton type="button" label={TranslationEn.general.buttons.next} onClick={onClick} />
			</FormButtons>
		</div>
	);
};
