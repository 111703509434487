/** @jsxRuntime classic */
/** @jsx jsx */

import { useEffect, useMemo, useState } from 'react';
import { useTheme, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import { Droppable } from '../Droppable';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { WeeklyDraggable } from './WeeklyDraggable';
import { MoreSlotsList } from '../MoreSlotsList';
import { MoreSlotsButton } from '../MoreSlotsButton';
import { LoadingContainer } from '../LoadingContainer';
import { IWeekToDropProps, ECalendarView, ISlotEventsToSpaces } from '../../types';
import { ISlot } from '../../../organisms/bo_calendar_slot/types';
import { ETimes } from '../../../types/enums/times';
import {
	spaceWidth,
	weekDayContainer,
	weekDayEventContainer,
	weekdayHeight,
	weekDayToDropContainer,
	weekDayToDropHorizontalContainer,
} from '../styles';
import { groupEventsByDate } from '../../utils';

dayjs.extend(isoWeek);

export const WeekToDrop = ({ spaceId, EventComponent, isLoading }: IWeekToDropProps) => {
	const colors = useTheme();
	const { options, eventsToSpaces } = useMiddlewareContext();
	const { view = ECalendarView.VERTICAL, date, isSundayFirstDay } = options || {};
	const dayShift = isSundayFirstDay ? 1 : 0;
	const horizontal = view === ECalendarView.HORIZONTAL;
	const today = dayjs(date);

	const dates = useMemo(() => {
		let result = [] as dayjs.Dayjs[];
		for (let i = 1; i < 8; i++) {
			result.push(today.isoWeekday(i - dayShift));
		}
		return result;
	}, [date, dayShift]);

	const weekDaysWithEvents = useMemo(() => {
		return groupEventsByDate(dates, eventsToSpaces, +spaceId);
	}, [dates, eventsToSpaces, spaceId]);

	const [modals, setModals] = useState<boolean[]>([]);

	const handleToggleModal = (index: number) => {
		let newModals = modals.slice();
		newModals[index] = !newModals[index];
		setModals(newModals);
	};

	let maxVisibleSlotsPerDay = 1;
	const todayContainerId = `week_container_${spaceId}`;
	const todayContainer = document.getElementById(todayContainerId);
	if (todayContainer) {
		const height = todayContainer.clientHeight - 20;
		maxVisibleSlotsPerDay = Math.floor(height / 20) + 1;
	}

	useEffect(() => {
		setModals(Array(dates.length).fill(false));
	}, [dates]);

	return (
		<div css={horizontal ? weekDayToDropHorizontalContainer(colors) : weekDayToDropContainer(colors)}>
			{weekDaysWithEvents.map((weekDay, index) => {
				const { events: weekDayEvents } = weekDay;
				const droppableId = `${spaceId}_${weekDay.date.toISOString()}`;
				const withModal = weekDayEvents.length > maxVisibleSlotsPerDay;
				const items = withModal ? weekDayEvents.slice(0, maxVisibleSlotsPerDay - 1) : weekDayEvents;
				const moreItemsCount = weekDayEvents.length - (maxVisibleSlotsPerDay - 1);

				return (
					<Droppable
						weekly
						maxWidth={horizontal ? `max(100% / 7, ${spaceWidth - 1}px)` : undefined}
						height={!horizontal ? `max(100% / 7, ${weekdayHeight}px)` : undefined}
						dragging={false}
						id={droppableId}
						key={droppableId}
					>
						<div id={index === 0 ? todayContainerId : undefined} css={weekDayContainer(colors, horizontal)}>
							{isLoading ? (
								<LoadingContainer />
							) : (
								items.map(event => {
									return (
										<WeeklyDraggable parentId={droppableId} state={event} key={event.id} id={event.id}>
											<div css={weekDayEventContainer} key={event.id}>
												<EventComponent event={event} key={`EventComponent-${event.id}`} />
											</div>
										</WeeklyDraggable>
									);
								})
							)}
							{withModal && (
								<MoreSlotsButton
									isWeekly
									colors={colors}
									handleToggleModal={() => handleToggleModal(index)}
									moreItemsCount={moreItemsCount}
								/>
							)}
							{withModal && modals[index] && (
								<MoreSlotsList
									colors={colors}
									EventComponent={EventComponent}
									group={{ items: weekDayEvents }}
									handleToggleModal={handleToggleModal}
									index={index}
								/>
							)}
						</div>
					</Droppable>
				);
			})}
		</div>
	);
};
