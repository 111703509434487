import React, { ButtonHTMLAttributes, useContext } from 'react';
import { isValuesChanged } from '@app/react/lib/form';
import { FormContext } from '../form';
import { ButtonProps } from 'ui';
import { CancelButton } from './CancelButton';

export const FormCancelButton: React.FC<CancelButtonProps> = ({
	disableCancelIfNoChanges = true,
	ButtonComponent = CancelButton,
	...props
}) => {
	const { values, initialValues, valid } = useContext(FormContext);
	return (
		<ButtonComponent
			{...props}
			disabled={disableCancelIfNoChanges && (!isValuesChanged(values, initialValues) || !valid)}
		/>
	);
};
interface CancelButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	onClick?: () => void;
	label?: string;
	disableCancelIfNoChanges?: boolean;
	ButtonComponent?: React.ComponentType<ButtonProps>;
}
