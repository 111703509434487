/** @jsxRuntime classic */
/** @jsx jsx */

import { FC } from 'react';
import { jsx } from '@emotion/react';

import { Draggable } from './Draggable';
import { IEventComponentProps } from '../../types';

import { ISlot } from '../../../organisms/bo_calendar_slot/types';
import { ResourceNameTypeEnum } from '../../../types/enums';

interface IProps {
	slot: ISlot;
	parentId: string;
	EventComponent: FC<IEventComponentProps>;
	groupTop?: number;
	groupCount?: number;
	groupIndex?: number;
}

export const DraggableSlot = ({ slot, parentId, EventComponent, groupTop, groupCount, groupIndex }: IProps) => {
	const isEditable = slot.isDraft
		? false
		: ResourceNameTypeEnum.RESERVATION === slot.event?.parentType &&
			parentId === String(slot.spaceId) &&
			!slot?.parentSlotId;

	const draggableId = parentId === String(slot.spaceId) ? slot.id : -1;

	return (
		<Draggable
			parentId={parentId}
			state={slot}
			key={slot.id}
			id={draggableId}
			isEditable={isEditable}
			groupTop={groupTop}
			groupCount={groupCount}
			groupIndex={groupIndex}
			isConnectedMaintenance={!!slot?.parentSlotId || slot?.isConnectedMaintenance}
		>
			<EventComponent event={slot} key={`EventComponent-${slot.id}`} />
		</Draggable>
	);
};
