import React from 'react';

export const Bag = (props: any) => (
	<svg
		data-testid="svgBag"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
      d="M5.21667 6.66732C5.52042 6.66732 5.76667 6.91356 5.76667 7.21732V7.75065C5.76667 8.31203 5.98967 8.85041 6.38662 9.24736C6.78358 9.64431 7.32196 9.86732 7.88333 9.86732C8.44471 9.86732 8.98309 9.64431 9.38004 9.24736C9.777 8.85041 10 8.31203 10 7.75065V7.21732C10 6.91356 10.2462 6.66732 10.55 6.66732C10.8538 6.66732 11.1 6.91356 11.1 7.21732V7.75065C11.1 8.60376 10.7611 9.42194 10.1579 10.0252C9.55462 10.6284 8.73645 10.9673 7.88333 10.9673C7.03022 10.9673 6.21205 10.6284 5.60881 10.0252C5.00556 9.42194 4.66667 8.60376 4.66667 7.75065V7.21732C4.66667 6.91356 4.91291 6.66732 5.21667 6.66732Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
      d="M5.21667 5.63398C5.52042 5.63398 5.76667 5.38774 5.76667 5.08398V4.55065C5.76667 3.98928 5.98967 3.45089 6.38662 3.05394C6.78358 2.65699 7.32196 2.43398 7.88333 2.43398C8.44471 2.43398 8.98309 2.65699 9.38004 3.05394C9.777 3.45089 10 3.98928 10 4.55065V5.08398C10 5.38774 10.2462 5.63398 10.55 5.63398C10.8538 5.63398 11.1 5.38774 11.1 5.08398V4.55065C11.1 3.69754 10.7611 2.87937 10.1579 2.27612C9.55462 1.67288 8.73645 1.33398 7.88333 1.33398C7.03022 1.33398 6.21205 1.67288 5.60881 2.27612C5.00556 2.87937 4.66667 3.69754 4.66667 4.55065V5.08398C4.66667 5.38774 4.91291 5.63398 5.21667 5.63398Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
      d="M2 6.43398C2 5.38464 2.71634 4.53398 3.6 4.53398H12.1333C13.017 4.53398 13.7333 5.38464 13.7333 6.43398V12.7673C13.7333 13.8167 13.017 14.6673 12.1333 14.6673H3.6C2.71634 14.6673 2 13.8167 2 12.7673V6.43398ZM3.6 5.80065H12.1333C12.4279 5.80065 12.6667 6.0842 12.6667 6.43398V12.7673C12.6667 13.1171 12.4279 13.4007 12.1333 13.4007H3.6C3.30545 13.4007 3.06667 13.1171 3.06667 12.7673V6.43398C3.06667 6.0842 3.30545 5.80065 3.6 5.80065Z"
			fill="currentColor"
		/>
	</svg>
);
