import { RCOrganization } from "@rcenter/core";

export enum TabsEnum {
    RESERVATIONS = 'reservations',
    RESERVATION = 'reservation',
    PACKAGES = 'packages',
    RENTAL_PRODUCTS = 'rentals',
}

export enum TAB_ACTIONS {
    CREATE = 'create',
    OVERVIEW = 'overview',
    EDIT = 'edit',
};

export enum RESERVATIONS_LABELS {
    RESERVATIONS = 'Reservations',
    RENTALS = 'Rentals',
    RENTAL_PRODUCT = 'Rental Product',
    RENTAL_PRODUCTS = 'Rental Products',
    PRODUCTS = 'Products',
    CREATE_RENTAL_PRODUCT = 'New Product',
    EDIT_RENTAL_PRODUCT = 'Edit Product',
};

export enum ReservationsFiltersEnum {
    //TODO: Uncomment after infinite scroll implementation
    // RESOURCE_IDS = 'resourceIds',
	SPORTS = 'sports',
    INSTRUCTOR = 'instructor',
    STATUSES = 'statuses'
};

export interface RentalBreadcrumb {
    label: RESERVATIONS_LABELS;
    to: string;
}

export interface ReservationsMapperProps {
    organization: RCOrganization;
    activeTab: string;
    action?: string;
    id?: string;
    edit?: boolean;
    handleTabChange: (tab: TabsEnum, id?: string, action?: TAB_ACTIONS) => void;
    setBreadcrumbs:  (value: RentalBreadcrumb[]) => void;
    allowedTabs: TabsEnum[];
}
