export const REQUIRED = 'required';

export const pageQueryTag = 'page';

export const pageQuryDefaultValue = '1';

export const CURRENT_PAGE = '#';

export const MINIMUM_REQUIRED_VALUE = 1;

export const PAUSE_REASONS_OPTIONS_MAX_HEIGHT = 300;

export const TAGS_MAX_HEIGHT = 84;

export const ITEMS_PER_PAGE_DEFAULT = 30;

export const DEFAULT_REACT_QUERY_STALE_MINUTES = 5;

export const DEFAULT_REACT_QUERY_RETRY_COUNT = 3;

export const DEFAULT_REFUND_REASON = 1;
