/** @jsx jsx */
import { BnIcon, Button, Icons, SuspenseWrapper } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { DiscoverResult, ErrorResponse, loadStripeTerminal, Terminal } from '@stripe/terminal-js';
import * as dayjs from 'dayjs';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { TranslationEn } from '@assets/i18n/en';
import { productApi } from 'app/react/lib/api/productsApi';
import { paymentApi } from '../lib/api/paymentApi';
import { stationApi } from '../lib/api/stationApi';
import { localStorage } from '../lib/storage';
import { cartStore } from '../stores/cartStore';
import { EStorageKeys } from '../types/enums';
import { BnTERMINAL, IStation } from '../types/station';
import { useFacility } from './useFacility';
import { useNotification } from './useNotification';
import { useOrganization } from './useOrganization';

const terminalStatusCss = (isConnected: boolean) => css`
	padding: 30px 20px;
	background: #232526;
	font-weight: 600;
	border-radius: 10px;
	text-transform: capitalize;
	color: white;
	display: flex;
	align-items: center;

	svg {
		margin-right: 8px;
		color: ${isConnected ? 'green' : 'red'};
	}
	button {
		margin-left: 8px;
		filter: brightness(0) invert(1);
	}
`;

export enum ETerminalConnectionStatus {
	CONNECTING = 'connecting',
	CONNECTED = 'connected',
	NOT_CONNECTED = 'not_connected',
}

export const useStation = () => {
	const { organizationId } = useOrganization();
	const [connectedStation, setConnectedStation] = useRecoilState(cartStore.connectedStation);
	const [productSubcategories, setProductSubcategories] = useRecoilState(cartStore.productSubcategories);
	const [isTerminalSet, setTerminalSet] = useRecoilState(cartStore.isTerminalSet);
	const [isTerminalLoading, setTerminalLoading] = useRecoilState(cartStore.isTerminalLoading);
	const [isSwipeAllowed, setSwipeAllowed] = useRecoilState(cartStore.isSwipeAllowed);
	const [shouldConnect, setShouldConnect] = useState(false);

	const { setTerminalNotification, toggle: notificationToggle } = useNotification();

	const [sTerminal] = useState<Terminal>();

	const { BN_TERMINAL } = EStorageKeys;
	const terminalToken: string = localStorage.getItem(BN_TERMINAL) as string;

	const labels = TranslationEn.station;
	const TerminalStatus = ({ message, status }: { message?: string; status?: string }) => {
		const { isConnected, isDisconnected } = useMemo(() => {
			return {
				isConnected: status === ETerminalConnectionStatus.CONNECTED,
				isDisconnected: status === ETerminalConnectionStatus.NOT_CONNECTED,
			};
		}, [status]);

		useEffect(() => {
			if (isConnected) {
				setTimeout(() => {
					notificationToggle();
				}, 2500);
			}
		}, [isConnected]);

		return (
			<div css={terminalStatusCss(isConnected)}>
				<Fragment>
					<SuspenseWrapper loading={!isConnected && !isDisconnected}>
						<BnIcon icon={isConnected ? Icons.am_wifi : Icons.close} />
						{message}
					</SuspenseWrapper>
				</Fragment>

				{isDisconnected && (
					<Button
						data-aid="button-useAddCustomer"
						theme="secondary"
						sizer="XS"
						onClick={() => {
							setTerminalNotification(
								<TerminalStatus message={labels.messages.tryingAgain} status={ETerminalConnectionStatus.CONNECTING} />
							);
							initiateTerminal();
						}}
					>
						{labels.tryAgain}
					</Button>
				)}
			</div>
		);
	};

	const shiftId = useMemo(() => {
		return connectedStation?.currentOpenShift?.id || null;
	}, [connectedStation]);

	const connectToStation = async (organizationId: number) => {
		const token = localStorage.getItem(BN_TERMINAL) as string;
		if (token) {
			stationApi.validateStation(token).then(res => {
				if (res.organizationId === organizationId && organizationId) {
					setConnectedStation(res);
				} else {
					localStorage.removeItem(BN_TERMINAL);
				}
			});
		}
	};

	const timestamp = () => `${dayjs().format()} - `;
	const logWithTimestamp = (message: string, logOutput?: unknown) => {
		console.log(timestamp(), message, logOutput || '');
	};

	const getProductSubcategories = (organizationId: number, stationId?: number) => {
		productApi.getSubcategories(organizationId, stationId).then(res => {
			setProductSubcategories(res);
		});
	};

	const fetchConnection = () => {
		return paymentApi.getStripeToken().then(token => {
			return token.secret;
		});
	};

	const disconnectReader = () => {
		logWithTimestamp('disconnect the reader');
		window[BnTERMINAL]?.disconnectReader();
	};

	const clearTerminalDisplay = () => {
		logWithTimestamp('clear the reader display');
		window[BnTERMINAL]?.clearReaderDisplay();
	};

	const initiateTerminal = (
		terminalSetter?: (t: Terminal) => void,
		isFetchConnectionToken = false,
		isConnectToTerminal = true
	) => {
		if (isFetchConnectionToken || terminalToken) {
			(async () => {
				const StripeTerminal = await loadStripeTerminal();
				const terminal = await StripeTerminal.create({
					onFetchConnectionToken: fetchConnection,
					onUnexpectedReaderDisconnect: error => {
						setSwipeAllowed(false);
						setTerminalNotification(
							<TerminalStatus message={labels.messages.disconnected} status={ETerminalConnectionStatus.NOT_CONNECTED} />
						);
					},
				});

				window[BnTERMINAL] = terminal;
				if (terminalSetter) {
					terminalSetter(terminal);
				}
				if (isConnectToTerminal) {
					connectToTerminal(terminal);
				}
			})();
		} else {
			setTerminalSet(false);
		}
	};

	const connectToTerminal = (terminal: Terminal) => {
		logWithTimestamp('looking for terminal token');
		logWithTimestamp('terminal token founded');

		// validate the terminal
		stationApi.validateStation(terminalToken).then(res => {
			const locationId: string = res.facility?.locationId;
			logWithTimestamp('response of validation of terminal');

			if (res.statusCode || !locationId) {
				logWithTimestamp("terminal couldn't been validated, error:", res);
				// removeCookie("bn_terminal");
				localStorage.removeItem(BN_TERMINAL);
				setTerminalSet(true);
			} else {
				logWithTimestamp('terminal been validated, response:', res);
				setTerminalSet(true);

				// try to connect to treminal
				const config = { simulated: false, location: locationId };
				logWithTimestamp('looking for readers');
				terminal
					.discoverReaders(config)
					.then(discoverResult => {
						logWithTimestamp('discover result get:', discoverResult);
						// discoveredReaders
						if ((discoverResult as ErrorResponse).error) {
							logWithTimestamp('discover readers failed inside with error:', (discoverResult as ErrorResponse).error);
						} else if ((discoverResult as DiscoverResult).discoveredReaders.length === 0) {
							logWithTimestamp('no readers founded');
							// no readers
						} else {
							logWithTimestamp('readers founded');
							// find the relevant reader
							const selectedReader = (discoverResult as DiscoverResult).discoveredReaders.find(
								reader => reader.id === res.processorTerminalId
							);

							logWithTimestamp('selected reader founded :', selectedReader);

							terminal
								.connectReader(selectedReader)
								.then(connectResult => {
									logWithTimestamp('connect reader get:', connectResult);
									if ((connectResult as ErrorResponse).error) {
										setTerminalNotification(
											<TerminalStatus
												message={labels.messages.failed}
												status={ETerminalConnectionStatus.NOT_CONNECTED}
											/>
										);

										// error
										logWithTimestamp(
											'reader connection failed inside with error:',
											(connectResult as ErrorResponse).error
										);
										setTerminalLoading(false);
										setSwipeAllowed(false);
									} else {
										setTerminalNotification(
											<TerminalStatus
												message={labels.messages.connected}
												status={ETerminalConnectionStatus.CONNECTED}
											/>
										);
										logWithTimestamp('reader connected');
										// setReader((connectResult as { reader: Reader }).reader);
										setSwipeAllowed(true);
										setTerminalLoading(false);
									}
								})
								.catch(err => {
									logWithTimestamp('connection failed with error: ', err);
								});
						}
					})
					.catch(err => {
						logWithTimestamp('discover readers failed with error:', err);
					});
			}
		});
	};

	const setShouldConnectToTerminal = (toConnect = false) => {
		setShouldConnect(toConnect);
	};

	const getStation = (
		facilityId: number,
		handleSuccess: (result: any) => void,
		handleError: (message: string) => void
	): void => {
		stationApi
			.getStations(organizationId, Number(facilityId))
			.then(res => {
				handleSuccess(res);
			})
			.catch(err => {
				handleError(err);
			});
	};

	const updateStation = (
		newStation: IStation,
		handleSuccess: (result: any) => void,
		handleError: (message: string) => void
	): void => {
		stationApi
			.updateStation(organizationId, newStation)
			.then(res => {
				handleSuccess(res);
			})
			.catch(err => {
				handleError(err);
			});
	};

	const validateStation = (
		cookie: string,
		handleSuccess: (result: any) => void,
		handleError: (message: string) => void
	): void => {
		stationApi
			.validateStation(cookie)
			.then(res => {
				handleSuccess(res);
			})
			.catch(err => {
				handleError(err.message);
			});
	};

	useEffect(() => {
		shouldConnect && initiateTerminal();
		return () => (shouldConnect ? disconnectReader() : undefined);
	}, [shouldConnect]);

	return {
		connectToStation,
		getProductSubcategories,
		connectedStation,
		productSubcategories,
		isSwipeAllowed,
		shiftId,
		initiateTerminal,
		sTerminal,
		isTerminalSet,
		isTerminalLoading,
		setSwipeAllowed,
		disconnectReader,
		clearTerminalDisplay,
		setShouldConnectToTerminal,
		updateStation,
		validateStation,
		getStation,
	};
};
