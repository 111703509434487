export const REQUIRED = 'required';

export const pageQueryTag = 'page';

export const pageQuryDefaultValue = '1';

export const CURRENT_PAGE = '#';

export const MINIMUM_REQUIRED_VALUE = 1;

export const TAGS_MAX_HEIGHT = 84;

export const ITEMS_PER_PAGE_DEFAULT = 30;
