import { IBasicError } from '@app/react/types/errors';
import { PaginationMetaDto, PlatformsEnum } from '@bondsports/types';
import { environment } from '../../../environments/environment';
import { CustomError } from './../types/helpers';
import { auth } from './auth';
import { deepFindFirst } from './utils';
/**
 * A wrapper around the browser `fetch` API:
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */

export function ParseError(err: Error) {
	const { meta } = JSON.parse(JSON.stringify(err));
	return meta;
}

export enum HttpStatusEnum {
	OK = 200,
	CREATED = 201,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	CONFLICT = 409,
	INTERNAL_SERVER_ERROR = 500,
	BAD_GATEWAY = 502,
}

/**
 * Checks that a response from the API is from the same organization as the user is logged in to.
 * If the response does not have a organizationId, it will pass.
 * @param responseBody
 */
const checkIfResponseIsOk = (responseBody: { data: any | any[] } | any | any[]) => {
	const body = responseBody.data ?? responseBody;
	const bodyArray = Array.isArray(body) ? body : [body];

	// find organizationId using lodash
	const orgObject = deepFindFirst(bodyArray, 'organizationId');
	if (orgObject) {
		const storedOrgId = Number(auth.getAuthOrganizationId());
		if (storedOrgId !== orgObject) {
			return false;
		}
	}

	// No organizationId in response bodyArray, let it pass
	return true;
};

async function handleHttpResponse(response: Response) {
	const contentType = response.headers.get('content-type');

	const responseBody = contentType.includes('application/json') ? await response.json() : await response.text();
	const isResponseFromProperOrg =
		Array.isArray(responseBody) || typeof responseBody === 'object' ? checkIfResponseIsOk(responseBody) : true;

	/**
	 * Handle a success response.
	 * Anything which isn't between 200-300 HTTP status will throw an error.
	 */
	if (isResponseFromProperOrg && response.status >= 200 && response.status < 300) {
		/**
		 * Handle debug message from the API
		 * won't appear in production - just for inner use
		 */
		if (!window.location.pathname.includes(environment.PRODUCTION_URL) && responseBody.debug) {
			console.log(
				'%c Debug output: %o',
				'background: #444; color: #bada55; padding: 2px; border-radius:2px ;',
				responseBody.debug
			);
		}
		return responseBody;
	}

	/**
	 * Handle logout if localstorage organizationId does not match respo nse organizationId
	 */
	if (!isResponseFromProperOrg) {
		auth.handleLogout();
	}

	const errorMsg = responseBody.error || response.statusText || response.status;
	const error: CustomError = new Error(errorMsg);

	error.meta = {
		body: responseBody,
		message: responseBody.error,
		statusCode: response.status,
		statusText: response.statusText,
	};
	throw error;
}

/**
 * @example
 * network.get('users/123');
 * network.get('users/123', { mode: 'no-cors' });
 * network.get('http://jsonplaceholder.typicode.com/users');
 */
async function get(path: string, options = {}) {
	valdiatePath(path);
	path = addOrganizationIdToUrl(path);
	const response = await fetch(path, {
		method: 'GET',
		...options,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('id_token')}`,
		},
	});

	return handleHttpResponse(response);
}

/**
 * @example
 * network.post('users', { name: 'Frodo' });
 *
 * @example
 * network.post('users/123/setName', 'Frodo', {
 *   headers: {
 *     'Content-Type': 'text/plain'
 *   }
 * });
 */
async function post(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	path = addOrganizationIdToUrl(path);
	const response: Response = await fetch(path, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('id_token')}`,
			...headers,
		},
		...options,
	});

	return handleHttpResponse(response);
}

async function put(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	path = addOrganizationIdToUrl(path);
	const response: Response = await fetch(path, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('id_token')}`,
			...headers,
		},
		...options,
	});

	return handleHttpResponse(response);
}

async function patch(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	path = addOrganizationIdToUrl(path);
	const response: Response = await fetch(path, {
		method: 'PATCH',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('id_token')}`,
			...headers,
		},
		...options,
	});

	return handleHttpResponse(response);
}

async function deleteMethod(path: string, data: unknown, headers = {}, options = {}) {
	valdiatePath(path);
	path = addOrganizationIdToUrl(path);
	const response: Response = await fetch(path, {
		method: 'DELETE',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('id_token')}`,
			...headers,
		},
		...options,
	});

	return handleHttpResponse(response);
}

const valdiatePath = (path: string) => {
	const invalidWords = [undefined, null, NaN.toString()];
	const invalidPath = invalidWords.some(element => {
		if (path.includes(element)) {
			return true;
		}

		return false;
	});

	if (invalidPath) {
		const msg = `The following path = ${path} is invalid`;
		console.error(`msg`);
		throw msg;
	}
	return;
};
export interface IApiError {
	err?: string;
	status: number;
}

const addPlatformToBody = (body = {}) => {
	return { ...body, platform: PlatformsEnum.BO };
};

const addOrganizationIdToUrl = (url: string) => {
	if (url.includes('organizationId=')) {
		return url;
	}
	const organizationId = auth.getAuthOrganizationId();
	if (organizationId === null || organizationId === undefined) {
		return url.trim();
	}
	const separator = url.includes('?') ? '&' : '?';
	return `${url.trim()}${separator}organizationId=${organizationId}`;
};

export const network = { get, post, put, patch, deleteMethod, delete: deleteMethod, addPlatformToBody, addOrganizationIdToUrl };

export const download = (dataurl: string, filename: string) => {
	const link = document.createElement('a');
	link.href = dataurl;
	link.target = '_blank';
	link.download = filename;
	link.click();
};

export function isErrorResponse(response: any): response is IApiError | IBasicError {
	return response.err !== undefined;
}
