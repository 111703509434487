/** @jsx jsx */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import {
	BnIcon,
	Popup,
	Typography,
	BnProvider,
	Button,
	SpinningLoader,
	ModalWindow,
	useModal,
	ETypographyColor,
	ETypography,
	Icons,
	EBrandingColorsOptions,
} from '@bondsports/utils';
import { setOpacity } from 'app/react/styles/utils';
import { colors, newColors } from 'app/react/styles/theme';
import { RecoilRoot } from 'recoil';
import { RCOrganization } from '@rcenter/core';
import { useOrganization } from 'app/react/hooks/useOrganization';
import { useStation } from 'app/react/hooks/useStation';
import { organizationApi } from 'app/react/lib/api/organizationApi';
import { IFacility } from 'app/react/types/facility';
import { ShiftStatusEnum } from 'app/react/types/station';
import { OpenRegistration } from './modal/openRegistration';
import { CloseRegistration } from './modal/closeRegistration';
import { TranslationEn } from 'assets/i18n/en';
import { useErrorModalDepricated } from '../shared/ErrorDepricated';
import { Mixpanel, mixpanelNull, MixpanelShiftType } from 'app/react/lib/mixpanel';

const contianerCss = css`
	display: flex;
	align-items: center;
	background: ${setOpacity(colors.white, 0.1)};
	border-radius: 3px;
	padding: 12px;
	position: relative;
	margin-top: 8px;
	cursor: pointer;
	svg {
		color: ${newColors.bg_text_teriaty};
		position: absolute;
		right: 12px;
	}
`;

const imgCss = css`
	display: flex;
	height: 36px;
	width: 36px;
	background: ${colors.brandSecondaryLight};
	border-radius: 50px;
	margin-right: 12px;
	align-items: center;
	justify-content: center;
	div {
		color: ${colors.brandPrimary};
		font-weight: bold;
	}
	img {
		height: 36px;
		width: 36px;
		border-radius: 50px;
	}
`;

const rightSide = css`
	display: flex;
	flex-direction: column;
	text-align: left;
`;

const titleContainer = css`
	padding: 16px;
`;

const statusContainer = css`
	padding: 0 6px 6px 6px;
`;

export const FacilityController = ({ organization }: { organization: RCOrganization }) => {
	return (
		<RecoilRoot>
			<BnProvider origin="facilityControl" fetchPermissios={null}>
				<FacilityControl organizationId={organization.id} />
			</BnProvider>
		</RecoilRoot>
	);
};

const FacilityControl = ({ organizationId }: { organizationId: number }) => {
	const { setOrganizationId } = useOrganization();
	const { connectToStation, connectedStation, shiftId } = useStation();
	const [facilities, setFacilities] = useState<IFacility[]>([]);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const [err, setErr] = useState('');

	const { toggle, isShowing } = useModal();

	useEffect(() => {
		setOrganizationId(organizationId);
		connectToStation(organizationId);
	}, [organizationId]);

	useEffect(() => {
		if (!connectedStation || (!isShowing && !isPopupOpen)) {
			connectToStation(organizationId);
		}
	}, [isPopupOpen, isShowing]);

	const selectedFacility: IFacility = useMemo(() => {
		if (connectedStation && facilities.length > 0) {
			const facility = facilities.find(facility => facility.id === connectedStation.facilityId);
			if (facility) return facility;
		}
		return facilities[0];
	}, [facilities, connectedStation]);

	useEffect(() => {
		Mixpanel.register({
			facility_id: selectedFacility?.id,
			facility_name: selectedFacility?.name,
		});
		// This component is wrapped in it's own RecoilState, so we can't use the BaseStore; it isn't shared.
		// Instead, we'll use local storage to persist the selected facility.
		localStorage.setItem('currentFacilityId', String(selectedFacility?.id));
	}, [selectedFacility]);

	useEffect(() => {
		Mixpanel.register({
			shift_type: connectedStation?.processorTerminalId ? MixpanelShiftType.STATION : MixpanelShiftType.ONLINE,
			shift_id: shiftId || mixpanelNull,
			station_id: connectedStation?.id || mixpanelNull,
			station_name: connectedStation?.name || mixpanelNull,
		});
	}, [connectedStation]);

	useEffect(() => {
		organizationApi.getFacilitiesDepricated(organizationId).then(res => {
			if (res.data) {
				setFacilities(res.data);
				setLoading(false);
			}
		});
	}, []);

	const labels = TranslationEn.facilityController;

	const FacilitySelectBody = () => {

		const [toggleButtonDataAid, toggleButtonLabel] = useMemo(() => {
			if (connectedStation?.currentOpenShift?.status === ShiftStatusEnum.OPEN) {
				return ['button-FacilityController-close', labels.closeRegister];
			}
			return ['button-FacilityController-open', labels.openRegister];
		}, [connectedStation]);

		return (
			<Fragment>
					{connectedStation && selectedFacility ? (
						<div>
							<div css={titleContainer}>
								<Typography type={ETypography.overlineAccented} color={ETypographyColor.teriaty}>
									{selectedFacility?.name} / {connectedStation?.name}
								</Typography>
							</div>
							<div css={statusContainer}>
								<Typography type={ETypography.body2} color={ETypographyColor.primary}>
									<Button data-aid={toggleButtonDataAid} theme="basic" sizer="S" onClick={toggle}>
										{toggleButtonLabel}
									</Button>
								</Typography>
							</div>
						</div>
					) : (
						<div>{labels.error}</div>
					)}
			</Fragment>
		);
	};

	const handleError = (error: string) => {
		ErrorToggle();
		setErr(error);
	};
	return (
		<Fragment>
			{isLoading ? (
				<SpinningLoader color={EBrandingColorsOptions.bg_system} />
			) : (
				<Popup
					onOpenChange={setPopupOpen}
					trigger={isPopupOpen}
					padding={0}
					fullWidth
					arrow
					body={<FacilitySelectBody />}
					placement="bottom-center"
				>
					<div css={contianerCss}>
						<div css={imgCss}>
							{selectedFacility?.mainMedia ? (
								<img src={selectedFacility.mainMedia.url} />
							) : (
								<div>{selectedFacility?.name.slice(0, 2)}</div>
							)}
						</div>
						<div css={rightSide}>
							<Typography type={ETypography.body2Accented} color={ETypographyColor.white}>
								{selectedFacility?.name}
							</Typography>
							<Typography type={ETypography.captionAccented} color={ETypographyColor.secondary}>
								{connectedStation ? connectedStation.name : labels.online}
							</Typography>
						</div>
						<BnIcon icon={Icons.expand_all} />
					</div>
				</Popup>
			)}

			<ModalWindow padding={0} isShowing={isShowing} toggle={toggle}>
				{connectedStation?.currentOpenShift?.status === ShiftStatusEnum.OPEN ? (
					<CloseRegistration toggle={toggle} handleError={handleError} />
				) : (
					<OpenRegistration toggle={toggle} handleError={handleError} />
				)}
			</ModalWindow>
			<ErrorModalDepricated message={err} />
		</Fragment>
	);
};
