/** @jsx jsx */
/** @jsxRuntime classic */

import { Button, EBrandingColorsOptions, Icons, SuspenseWrapper } from '@bondsports/utils';
import { jsx } from '@emotion/react';
import React from 'react';
import { TranslationEn } from '../../../../../../../../assets/i18n/en';
import { WidgetBody } from '../widgetBody';
import usePermissions from '@app/react/hooks/permissions/usePermissions';
import { PermissionsWrapper } from 'ui';

interface Props {
	eventCount: number;
	unallocatedCount: number;
	isLoading?: boolean;
	isConflicted?: boolean;
	onRedirection?: () => void;
}

export const Allocation = ({
	eventCount,
	unallocatedCount,
	isConflicted = false,
	isLoading = true,
	onRedirection,
}: Props) => {
	const labels = TranslationEn.activities.session.dashboard.allocationWidget;
	const { userPermissions } = usePermissions();

	const title =
		unallocatedCount === 0
			? labels.completeAllocation
			: unallocatedCount === eventCount
			? labels.noAllocation
			: labels.partialAllocation.replace('~', String(unallocatedCount));

	return (
		<WidgetBody icon={isConflicted ? Icons.court_indicator : Icons.court} title={isLoading ? null : title}>
				<SuspenseWrapper loading={isLoading} color={EBrandingColorsOptions.bg_system}>
					<PermissionsWrapper requiredPermissions={['programs.sessions.resources.create']} userPermissions={userPermissions}>
						<Button data-aid="button-dashboardAllocation-add" sizer="S" theme="primary" onClick={onRedirection}
										disabled={unallocatedCount === 0}>
							{labels.addResource}
						</Button>
					</PermissionsWrapper>
			</SuspenseWrapper>
		</WidgetBody>
	);
};
