export const RELEVANT_SLOT_ID = 'rsid';

export const CONFLICTS_QUERY_KEYS = {
	CONFLICTS: 'conflicts',
	RESOURCE_ID: 'r',
	START_TIME: 'st',
	END_TIME: 'et',
	START_DATE: 'sd',
	END_DATE: 'ed',
	RESERVATION_SLOT_ID: 'rsid',
	CINLINE: 'cinline',
	FULL_PAGE: 'fullpage',
};
export const RESERVATION_ID_PARAM = 'reservationId';
export const RESERVATION_TYPE_PARAM = 'reservationType';
