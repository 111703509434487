import { ColorCode, Slot, SlotTypeEnum } from '@bondsports/types';

interface ISlotsAndMaintIndex {
	[x: number]: number[];
}

interface IProcessedSlot extends Slot {
	// colorCode?: ColorCode;
	isDraft?: boolean;
}

export interface IBundledSlot extends IProcessedSlot {
	isConnectedToMaint?: boolean;
	connectedMaints?: Slot[];
	overallStartTime: string;
	overallEndTime: string;
	maintenanceSlots?: (Slot & { isDraft?: boolean })[];
	isDraft?: boolean;
}

export interface ISlotEventsToSpaces {
	[eventId: number]: IBundledSlot[];
}

export interface IEventsToSpacesByDate {
	[date: string]: { [spaceId: number]: IBundledSlot[] };
}

/**
 * here the overall-slots array wiil be manipulated, when maint-slots would extracted away as they get bundled with their parent slot
 * @param processedSlots a shalow copy of the slots array, from the bundleEventsWithMaintenances function
 * @param maintId maintenance slot id
 * @returns maintenance slot, extracted from the aprocessedSlots array
 */
const connectRemovedSlot = (processedSlots: IProcessedSlot[], maintId: number) => {
	const maintIndex = processedSlots.findIndex(slot => slot.id === maintId);
	const [{ ...maintenanceSlot }] = processedSlots.splice(maintIndex, 1);
	return maintenanceSlot as IProcessedSlot;
};

const inferOverallTimeAndConflicts = ({
	maintenanceSlot,
	earliestStart,
	latestEnd,
}: {
	maintenanceSlot: IProcessedSlot;
	earliestStart: string;
	latestEnd: string;
}) => {
	const { startTime, endTime, conflictsCount } = maintenanceSlot;
	const earliestTime = startTime < earliestStart ? startTime : earliestStart;
	const latestTime = endTime > latestEnd ? endTime : latestEnd;
	return { earliestTime, latestTime, conflictsNum: conflictsCount };
};

/**
 *  exrtract the maintenance slots from the general slots array, and connect them to their parrent slot.
 *  get all needed data from maintenance-slot, to update the parent: times & conflicts.
 */
const bundleMaintsToSlot = ({
	maintId,
	processedSlots,
	earliestStart,
	latestEnd,
	maintColors,
}: {
	maintId: number;
	processedSlots: IProcessedSlot[];
	earliestStart: string;
	latestEnd: string;
	maintColors: ColorCode;
}) => {
	const maintenanceSlot = connectRemovedSlot(processedSlots, maintId);
	const { earliestTime, latestTime, conflictsNum } = inferOverallTimeAndConflicts({
		maintenanceSlot,
		earliestStart,
		latestEnd,
	});
	maintenanceSlot.colorCode = maintColors;
	return { maintenanceSlot, earliestTime, latestTime, conflictsNum };
};

const bundleSlot = ({
	slot,
	processedSlots,
	childrenMaints,
	maintColors,
}: {
	slot: IProcessedSlot;
	processedSlots: IProcessedSlot[];
	childrenMaints: number[];
	maintColors?: ColorCode;
}) => {
	let earliestStart = slot.startTime;
	let latestEnd = slot.endTime;
	let conflictsCount = slot.conflictsCount;
	/* bundle the slot with all its maintenance slots, based on maintIds from the index table: */
	return {
		...slot,
		isDraft: !!slot.isDraft,
		isConnectedMaintenance: true,
		connectedMaints: childrenMaints.map(maintId => {
			const { maintenanceSlot, earliestTime, latestTime, conflictsNum } = bundleMaintsToSlot({
				maintId,
				processedSlots,
				earliestStart,
				latestEnd,
				maintColors,
			});
			earliestStart = earliestTime;
			latestEnd = latestTime;
			conflictsCount += conflictsNum ?? 0;
			return maintenanceSlot;
		}),
		overallStartTime: earliestStart,
		overallEndTime: latestEnd,
		conflictsCount,
	} as unknown as IBundledSlot;
};

const createSlotAndMaintIndex = (eventsForSpace: IBundledSlot[]): ISlotsAndMaintIndex => {
	const indexTable = {}; //desired outcome = { 273778:[273779] } as { parentSlotId: array of maintenanceSlotIds }
	for (const slot of eventsForSpace) {
		if (!slot.id) {
			slot.id = Date.now();
		}

		if (slot.parentSlotId) {
			const existingIndex = indexTable[slot.parentSlotId];
			if (existingIndex) {
				existingIndex.push(slot.id);
			} else {
				indexTable[slot.parentSlotId] = [slot.id];
			}
		}
	}
	return indexTable;
};

const bundleEventsWithMaintenances = (
	indexObject: ISlotsAndMaintIndex,
	eventsForSpace: IBundledSlot[],
	maintColors: ColorCode
) => {
	const processedSlots = [...eventsForSpace];
	/* processedSlots would get choped down along the loop execution with connectRemovedSlot*/
	const bundledEvents: IBundledSlot[] = [];
	eventsForSpace.forEach(slot => {
		const { id } = slot;
		/* check if id is a key in the index table: */
		const childrenMaints = indexObject[id];
		if (childrenMaints) {
			/* bundle the slot with all its maintenance slots, based on maintIds from the index table: */
			slot = bundleSlot({ slot, processedSlots, childrenMaints, maintColors });
		}
		if (!slot.parentSlotId) {
			bundledEvents.push(slot);
		}
	});
	return bundledEvents;
};

export const bundleEventToSpaces = (obj: ISlotEventsToSpaces, maintColors?: ColorCode): ISlotEventsToSpaces => {
	const processedEvents = {};
	const events = { ...obj };
	for (const key in events) {
		const connectionIndexes = createSlotAndMaintIndex(events[key]);
		const processedSlots = bundleEventsWithMaintenances(connectionIndexes, events[key], maintColors);
		processedEvents[key] = processedSlots;
	}
	return processedEvents;
};

export const bundleDraftSlotForSpaces = (eventsToSpacesByDate: IEventsToSpacesByDate): ISlotEventsToSpaces => {
	const bundledDrafts: ISlotEventsToSpaces = {};
	let processedSlot: IBundledSlot[] = [];
	Object.entries(eventsToSpacesByDate).forEach(([day, spaceSlot]) => {
		Object.entries(spaceSlot).forEach(([space, slots]) => {
			slots.forEach(slot => {
				// if it's not a maintenance slot, check if it has children and bound them with parentId
				if (slot.slotType !== SlotTypeEnum.MAINTENANCE) {
					if (slot.maintenanceSlots) {
						slot.maintenanceSlots.forEach(maintSlot => {
							maintSlot.parentSlotId = slot.id;
							maintSlot.isDraft = true;
							processedSlot.push(maintSlot as IBundledSlot);
						});
					}
					processedSlot.push(slot);
				}
			});
			spaceSlot[space] = processedSlot;
			processedSlot = [];
		});
		const bundledDraftSlots = bundleEventToSpaces(spaceSlot);
		bundledDrafts[day] = bundledDraftSlots;
	});
	return bundledDrafts;
};
